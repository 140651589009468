'use strict';

angular.module('lmsApp')
    .factory('videoConf', function ($resource) {
        return $resource('api/videoConfs/:id', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: false,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'create': {
                method: 'POST',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'delete': {
                method: 'DELETE',
                transformResponse: function (data) {
                    return {content: data};
                }
            },
            'getConfStatus': {
                method: 'GET',
                url: 'api/videoConfs/:id/status',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getRecords': {
                method: 'GET',
                isArray: true,
                url: 'api/videoConfs/records/:recordId',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'deleteRecord': {
                method: 'DELETE',
                url: 'api/videoConfs/record/:recordId',
                transformResponse: function (data) {
                    return {content: data};
                }
            }
        });
    });
