'use strict';

angular.module('lmsApp')
    .controller('StudentParentsQuizAttemptController', function ($scope, $rootScope, $stateParams, QuizProgress) {

        $rootScope.isFinished = true;
        $scope.sectionId = $stateParams.id;
        $scope.studentId = $stateParams.studentId;
        $scope.attemptNumber = $stateParams.attempt;
        $scope.quizProgressId = $stateParams.quizProgressId;

        $scope.load = function (quizProgressId, attempt) {
            QuizProgress.getQuiz({id: quizProgressId}, function (result) {
                $scope.quiz = result;
            });
            QuizProgress.getAttempt({id: quizProgressId, attempt: attempt}, function (result) {
                $rootScope.quizAnswers = result.answers;
                $rootScope.responseString = result.feedbacks;
            });
        };

        $scope.load($stateParams.quizProgressId, $stateParams.attempt);
    });