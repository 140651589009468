'use strict';

angular.module('lmsApp').factory('Rubric', function ($resource) {
    return $resource('api/rubrics/', {}, {
        get: {
            method: 'GET',
            url: 'api/rubrics/:id',
            transformResponse: function (data) {
                data = angular.fromJson(data);
                return data;
            }
        },
        update: {
            method: 'PUT'
        },
        'create': {
            method: 'POST'
        },
        'delete': {
            method: 'DELETE',
            url: 'api/rubrics/:id'
        }
    });
});
