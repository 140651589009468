"use strict";

angular.module("lmsApp").config(function($stateProvider) {
    $stateProvider.state("userActivity", {
        parent: "admin",
        url: "/useractivity",
        data: {
            roles: ["OP_MANAGE_COURSES", "OP_VIEW_COURSES"],
            pageTitle: "lmsApp.course.wiki.title"
        },
        views: {
            "content@": {
                templateUrl: "app/admin/userActivity/userActivity.html",
                controller: "UserActivityController"
            }
        },
        resolve: {
            translatePartialLoader: [
                "$translate",
                "$translatePartialLoader",
                function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart("userActivity");
                    return $translate.refresh();
                }
            ]
        }
    });
});
