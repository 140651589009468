'use strict';

angular.module('lmsApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('methodologistCourses', {
                parent: 'entity',
                url: '/methodologistCourses',
                data: {
                    roles: ['OP_VIEW_TEACHER_COURSES'],
                    pageTitle: 'lmsApp.methodologistCourses.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/methodologistCourses/methodologistCourses.html',
                        controller: 'methodologistCoursesController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('methodologistCourses');
                        return $translate.refresh();
                    }]
                }
            })
            .state('methodologistCoursesDetail', {
                parent: 'entity',
                url: '/methodologistCourses/:id',
                data: {
                    roles: ['OP_VIEW_TEACHER_COURSES'],
                    pageTitle: 'lmsApp.methodologistCourses.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/methodologistCourses/methodologistCourses-detail.html',
                        controller: 'methodologistCoursesDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('methodologistCourses');
                        return $translate.refresh();
                    }]
                }
            })
            .state('methodologistCoursesEdit', {
                parent: 'entity',
                url: '/methodologistCourses/:id/edit',
                data: {
                    roles: ['OP_MANAGE_TEACHER_COURSES'],
                    pageTitle: 'lmsApp.methodologistCourses.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/methodologistCourses/methodologistCourses-edit.html',
                        controller: 'methodologistCoursesEditController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('methodologistCourses');
                        return $translate.refresh();
                    }]
                }
            });
    });
