'use strict';

angular
  .module('lmsApp')
  .service('FilesManager', [
    'dialogs',
    function(dialogs) {
      // Variables
      var _editWikiTemplate = '/app/components/admin/filesManagerModal.html', editor = {};

      // Methods
      this.openFile = openFile;

      function openFile(courseId, filePath, modalOptions) {
        if (!courseId) {
          return false;
        }

        var dialogOptions = modalOptions ? modalOptions : {};
        dialogOptions.keyboard = dialogOptions.keyboard ? dialogOptions.keyboard : true;

        var dlg = dialogs.create(
          _editWikiTemplate,
          'FilesManagerCtrl',
          { courseId: courseId, filePath: filePath ? filePath : null },
          dialogOptions
        );
        dlg.result.then(function(data) {}, function(data) {});
      }
    }
  ])
  .controller('FilesManagerCtrl', [
    '$scope',
    '$uibModalInstance',
    'data',
    'FilesService',
    'uuid4',
    'Uuid',
    '$q',
    '$http',
    '$rootScope',
    'WikiManager',
    'OpenTaskManager',
    'QuizManager',
    'toastr',
    function(
      $scope,
      $uibModalInstance,
      initObj,
      FilesService,
      uuid4,
      Uuid,
      $q,
      $http,
      $rootScope,
      WikiManager,
      OpenTaskManager,
      QuizManager,
      toastr
    ) {
      // Variables
      var _courseId, cls, _filePath, fullFilePath;

      $scope.file = {};
      $scope.edit = false;
      $scope.configPath = {
        domainUrl: window.location.origin,
        courses: 'upload/courses/',
        courseFiles: 'files/'
      };

      // Methods
      $scope.cancel = cancel;
      $scope.addWiki = addWiki;
      $scope.addOpenTask = addOpenTask;
      $scope.addTest = addTest;
      $scope.makeFile = makeFile;
      $scope.fileTitle = '';

      init();

      function init() {
        if (!initObj.courseId || !initObj.filePath) {
          $uibModalInstance.close();
          return false;
        }

        _courseId = initObj.courseId;
        _filePath = initObj.filePath;

        $scope.fileTitle = _filePath.split('/');
        $scope.fileTitle = $scope.fileTitle.slice($scope.fileTitle.length - 1)[0];

        fullFilePath = getFullFilePath(initObj.filePath);

        getWikiFileByUrl(fullFilePath).then(
          function(response) {
            $scope.file.text = response; // file body
            $scope.edit = true;
          },
          function() {
            alert('File not found');
            $uibModalInstance.close();
          }
        );
      }

      function addWiki() {
        WikiManager.openWiki(_courseId);
      }

      function addOpenTask() {
        OpenTaskManager.openTask(_courseId);
      }

      function addTest() {
        QuizManager.openQuiz(_courseId);
      }

      function getFullFilePath(fileName) {
        if (_courseId) {
          return '/' +
            $scope.configPath.courses +
            _courseId +
            '/' +
            $scope.configPath.courseFiles +
            fileName;
        }
      }

      function getWikiFileByUrl(path) {
        var dfd = $q.defer();
        $http.get($scope.configPath.domainUrl + path + '?cacheBuster=' + uuid4.generate()).then(
          function(response) {
            dfd.resolve(String(response.data));
          },
          function() {
            dfd.reject();
          }
        );
        return dfd.promise;
      }

      function cancel() {
        $uibModalInstance.dismiss('Canceled');
      }

      function makeFile() {
        if (_courseId && _filePath) {
          FilesService.editText(
            { id: _courseId, path: _filePath },
            $scope.file.text,
            function(response) {
              // $uibModalInstance.close('Fine'); // Чтоб просто сохранялось но не закрывался попап
              toastr.success('Сохранено');
            }
          );
        }
      }
    }
  ]); //
