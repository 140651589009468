'use strict';

angular.module('lmsApp')
    .directive('hasAnyRole', ['Principal', function (Principal) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var setVisible = function () {
                    element.removeClass('hidden');
                },
                    setHidden = function () {
                        element.addClass('hidden');
                    },
                    defineVisibility = function (reset) {
                        var result;
                        if (reset) {
                            setVisible();
                        }

                        result = Principal.isInAnyRole(roles);
                        if (result) {
                            setVisible();
                        } else {
                            setHidden();
                        }
                    },
                    roles = attrs.hasAnyRole.replace(/\s+/g, '').split(',');

                if (roles.length > 0) {
                    defineVisibility(true);
                }
            }
        };
    }])
    .directive('excludingRoles', ['Principal', function (Principal) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var setVisible = function () {
                    element.removeClass('hidden');
                };
                var setHidden = function () {
                    element.addClass('hidden');
                };
                var defineVisibility = function (roles) {
                    var visible = true;
                    roles.forEach(function (role) {
                        var isInRole = Principal.isInRole(role);
                        if (isInRole) {
                            visible = false;
                        }
                    });
                    if (visible) {
                        setVisible();
                    } else {
                        setHidden();
                    }
                };
                var testRoles = attrs.excludingRoles.replace(/\s+/g, '').split(',');
                if (testRoles.length > 0) {
                    defineVisibility(testRoles);
                }
            }
        };
    }])
    .directive('hasRole', ['Principal', function (Principal) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var setVisible = function () {
                    element.removeClass('hidden');
                },
                    setHidden = function () {
                        element.addClass('hidden');
                    },
                    defineVisibility = function (reset) {
                        var result;
                        if (reset) {
                            setVisible();
                        }

                        result = Principal.isInRole(role);
                        if (result) {
                            setVisible();
                        } else {
                            setHidden();
                        }
                    },
                    role = attrs.hasRole.replace(/\s+/g, '');

                if (role.length > 0) {
                    defineVisibility(true);
                }
            }
        };
    }])
    .directive('forAdmin', ['Principal', 'Auth', function (Principal, Auth) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var setVisible = function () {
                    element.removeClass('hidden');
                },
                    setHidden = function () {
                        element.addClass('hidden');
                    },
                    defineVisibility = function () {
                        if (Auth.checkAppRoles()) {
                            setVisible();
                        } else {
                            setHidden();
                        }
                    };
                defineVisibility();
            }
        };
    }]);
