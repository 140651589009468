'use strict';

angular.module('lmsApp')
    .controller('sectionsInGroupDetailController', function ($scope, $rootScope, $stateParams, CourseSection) {
        $scope.courseSection = {};
        $scope.load = function (id) {
            CourseSection.get({id: id}, function (result) {
                $scope.sectionInGroup = result;
            });
        };
        $scope.load($stateParams.id);
    });
