'use strict';

angular.module('lmsApp')
    .factory('VideoConferences', function ($resource, API) {
        var videoConfApi = API.newAdmin + '/conferences'
        return $resource(videoConfApi, {}, {
            create: {
                method: 'POST',
                url: videoConfApi +'/'
            },
            update: {
                method: 'PUT',
                url: videoConfApi +'/'
            },
            addParticipant: {
                method: 'POST',
                url: videoConfApi + '/participants'
            },
            deleteParticipant: {
                method: 'DELETE',
                url: videoConfApi + '/participants'
            },
            deleteAllParticipants: {
                method: 'DELETE',
                url: videoConfApi + '/participants/all/:conferenceId'
            },
            addGroup: {
                method: 'POST',
                url: videoConfApi + '/groups'
            }
        });
    })
