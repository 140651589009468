'use strict';

angular.module('lmsApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('learningclass', {
                parent: 'entity',
                url: '/learningclass',
                data: {
                    roles: ['OP_MANAGE_LEARNINGCLASS', 'OP_VIEW_LEARNINGCLASS'],
                    pageTitle: 'lmsApp.learningclass.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/learningclass/learningclasses.html',
                        controller: 'LearningclassController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('learningclass');
                        return $translate.refresh();
                    }]
                }
            })
            .state('learningclassDetail', {
                parent: 'entity',
                url: '/learningclass/:id',
                data: {
                    roles: ['OP_MANAGE_LEARNINGCLASS', 'OP_VIEW_LEARNINGCLASS'],
                    pageTitle: 'lmsApp.learningclass.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/learningclass/learningclass-detail.html',
                        controller: 'LearningclassDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('learningclass');
                        return $translate.refresh();
                    }]
                }
            });
    });
