'use strict';

angular
  .module('lmsApp')
  .service('WikiManager', [
    'StaticBackdropModal',
    function(StaticBackdropModal) {
      // Variables
      var _editWikiTemplate = '/app/components/entities/course/wikiManagerModal.html', editor = {};

      // Methods
      this.openWiki = openWiki;

      function openWiki(courseId, wiki) {
        if (!courseId) {
          return false;
        }
        var dlg = StaticBackdropModal.create(_editWikiTemplate, 'WikiManagerCtrl', {
          courseId: courseId,
          data: wiki ? wiki : null,
          editor: editor
        });
        dlg.result.then(
          function(data) {
            killEditorInstance();
          },
          function(data) {
            killEditorInstance();
          }
        );
      }

      function killEditorInstance() {
        editor.instance = null;
        if (tinyMCE.get('wikiText')) {
          tinyMCE.get('wikiText').remove();
        }
      }
    }
  ])
  .controller('WikiManagerCtrl', [
    '$scope',
    '$modalInstance',
    'toastr',
    'data',
    'FilesService',
    'StaticPageHelper',
    'uuid4',
    'Uuid',
    '$q',
    '$http',
    '$rootScope',
    function(
      $scope,
      $modalInstance,
      toastr,
      initObj,
      FilesService,
      StaticPageHelper,
      uuid4,
      Uuid,
      $q,
      $http,
      $rootScope
    ) {
      // Variables
      var _courseId, cls, _editor;

      $scope.wiki = {};
      $scope.wikiSettings = true;
      $scope.fileNameEditable = false;
      $scope.edit = false;
      $scope.configPath = {
        domainUrl: window.location.origin,
        courses: 'upload/courses/',
        courseFiles: 'files/',
        wikiPath: 'wiki_content/'
      };
      $scope.wikiCourseLink = '';
      $scope.error = null;

      // Methods
      $scope.cancel = cancel;
      $scope.save = save;
      $scope.copyToClipboard = StaticPageHelper.copyToClipboard;
      $scope.filenameChanged = filenameChanged;

      /*jshint latedef: nofunc */
      init();

      function init() {
        if (!initObj.courseId) {
          $modalInstance.close();
          return false;
        }

        $scope.wiki.courseId = initObj.courseId;

        _courseId = initObj.courseId;
        _editor = initObj.editor;

        if (initObj.data && initObj.data.name && initObj.data.name.length > 0) {
          var ready = false, filePath, pageData;

          $scope.wiki.resource = initObj.data;
          filePath = getFullFilePath($scope.wiki.resource.name);

          getWikiFileByUrl(filePath).then(function(response) {
            $scope.wiki.text = response; // file body
            var wikiText = $scope.wiki.text.replace(
              /^([\s\S]*)<body[^>]*>([\s\S]*)<\/body>([\s\S]*)$/gi,
              '$2'
            );
            pageData = getWikiPageData($scope.wiki.text);
            $scope.wiki.title = pageData.title; // body title tag
            $scope.wiki.filename = $scope.wiki.resource.name; // filename
            $scope.wiki.imsccId = pageData.identifier; // body meta tag
            $scope.fileNameEditable = false;
            $scope.edit = true;
            if (!_editor.instance) {
              StaticPageHelper.initEditor(_editor, 'wikiText', wikiText || '', _courseId);
            } else {
              _editor.instance.setContent(wikiText || '');
            }
            $('#saveWikiModal').modal('show');
          });
        } else {
          $scope.wiki.imsccId = Uuid.getNewKey($scope.wikiImsccIdMap) + uuid4.generate();
          $scope.edit = false;
          $scope.fileNameEditable = true;
          if (!_editor.instance) {
            StaticPageHelper.initEditor(_editor, 'wikiText', '', _courseId);
          } else {
            _editor.instance.setContent('');
          }
        }
      }

      function getFullFilePath(fileName) {
        if (_courseId) {
          return '/' +
            $scope.configPath.courses +
            _courseId +
            '/' +
            $scope.configPath.courseFiles +
            $scope.configPath.wikiPath +
            fileName;
        }
      }

      function getWikiFileByUrl(path) {
        var dfd = $q.defer();
        $http
          .get($scope.configPath.domainUrl + path + '?cacheBuster=' + uuid4.generate())
          .then(function(response) {
            dfd.resolve(String(response.data));
          });
        return dfd.promise;
      }

      function getWikiPageData(html) {
        var el = $('<div></div>'), data = {};
        el.html(html);
        data.title = el.find('title').text();
        data.identifier = el.find('meta[name=identifier]').attr('content');
        el.html('');
        el.remove();
        return data;
      }

      function cancel() {
        $modalInstance.dismiss('Canceled');
      }

      function save() {
        if (
          $scope.wiki.title &&
          $scope.wiki.title.length > 0 &&
          !/^\s+$/.test($scope.wiki.title) &&
          $scope.wiki.filename &&
          $scope.wiki.filename.length > 0 &&
          !/^\s+$/.test($scope.wiki.filename) &&
          $scope.wiki.imsccId &&
          $scope.wiki.imsccId.length > 0
        ) {
          if (_editor.instance) {
            $scope.wiki.text = _editor.instance.getContent();
          }
          try {
            $scope.wiki.text = StaticPageHelper.makeValidHtml($scope.wiki.text, $scope.wiki.title);
            $scope.wiki.text = StaticPageHelper.updateMetaIdentifier(
              $scope.wiki.text,
              $scope.wiki.imsccId
            );
            $scope.wiki.filename = StaticPageHelper.makeValidFilename($scope.wiki.filename);
            FilesService.editText(
              { id: _courseId, path: $scope.configPath.wikiPath + $scope.wiki.filename },
              $scope.wiki.text,
              function(response) {
                $modalInstance.close('Fine');
                $rootScope.$broadcast('wikiFileSaved');
                toastr.success('Wiki-файл сохранён');
              }
            );
          } catch (e) {
            alert('Неверный формат документа.');
            return false;
          }
        }
      }
      function filenameChanged(e) {
        $scope.filenameUserEdit = true;
      }
      $scope.$watch(
        'wiki',
        function(v) {
          if (v.filename) {
            $scope.wikiCourseLink = '<a href="#" onclick="LmsProxy.getInstance().showWiki(event, \'../../' +
              ($scope.configPath.wikiPath + StaticPageHelper.makeValidFilename(v.filename)) +
              '\')">' +
              (v.title ? v.title : '...') +
              '</a>';
          }
          if (v.title && !$scope.edit && !$scope.filenameUserEdit) {
            $scope.wiki.filename = StaticPageHelper.makeValidFilename(v.title).replace('.html', '');
          }
        },
        true
      );
    }
  ]); //
