'use strict';

angular.module('lmsApp')
    .controller('BookshelfDetailController', function ($scope, $stateParams, Bookshelf,Course) {
        $scope.bookshelf = {};
        $scope.syllabus = '/';

        $scope.load = function (id) {
            Bookshelf.get({id: id}, function(result) {
                $scope.bookshelf = result;
            });
        };

        $scope.load($stateParams.id);
    });
