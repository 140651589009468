'use strict';

angular.module('lmsApp').factory('ImportUsers', function ($resource) {
    return $resource('admin/import-users', {}, {
        'post': {
            method: 'POST',
            url: 'admin/import-users',
            isArray: true
        },
        'checkData': {
            method: 'POST',
            url: 'admin/import-users/check',
            isObject: true
        },
        'newStudentsCheck': {
            method: 'POST',
            url: 'imports/students/check',
        },
        'newStudentsAdd': {
            method: 'POST',
            url: 'imports/students/add',
        },
        'newTeachersCheck': {
            method: 'POST',
            url: 'imports/teachers/check',
        },
        'newTeachersAdd': {
            method: 'POST',
            url: 'imports/teachers/add',
        },
        'checkParentsData': {
            method: 'POST',
            url: 'admin/import-parents/check',
            isObject: true
        },
        'importParents': {
            method: 'POST',
            url: 'admin/import-parents',
            isArray: true
        },
    });
});
