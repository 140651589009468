'use strict';

angular.module('lmsApp')
    .service('QuizManager', ['dialogs', function(dialogs) {

        // Variables
        var _editWikiTemplate = '/app/components/entities/course/quizManagerModal.html',
            editor = {};

        // Methods
        this.openQuiz = openQuiz;

        function openQuiz(courseId, wiki) {
            if(!courseId) {
                return false;
            }

            var dlg = dialogs.create(_editWikiTemplate, 'QuizManagerCtrl', {'courseId' : courseId, 'data' : wiki ? wiki : null, 'editor' : editor}, {keyboard : true});
            dlg.result.then(function(data) {
                killEditorInstance();
            }, function(data) {
                killEditorInstance();
            });
        }

        function killEditorInstance() {
            editor.instance = null;
            if(tinyMCE.get('quizText')) {
                tinyMCE.get('quizText').remove();
            }
        }
    }])
    .controller('QuizManagerCtrl', ['$scope', '$uibModalInstance', 'toastr', 'data', 'FilesService', 'StaticPageHelper', 'uuid4', 'Uuid', '$q', '$http', '$rootScope', 'Course', 'Quiz',
        function($scope, $uibModalInstance, toastr, initObj, FilesService, StaticPageHelper, uuid4, Uuid, $q, $http, $rootScope, Course, Quiz) {

        // Variables
        var _courseId, cls, _editor;

        $scope.courseId = null;
        $scope.quiz = {};
        $scope.quizSettings = true;
        $scope.quizImsccIdMap = {};
        $scope.edit = false;
        $scope.configPath = {
            'domainUrl' : window.location.origin ,
            'courses' : 'upload/courses/',
            'courseFiles' : 'files/',
            'quizPath' : 'wiki_content/',
        };
        $scope.quizCourseLink = '';
        $scope.error=null;

        // Methods
        $scope.cancel = cancel;
        $scope.save = save;
        $scope.copyToClipboard = StaticPageHelper.copyToClipboard;

        init();

        function init() {

            if(!initObj.courseId) {
                $uibModalInstance.close();
                return false;
            }

            $scope.courseId = _courseId = initObj.courseId;
            _editor = initObj.editor ? initObj.editor : {};

            getCourse(_courseId);

             $scope.quiz = {};
            var imsccId = 'zzz' + Uuid.getNewKey($scope.quizImsccIdMap) + uuid4.generate(),
                imsccIdText = ['<a onclick="LmsProxy.getInstance().openQuizz(event, \'', '\')" href="#">'];
            $scope.quizSettings = true;
            $scope.quizQuestions = false;
            $scope.quiz.imsccId = imsccId;
            $scope.beginLink = imsccIdText[0] + imsccId + imsccIdText[1];
            $scope.imsccId = $scope.beginLink + '</a>';
            $scope.quiz.required = false;
            $scope.quiz.description = '';
            $scope.quiz.shuffleAnswers = false;
            if (_editor.instance) {
                _editor.instance.setContent($scope.quiz.description);
            }
            StaticPageHelper.initEditor(_editor, 'quizText', '', _courseId);
        }

        function getFullFilePath(fileName) {
            if(_courseId) {
                return '/' + $scope.configPath.courses + _courseId + '/' + $scope.configPath.courseFiles + $scope.configPath.wikiPath + fileName;
            }
        }

        function cancel() {
            $uibModalInstance.dismiss('Canceled');
        }

        function save() {
            $scope.quiz.course = {'id': _courseId};
            if (_editor.instance) {
                $scope.quiz.description = _editor.instance.getContent();
            }
            Quiz.update($scope.quiz, function (response) {
                $scope.quiz.id = response.id;
                $scope.quizSettings = false;
                $scope.quizQuestions = true;
                $scope.edit = true;
                toastr.success('Тест сохранён');
            }, function (response) {
                $scope.error = response.headers('Failure');
            });
        }

        function getCourse(courseId) {
            if(!courseId) {
                return false;
            }

            Course.get({id: courseId, expand: 'topics'}, function(result) {
                $scope.course = result;
                $scope.topics = $scope.course.topics;
            });
        }

        $scope.$watch('quiz.title', function () {
            if ($scope.quiz.title !== void 0 && $scope.quiz.title !== null && $scope.quiz.title !== '') {
                $scope.imsccId = $scope.beginLink + $scope.quiz.title + '</a>';
            }
        });
    }]);
