'use strict';

angular.module('lmsApp').directive('numberConverter', function () {
    return {
        priority: 1,
        restrict: 'A',
        require: 'ngModel',
        link: function(scope, element, attr, ngModel) {
            function toModel(value) {
                return '' + value; // convert to string
            }

            function toView(value) {
                return parseFloat(value); // convert to float
            }

            ngModel.$formatters.push(toView);
            ngModel.$parsers.push(toModel);
        }
    };
});