'use strict';

angular.module('lmsApp')
    .controller('VideoConfConfigController', function ($scope, $window, Account, Videoconfconfig, VideoConferences, videoConf, $timeout) {
        $scope.videoConfConfigs = [];
        $scope.account = {};
        $scope.startMettingData = {};
        $scope.meetingDataConnection = {};



        
        $scope.loadAll = function () {
            Account.get(function (result) {
                $scope.account = result.data;
                Videoconfconfig.query(function (result) {
                    $scope.videoConfConfigs = result;
                    angular.forEach($scope.videoConfConfigs, function (value, index) {
                        videoConf.getConfStatus({
                            id: value.bbbMeetingId
                        }, function (result) {
                            if (result.status && result.status === 'notFound') {
                                value.status = 'NotStarting';
                            } else {
                                value.status = 'InProgress';
                            }
                        });
                    });
                });
            });
        };

        $scope.loadAll();

        $scope.create = function () {
            var videoconf = {
                name: $scope.videoConfConfig.name,
                startDate:  $scope.videoConfConfig.plannedStartTime
            }
            if($scope.videoConfConfig.id !== undefined && $scope.videoConfConfig.id !==null) {
                videoconf.id = $scope.videoConfConfig.id;
                VideoConferences.update(videoconf,
                    function () {
                        $scope.loadAll();
                        $('#saveVideoconfconfigModal').modal('hide');
                        $scope.clear();
                    });                
            } else {
                VideoConferences.create(videoconf,
                    function () {
                        $scope.loadAll();
                        $('#saveVideoconfconfigModal').modal('hide');
                        $scope.clear();
                });
            }
        };

        $scope.update = function (id) {
            Videoconfconfig.get({
                id: id
            }, function (result) {
                $scope.videoConfConfig = result;
                $scope.videoConfConfig.plannedStartTime = new Date($scope.videoConfConfig.plannedStartTime);
                $('#saveVideoconfconfigModal').modal('show');
            });
        };

        $scope.delete = function (id) {
            Videoconfconfig.get({
                id: id
            }, function (result) {
                $scope.videoConfConfig = result;
                $scope.videoConfConfig.plannedStartTime = new Date($scope.videoConfConfig.plannedStartTime);
                $('#deleteVideoconfconfigConfirmation').modal('show');
            });
        };

        $scope.confirmDelete = function (id) {
            Videoconfconfig.delete({
                    id: id
                },
                function () {
                    $scope.loadAll();
                    $('#deleteVideoconfconfigConfirmation').modal('hide');
                    $scope.clear();
                });
        };

        $scope.startConference = function (id) {
            Videoconfconfig.get({
                id: id
            }, function (result) {
                $scope.videoconf = result;
                $scope.startMettingData.firstName = $scope.account.firstName;
                $scope.startMettingData.lastName = $scope.account.lastName;
                $scope.startMettingData.meetingId = $scope.videoconf.bbbMeetingId;
                $scope.startMettingData.meetingName = $scope.videoconf.name;
                videoConf.create($scope.startMettingData,
                    function (result) {
                        $window.open(result.url, '_blank');
                        $timeout(function () {
                            $scope.loadAll();
                        }, 3000);

                    });
            });
        };

        $scope.openVideoConf = function (id) {
            Videoconfconfig.get({
                id: id
            }, function (result) {
                $scope.videoconf = result;
                $scope.meetingDataConnection.firstName = $scope.account.firstName;
                $scope.meetingDataConnection.lastName = $scope.account.lastName;
                $scope.meetingDataConnection.meetingId = $scope.videoconf.bbbMeetingId;
                $scope.meetingDataConnection.meetingName = $scope.videoconf.name;
                $scope.meetingDataConnection.role = 'user';
                videoConf.update($scope.meetingDataConnection, function (result) {
                    $window.open(result.url, '_blank');
                });
            });
        };

        $scope.openVideoConfModerator = function (id) {
            Videoconfconfig.get({
                id: id
            }, function (result) {
                $scope.videoconf = result;
                $scope.meetingDataConnection.firstName = $scope.account.firstName;
                $scope.meetingDataConnection.lastName = $scope.account.lastName;
                $scope.meetingDataConnection.meetingId = $scope.videoconf.bbbMeetingId;
                $scope.meetingDataConnection.meetingName = $scope.videoconf.name;
                $scope.meetingDataConnection.role = 'moderator';
                videoConf.update($scope.meetingDataConnection, function (result) {
                    $window.open(result.url, '_blank');
                });
            });
        };

        $scope.clear = function () {
            $scope.videoConfConfig = {
                name: null,
                plannedStartTime: null,
                id: null
            };
            $scope.editForm.$setPristine();
            $scope.editForm.$setUntouched();
        };
    });
