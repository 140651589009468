'use strict';

angular.module('lmsApp').factory('LessonPage', function ($resource) {
    return $resource('api/lessonPages', {}, {
        'add': {
            method: 'POST'
        },
        'delete': {
            method: 'DELETE',
            url: 'api/lessonPages/:id'
        },
        'update': {
            method: 'PUT'
        }
    });
});
