/**
 * Created by nlyubich on 24.06.2015.
 */
'use strict';

angular.module('lmsApp')
    .directive('quizquestion', function() {
        return {
            restrict: 'E',
            scope: {
                'content' : '='
            },
            link: function(scope, element, attrs) {
                scope.question = scope.content;
                var notSupported = [
                    'file_upload_question',
                    'calculated_question',
                    'text_only_question',
                    'essay_question'
                ];
                if (notSupported.indexOf(scope.content.type.name) > -1) {
                    scope.typeServed = false;
                } else {
                    scope.typeServed = true;
                    scope.contentUrl = 'app/quiz/templates/' + scope.content.type.name + '.html';
                }
                /*attrs.$observe('content.type.name', function(v){
                    scope.contentUrl = 'app/quiz/templates/' + v + '.html';
                });*/
            },
            template: '<div ng-include="contentUrl" ng-if="typeServed"></div>'
        };
    });