'use strict';

angular.module('lmsApp')
    .factory('AssignmentComment', function ($resource) {
        return $resource('api/assignmentComments/courses/:courseId/assignments/:imsccId/students/:userId', {}, {
            /**
             * Get all by imsccId and current or specified user id
             */
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                url: 'api/assignmentComments/:id'
            },
            'update': {method: 'PUT'},
            'create': {method: 'POST'},
            'getUnpublished': {
                method: 'GET',
                url: 'api/assignmentComments/courses/:courseId/assignments/:imsccId/unpublished/students/:userId'

            },
            removeFile: {
                method: 'DELETE',
                url: 'api/assignmentComment/:id/files/:fileId'
            }
        });
    });
