'use strict';

angular.module('lmsApp')
    .controller('teacherCoursesRedactController', function ($scope, $stateParams, Course, Courses, CourseSection, User, Users) {
        $scope.user = {};
        $scope.updateUser = {};
        $scope.courseList = {};
        $scope.error = '';
        $scope.excludedIds = [];
        $scope.removedCourseId='';
        $scope.teacherId = $stateParams.id;
        $scope.coursesOptions = {
            'page': 1,
            'per_page': 10,
            'sorting_fields': ['id'],
            'sorting_direction': 'asc'
        };

        $scope.load = function (id) {
            Users.getTeacherCourses({'teacherId': id,}, function (user) {
                $scope.user = user;
                $scope.getCourses();
            });
        };


        $scope.showAddCourseDialog = function () {
            $('#addCourseModal').modal('show');
        };

        $scope.showDeleteCourseDialog = function () {
            $scope.clear();
            $('#deleteCourseModal').modal('show');
        };

        $scope.addCourse = function () {
            var addCourse = {
                firstId: $scope.teacherId,
                secondId: $scope.newCourse.id
            }
            Users.addTeacherCourse(addCourse,
                function (result) {
                    $('#addCourseModal').modal('hide');
                    $scope.newCourse = void 0;
                    $scope.load($scope.teacherId);
                },
                function (response) {
                    if (response.status === 400) {
                        $scope.error = response.headers('Failure');
                    }
            })
        };

        $scope.deleteCourse = function () {
            var delCourse = {
                id: $scope.teacherId,
                course_id:  $scope.removedCourseId
            }
            Users.deleteTeacherCourse(delCourse, function (data) {
                $('#deleteCourseModal').modal('hide');
                $scope.user = $scope.updateUser;
                $scope.load($stateParams.id);
            },
            function (response) {
                if (response.status === 400) {
                    $scope.error = response.headers('Failure');
                }
                if(response.status === 409){
                    $scope.error = response.data.error
                }
            });
        };

        $scope.getCourses = function(loadOptions) {
            var loadOptions = (loadOptions) ? loadOptions : $scope.coursesOptions;
            loadOptions.id = $scope.teacherId;

            Courses.getByTeacherId(loadOptions, function (result, headers) {
                $scope.courseList = {
                    'items' : result,
                    'total' : headers('X-Total-Count'),
                };
            });
        };


        $scope.clear = function () {
            $scope.error = '';
        };

        $scope.load($scope.teacherId);
    });
