/**
 * Created by nlyubich on 24.06.2015.
 */
'use strict';

angular.module('lmsApp')
    .directive('quizsection', function ($compile) {
        return {
            restrict: 'E',
            scope: {
                'content': '='
            },
            template: '<div class="voffset4" class="section" id="{{content.imsccId}}">' +
            '<h3 class="underline">{{content.title}}</h3>' +
            '<div has-role="OP_MANAGE_COURSES"><h4>Section ID: {{content.imsccId}}</h4></div>' +
            '</div>',
            link: function (scope, element, attrs) {
                element.append($compile(
                    '<quizsection ng-repeat="(key, section) in content.children" content="section"/>' +
                    '<quizquestion ng-repeat="(key, question) in content.questions" content="question" class="quiz"/>'
                )(scope));
            }
        };
    });