'use strict';

angular.module('lmsApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('videoConfConfig', {
                parent: 'entity',
                url: '/videoConfConfig',
                data: {
                    roles: ['ROLE_ADMIN', 'ROLE_LOCAL_ADMIN', 'ROLE_SCHOOL_ADMIN'],
                    pageTitle: 'lmsApp.videoConfConfig.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/videoConfConfig/videoConfConfigs.html',
                        controller: 'VideoConfConfigController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('videoConfConfig');
                        return $translate.refresh();
                    }]
                }
            })
            .state('videoConfConfigDetail', {
                parent: 'entity',
                url: '/videoConfConfig/:id',
                data: {
                    roles: ['ROLE_ADMIN', 'ROLE_LOCAL_ADMIN', 'ROLE_SCHOOL_ADMIN'],
                    pageTitle: 'lmsApp.videoConfConfig.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/videoConfConfig/videoConfConfig-detail.html',
                        controller: 'VideoConfConfigDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('videoConfConfig');
                        return $translate.refresh();
                    }]
                }
            })
            .state('videoConfConfigRecord', {
                parent: 'entity',
                url: '/videoConfConfig/record/:id',
                data: {
                    roles: ['ROLE_ADMIN', 'ROLE_LOCAL_ADMIN', 'ROLE_SCHOOL_ADMIN'],
                    pageTitle: 'lmsApp.videoConfConfig.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/videoConfConfig/videoConfConfig-record.html',
                        controller: 'VideoConfConfigRecordController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('videoRecord');
                        return $translate.refresh();
                    }]
                }
            });
    });
