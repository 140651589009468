'use strict';

angular.module('lmsApp').controller('CourseController', function ($scope, Course, CourseGroup, CourseUploader, $translate, CourseType, adaptedProgramList) {

        $scope.courses = [];
        $scope.progress = 0;
        $scope.courseError = null;
        $scope.courseGroups = null;
        $scope.courseGroupsFilter = null;
        $scope.unpackingInProgress = false;
        $scope.sorting = {
            fields: ['id'],
            direction: 'asc'
        };
        $scope.pagination = {
            page: 1,
            total: 0,
            perPage: 20
        };
        $scope.cgOptions = {
            'page': 1,
            'per_page': 5
        };

        // Methods
        $scope.loadAll = loadAll;
        $scope.getAllCourseGroups = getAllCourseGroups;
        $scope.getCourseGroups = getCourseGroups;
        $scope.changePerPageCount = changePerPageCount;
        $scope.sort = sort;
        $scope.create = create;
        $scope.update = update;
        $scope.deleteCourse = deleteCourse;
        $scope.confirmDelete = confirmDelete;
        $scope.clear = clear;
        $scope.updateAndUpload = updateAndUpload;

        $scope.adaptedProgramList = adaptedProgramList;
        $scope.currentCourseGroup = localStorage.getItem('currentCourseGroup') || '';
        $scope.currentCourseType = localStorage.getItem('currentCourseType') || '';

        init();

        function init() {

          CourseType.getAll({}, function (result) {
            $scope.courseTypes = result;
          });

          $scope.loadAll();
          $scope.getAllCourseGroups();
        }

        function loadAll() {
            var _opts = {
                page: $scope.pagination.page,
                per_page: $scope.pagination.perPage,
                sorting_fields: $scope.sorting.fields,
                sorting_direction: $scope.sorting.direction
            };
            if ($scope.currentCourseGroup && $scope.currentCourseGroup !== '') {
                _opts.courseGroupId = $scope.currentCourseGroup;
            }

            if ($scope.currentCourseType && $scope.currentCourseType !== '') {
              _opts.courseTypeId = $scope.currentCourseType;
            }

            Course.query(_opts, function (result, headers) {
                $scope.pagination.total = headers('X-Total-Count');
                $scope.courses = result;
            });

            $scope.getCourseGroups();
        }

        function getCourseGroups(loadOptions) {
            loadOptions = loadOptions ? loadOptions : $scope.cgOptions;
            CourseGroup.query(loadOptions, function (result, headers) {
                $scope.courseGroups = {
                    'items': result,
                    'total': headers('X-Total-Count')
                };
            });
        }

        function getAllCourseGroups(loadOptions) {
            CourseGroup.query(loadOptions, function (result, headers) {
                $scope.courseGroupsFilter = result;
            });
        }

        function changePerPageCount(number) {
            $scope.pagination.perPage = number;
            $scope.pagination.page = 1;
            $scope.loadAll();
        }

        function sort(fields) {
            //Меняем порядок сортировки, если выбран тот же столбец
            if (fields[0] === $scope.sorting.fields[0]) {
                if ($scope.sorting.direction === 'asc') {
                    $scope.sorting.direction = 'desc';
                } else {
                    $scope.sorting.direction = 'asc';
                }
            } else {
                //Меняем столбец сортировки
                $scope.sorting.fields = fields;
                $scope.sorting.direction = 'asc';
            }

            $scope.pagination.page = 1;
            $scope.loadAll();
        }

        function create() {
            $scope.course.categoryInfo = Object.keys($scope.categoryInfo).map(function(objectKey, index) {
               return { 's_category': objectKey };
            });
            Course.create($scope.course,
                function () {
                    $scope.loadAll();
                    $('#saveCourseModal').modal('hide');
                    $scope.clear();
                });
        }

        $scope.categoryInfo = {};

        function update(id) {
            Course.get({
                id: id
            }, function (result) {
                $scope.clear();
                $scope.course = result;
                if (!$scope.course.categoryInfo) {
                  $scope.course.categoryInfo = [];
                }
                $scope.categoryInfo = { 'C_DEFAULT': true };
                $scope.course.categoryInfo.forEach(function(category) {
                  $scope.categoryInfo[category['s_category']] = true;
                });
               $('#updateCourseModal').modal('show');
            });
        }

        function deleteCourse(id) {
            Course.get({
                id: id
            }, function (result) {
                $scope.clear();
                $scope.course = result;
                $('#deleteCourseConfirmation').modal('show');
            });
        }

        function confirmDelete(id) {
            Course.delete({
                    id: id
                },
                function () {
                    $scope.loadAll();
                    $('#deleteCourseConfirmation').modal('hide');
                    $scope.clear();
                },
                function (response) {
                    $scope.courseError = response.headers('Failure');
                });
        }

        function clear() {
            $scope.course = {
                name: null,
                parallel: null,
                published: null,
                id: null,
                courseType: null
            };
            $scope.categoryInfo = {};
            $scope.courseCartridge = null;
            $scope.courseError = '';
            $scope.progress = 0;
            $scope.unpackingInProgress = false;
            $scope.editForm.$setPristine();
            $scope.editForm.$setUntouched();
            $scope.updateForm.$setPristine();
            $scope.updateForm.$setUntouched();
            document.getElementById('course-update-form').elements['cartridge'].value = '';
        };

        function updateAndUpload() {
            $scope.courseError = null;
            var checkCartridge = function (cartridge, course) {
                Course.checkUpload({id: course.id}, function (success) {
                        uploadCartridge(cartridge, course)
                    },
                    function (error) {
                        if (error.status == 409 && confirm($translate.instant('lmsApp.course.confirmOverride'))) {
                            uploadCartridge(cartridge, course)
                        } else {
                            $scope.courseError = error.headers().failure;
                        }
                    });
            };

            function uploadCartridge(cartridge, course) {
                CourseUploader.upload(cartridge, course.id, {
                    progress: function (evt) {
                        $scope.progress = parseInt(100.0 * evt.loaded / evt.total);
                        if ($scope.progress === 100) {
                            $scope.unpackingInProgress = true;
                        }
                    },
                    success: function (data, status) {
                        $scope.loadAll();
                        $('#updateCourseModal').modal('hide');
                        $scope.clear();
                    },
                    error: function (data, status, headers) {
                        if (headers('Failure')) {
                            $scope.courseError = decodeURIComponent(headers('Failure'));
                        } else if (data && data.message) {
                            $scope.courseError = data.message;
                        } else {
                            $scope.courseError = 'Неизвестная ошибка';
                        }
                    }
                })
            }

            if ($scope.course.courseGroup !== null && $scope.course.courseGroup.id === null) {
                $scope.course.courseGroup = null;
            }

            var categoryInfo = [];
            Object.keys($scope.categoryInfo).forEach(function(objectKey, index) {
               if ($scope.categoryInfo[objectKey]) {
                  categoryInfo.push({ 's_category': objectKey });
               }
            });
            $scope.course.categoryInfo = categoryInfo;

            Course.update($scope.course, function () {
                // successful course update, upload the cartridge
                if ($scope.courseCartridge) {
                    // todo: check status first
                    checkCartridge($scope.courseCartridge, $scope.course);
                    return;
                }

                $scope.loadAll();
                $('#updateCourseModal').modal('hide');
                $scope.clear();
            }, function (response) {
                $scope.courseError = response.headers('Failure');
            });
        }

        $scope.$watch('currentCourseGroup', function (v) {
            localStorage.setItem('currentCourseGroup', $scope.currentCourseGroup);
            $scope.loadAll();
        });

        $scope.$watch('currentCourseType', function (v) {
            localStorage.setItem('currentCourseType', $scope.currentCourseType);
            $scope.loadAll();
      });
    }
);
