'use strict';

angular.module('lmsApp')
    .controller('videoConfController', function ($scope, $window, $state, $interval,
                                                 videoConf, Principal) {

        $scope.idMeeting = null;
        $scope.videoServError = null;
        $scope.meetingDataConnection = {};
        $scope.loadAll = function () {
            videoConf.query(function (result) {
                $scope.videoConfs = result;
            });
        };
        $scope.loadAll();

        $scope.getAccountData = function () {
            Principal.identity().then(function (account) {
                $scope.account = account;
            });
        };
        $scope.getAccountData();

        $scope.openVideoConf = function (videoconf) {
            $scope.meetingDataConnection.firstName = $scope.account.firstName;
            $scope.meetingDataConnection.lastName = $scope.account.lastName;
            $scope.meetingDataConnection.meetingId = videoconf.meetingId;
            $scope.meetingDataConnection.meetingName = videoconf.meetingName;
            $scope.meetingDataConnection.role = 'user';
            videoConf.update($scope.meetingDataConnection, function (result) {
                $window.open(result.url, '_blank');
                $scope.clear();
            });
        };
        $scope.openVideoConfModerator = function (videoconf) {
            $scope.meetingDataConnection.firstName = $scope.account.firstName;
            $scope.meetingDataConnection.lastName = $scope.account.lastName;
            $scope.meetingDataConnection.meetingId = videoconf.meetingId;
            $scope.meetingDataConnection.meetingName = videoconf.meetingName;
            $scope.meetingDataConnection.role = 'moderator';
            videoConf.update($scope.meetingDataConnection, function (result) {
                $window.open(result.url, '_blank');
                $scope.clear();
            });
        };

        $scope.create = function () {
            $scope.data = [$scope.account.firstName, $scope.account.lastName, $scope.nameVideoConf];
            videoConf.create($scope.data,
                function (result) {
                    $window.open(result.url, '_blank');
                    $('#saveVideoConfModal').modal('hide');
                },
                function (error) {
                    $scope.videoServError = error.statusText;
                });
        };

        $scope.delete = function (id) {
            $scope.idMeeting = id;
            $('#deleteVideoConfConfirmation').modal('show');

        };
        $scope.confirmDelete = function () {
            videoConf.delete({id: $scope.idMeeting},
                function () {
                    $window.location.reload();
                    $('#deleteVideoConfConfirmation').modal('hide');
                });
        };

        $scope.clear = function () {
            $scope.meetingDataConnection = {};
        };
    }
)
;
