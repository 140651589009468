angular.module('lmsApp').controller('UserActivityController', function($scope, User) {
  $scope.userActivityStories = {};

  $scope.pagination = {
    sorting_fields: ['loggedInTS'],
    sorting_direction: 'desc',
    page: 1,
    per_page: 20,
    total_pages: 0
  };

  $scope.load = function() {
    User.getHistoryOfLoggedUsers($scope.pagination, function(result) {
      $scope.userActivityStories = result;

      $scope.pagination.total_pages = result.totalPages;
    });
  };

  $scope.changePerPageCount = function(number) {
    $scope.pagination.per_page = number;
    $scope.pagination.page = 1;
    $scope.load();
  };
  $scope.sort = function(fields) {
    //Меняем порядок сортировки, если выбран тот же столбец
    if (fields[0] === $scope.pagination.sorting_fields[0]) {
      if ($scope.pagination.sorting_direction === 'asc') {
        $scope.pagination.sorting_direction = 'desc';
      } else {
        $scope.pagination.sorting_direction = 'asc';
      }
    } else {
      //Меняем столбец сортировки
      $scope.pagination.sorting_fields = fields;
      $scope.pagination.sorting_direction = 'asc';
    }
    $scope.load();
  };
  $scope.load();
});
