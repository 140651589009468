'use strict';

angular.module('lmsApp')
    .factory('School', function ($resource, API) {
        var schoolApi = '/admin/schools'
        return $resource('api/schools/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getByTerritory': {
                method: 'GET',
                url: schoolApi + '/location/:id',
                isArray: true
            },            
            'getById': {
                method: 'GET',
                url: schoolApi + '/:id'
            },
            'update': {
                method:'PUT'
            },
            'getAllschools': {
                method: 'GET',
                url: 'api/schools',
                isArray: true
            }
        });
    });
