'use strict';
angular.module('lmsApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('coursePreparingForOlympicsOpenTasks', {
        parent: 'entity',
        url: '/course/:id/preparingForOlympicsOpenTasks',
        data: {
          roles: ['OP_MANAGE_COURSES', 'OP_VIEW_COURSES'],
          pageTitle: 'lmsApp.course.openTasks.title'
        },
        views: {
          'content@': {
            templateUrl: 'app/entities/course/preparingForOlympics/openTask/openTasks.html',
            controller: 'coursePreparingForOlympicsOpenTasksController'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('course');
            return $translate.refresh();
          }]
        }
      })
  });
