'use strict';

angular.module('lmsApp')
    .controller('videoRecordController', function ($scope, $window, $state, $interval,
                                                   videoConf) {
        $scope.loadAll = function () {
            videoConf.getRecords({recordId: 'all'}, function (result) {
                $scope.videoRecords = result;
            });
        };
        $scope.loadAll();
        $scope.showRecords = function (url) {
            $window.open(url, '_blank');
        };
        $scope.delete = function (id) {
          videoConf.deleteRecord({recordId: id},function(result) {
              $scope.loadAll();
            });
        };

    }
)
;
