'use strict';

angular.module('lmsApp')
    .factory('CourseDomain', function ($resource) {
        return $resource('api/courseDomain/:id', {}, {
            'update': { method: 'PUT' },
            'getAll': {
                method: 'GET',
                url: 'api/courseDomain/all',
                isArray: true
            },
        });
    }
);
