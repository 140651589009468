'use strict';

angular.module('lmsApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('courseGroup', {
                parent: 'entity',
                url: '/courseGroup',
                data: {
                    roles: ['OP_VIEW_COURSE_GROUP', 'OP_MANAGE_COURSE_GROUP'],
                    pageTitle: 'CourseGroups'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/courseGroup/courseGroups.html',
                        controller: 'CourseGroupController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('courseGroup');
                        return $translate.refresh();
                    }]
                }
            })
            .state('courseGroupDetail', {
                parent: 'entity',
                url: '/courseGroup/:id',
                data: {
                    roles: ['OP_VIEW_COURSE_GROUP', 'OP_MANAGE_COURSE_GROUP'],
                    pageTitle: 'CourseGroup'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/courseGroup/courseGroup-detail.html',
                        controller: 'CourseGroupDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('courseGroup');
                        $translatePartialLoader.addPart('course');
                        return $translate.refresh();
                    }]
                }
            });
    });
