angular.module('lmsApp')
    .factory('Courses', function ($resource, API) {
        var courseApi = '/admin/courses'
        return $resource(courseApi, {}, {

            getByMethodologistId: {
                method: 'GET',
                url: courseApi + '/methodologist/:id',
                isArray: true,
            },
            getByTeacherId: {
                method: 'GET',
                url: courseApi + '/teacher/:id',
                isArray: true,                
            },            
        });
    })