'use strict';

angular.module('lmsApp')
    .controller('VideoConfConfigDetailController', function ($scope, $stateParams, Account, Videoconfconfig, VideoConferences, Users, UserGroup) {
        $scope.videoConfConfig = {};

        $scope.userList = {};
        $scope.addGroupOfUser = {};
        $scope.error = {
            cruduserError: false,
            cruduserGroupError: false
        };

        $scope.userOptions = {
            'page': 1,
            'per_page': 10,
            'sorting_fields': ['id'],
            'sorting_direction': 'asc'
        };
        $scope.ugOptions = {
            'page': 1,
            'per_page': 10,
            'sorting_fields': ['id'],
            'sorting_direction': 'asc'
        };
        $scope.load = function (id) {
            Videoconfconfig.get({
                id: id
            }, function (result) {
                $scope.videoConfConfig = result;
                $scope.getParticipants();
                $scope.getUserGroups();
            });
        };

        $scope.showAddUserDialog = function () {
            $('#addUserModal').modal('show');
        };

        $scope.showDeleteUserDialog = function () {
            $('#deleteUserModal').modal('show');
        };

        $scope.showDeleteAllParticipantsDialog = function () {
            $('#deleteAllParticipantsModal').modal('show');
        };

        $scope.showAddGroupUserDialog = function () {
            $('#addGroupUserModal').modal('show');
        };

        $scope.addUser = function () {
            var conferenceUser = {
                firstId: $scope.videoConfConfig.id,
                secondId: $scope.newUser.id
            }
            VideoConferences.addParticipant(conferenceUser,
                function (data) {
                    $scope.videoConfConfig = data;
                    $('#addUserModal').modal('hide');
                    $scope.clear();
                    $scope.load($stateParams.id);

                },
                function (response) {
                    if (response.status === 403) {
                        $scope.cruduserError = 'FORBIDDEN_EXCEPTION';
                        $scope.error.cruduserError = true;
                    }
                })
        };

        $scope.addGroupUser = function () {

            $scope.addGroupOfUser.videoConfConfig = $scope.videoConfConfig;
            $scope.addGroupOfUser.userGroupId = $scope.newGroup.id;
            Videoconfconfig.updateGroupUser($scope.addGroupOfUser,
                function (data) {
                    $scope.videoConfConfig = data;
                    $('#addGroupUserModal').modal('hide');
                    $scope.newGroup = void 0;
                    $scope.clear();
                    $scope.load($stateParams.id);
                }, function(response){
                    if (response.status === 400) {
                        $scope.cruduserGroupError = response.headers('failure')
                        $scope.error.cruduserGroupError = true;
                    }
                });
        };

        $scope.deleteUser = function () {
            var delConferenceUser = {
                id: $scope.videoConfConfig.id,
                user_id: $scope.removedUserId
            }
            VideoConferences.deleteParticipant(delConferenceUser,
                function (data) {
                    $scope.videoConfConfig = data;
                    $('#deleteUserModal').modal('hide');
                    $scope.clear();
                    $scope.load($stateParams.id);
                }
            );
        };

        $scope.deleteAllParticipants = function () {
            VideoConferences.deleteAllParticipants({
                conferenceId: $scope.videoConfConfig.id
            }, function() {
                $('#deleteAllParticipantsModal').modal('hide');
                $scope.clear();
                $scope.load($stateParams.id);
            });
        };

        $scope.clear = function () {
            $scope.newUserId = '';
            $scope.newUser = null;
            $scope.removedUserId = null;
            $scope.addGroupOfUser = {};
        };

        $scope.getParticipants = function(loadOptions) {
            loadOptions = (loadOptions) ? loadOptions : $scope.userOptions;
            loadOptions.id = $scope.videoConfConfig.id;
            Users.getUsersForVideoConference(loadOptions, function (result, headers) {
                $scope.userList = {
                    'items' : result,
                    'total' : headers('X-Total-Count'),
                };
            });
        };

        $scope.getUserGroups = function(loadOptions) {
            loadOptions = (loadOptions) ? loadOptions : $scope.ugOptions;
            UserGroup.query(loadOptions, function (result, headers) {
                $scope.userGroups = {
                    'items' : result,
                    'total' : headers('X-Total-Count'),
                };
            });
        };

        $scope.load($stateParams.id);
    });
