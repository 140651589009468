'use strict';

angular.module('lmsApp').controller('ConversationController', function ($scope, Conversation, Conversations, User) {
    $scope.conversations = [];
    $scope.isUpdate = false;
    $scope.sorting = {
        fields: ['date'],
        direction: 'desc'
    };
    $scope.pagination = {
        page: 1,
        total: 0,
        perPage: 20
    };

    $scope.loadAll = function () {
        Conversation.query({
            page: $scope.pagination.page,
            per_page: $scope.pagination.perPage,
            sorting_fields: $scope.sorting.fields,
            sorting_direction: $scope.sorting.direction
        }, function (result, headers) {
            $scope.pagination.total = headers('X-Total-Count');
            $scope.conversations = result.map(function(conv){
                var date = new Date(conv.date);
                conv.dateTime =  date.getHours() + ':' + date.getMinutes() + '  ' + date.getDay() + '/' + date.getMonth()+ '/' + date.getFullYear()
                return conv
            });
        });
    };

    $scope.create = function () {
        if ($scope.isUpdate) {
            $scope.isUpdate = false;
            Conversations.update($scope.conversation, function () {
                $scope.loadAll();
                $('#saveConversationModal').modal('hide');
                $scope.clear();
            });
        } else {
            Conversations.create($scope.conversation, function () {
                $scope.loadAll();
                $('#saveConversationModal').modal('hide');
                $scope.clear();
            });
        }
    };

    $scope.update = function (id) {
        $scope.isUpdate = true;

        Conversation.get({
            id: id
        }, function (result) {
            $scope.conversation = result;
            $('#saveConversationModal').modal('show');
        });
    };

    $scope.delete = function (id) {
        Conversation.get({
            id: id
        }, function (result) {
            $scope.conversation = result;
            $('#deleteConversationConfirmation').modal('show');
        });
    };

    $scope.confirmDelete = function (id) {
        Conversation.delete({
                id: id
            },
            function () {
                $scope.loadAll();
                $('#deleteConversationConfirmation').modal('hide');
                $scope.clear();
            });
    };

    $scope.clear = function () {
        $scope.conversation = {
            id: null,
            name: null,
            distribution: false
        };
        $scope.editForm.$setPristine();
        $scope.editForm.$setUntouched();
    };

    $scope.changePerPageCount = function (number) {
        $scope.pagination.perPage = number;
        $scope.pagination.page = 1;
        $scope.loadAll();
    };

    $scope.sort = function (fields) {
        //Меняем порядок сортировки, если выбран тот же столбец
        if (fields[0] === $scope.sorting.fields[0]) {
            if ($scope.sorting.direction === 'asc') {
                $scope.sorting.direction = 'desc';
            } else {
                $scope.sorting.direction = 'asc';
            }
        } else {
            //Меняем столбец сортировки
            $scope.sorting.fields = fields;
            $scope.sorting.direction = 'asc';
        }

        $scope.pagination.page = 1;
        $scope.loadAll();
    };

    $scope.loadAll();
});
