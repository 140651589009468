'use strict';

angular.module('lmsApp')
    .controller('teacherCoursesDetailController', function ($scope, $stateParams, Users) {
        $scope.user = {};

        $scope.load = function (id) {
            Users.getTeacherCourses({'teacherId': id}, function (user) {
                $scope.user = user;
            });
        };

        $scope.load($stateParams.id);

    });
