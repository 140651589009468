/**
 * Created by oem on 21.12.16.
 */
'use strict';

angular.module('lmsApp')
    .factory('Locations', function ($resource, API) {
        var locationApi = '/admin/locations'
        return $resource(locationApi, {}, {
            query: {
                method: 'GET',
                url: locationApi,
                isArray: true
            },
            children: {
                method: 'GET',
                url: locationApi + '/children/:id',
                isArray: true
            },
            addLocation: {
                method: 'POST',
                url: locationApi
            },
            editLocation: {
                method: 'PUT',
                url: locationApi
            },
            addAdmin: {
                method: 'POST',
                url: locationApi + '/admins'
            },
            deleteAdmin: {
                method: 'DELETE',
                url: locationApi + '/admins'
            },
            addCoordinator: {
                method: 'POST',
                url: locationApi + '/coordinators'
            },
            deleteCoordinator: {
                method: 'DELETE',
                url: locationApi + '/coordinators'
            }
        });
    })
