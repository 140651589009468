'use strict';

angular.module('lmsApp').directive('assignmentComments', function ($stateParams, AssignmentComment, Upload, Principal, ParseLinks) {
    return {
        restrict: 'E',
        controller: function ($scope) {

            var _isStudent = Principal.isInRole('ROLE_STUDENT');

            $scope.comment = {};
            $scope.comments = [];
            
            $scope.files = [];
            $scope.filesToUpload = [];
            $scope.uploadInProgress = false;

            $scope.page = 1;
            $scope.loadPage = function (page) {

                $scope.page = page;

                if (Principal.isInRole('OP_VIEW_COURSE_SECTIONS')) {
                    AssignmentComment.query(
                        {
                            courseId: $stateParams.id,
                            imsccId: $stateParams.imsccId,
                            userId: $stateParams.studentId,
                            page: $scope.page,
                            per_page: 10
                        },
                        function (data, headers) {
                            $scope.links = ParseLinks.parse(headers('link'));
                            $scope.comments = $scope.comments.concat(data);
                        }
                    );
                } else {
                    // get comments for the current student
                    AssignmentComment.query(
                        {
                            courseId: $stateParams.id,
                            imsccId: $stateParams.imsccId,
                            page: $scope.page,
                            per_page: 10
                        },
                        function (data, headers) {
                            $scope.links = ParseLinks.parse(headers('link'));
                            $scope.comments = $scope.comments.concat(data);
                        }
                    );
                }
            };

            $scope.loadComments = function (params) {
                if (Principal.isInRole('OP_VIEW_COURSE_SECTIONS')) {
                    $scope.comments = AssignmentComment.query(
                        {
                            courseId: $stateParams.id,
                            imsccId: params.imsccId,
                            userId: params.studentId,
                            page: $scope.page,
                            per_page: 10
                        }
                    );
                    $scope.comment = AssignmentComment.getUnpublished(
                        {courseId: $stateParams.id, imsccId: params.imsccId, userId: params.studentId}
                    );
                } else {
                    // get comments for the current student
                    $scope.comments = AssignmentComment.query(
                        {
                            courseId: $stateParams.id,
                            imsccId: params.imsccId,
                            page: $scope.page,
                            per_page: 10
                        }
                    );
                    $scope.comment = AssignmentComment.getUnpublished({courseId: $stateParams.id, imsccId: params.imsccId});
                }
            };

            $scope.loadComments($stateParams);

            $scope.clearMessageForm = function () {
                $scope.comment = {};
                $scope.commentForm.$setPristine();
                $scope.commentForm.$setUntouched();
            };

            /**
             * Saves or updates a comment and optionally publish it.
             *
             * @param publish whether or not this comment should be published
             * @returns {Undefined}
             */
            $scope.saveComment = function (publish) {
                var action = 'update',
                    studentId;

                $scope.comment.published = publish || false;
                $scope.comment.commentDate = new Date();

                if (!$scope.comment.id) {
                    action = 'create';
                    studentId = $stateParams.studentId;
                }

                if (_isStudent) {
                    AssignmentComment[action](
                        {courseId: $stateParams.id, imsccId: $stateParams.imsccId},
                        $scope.comment,
                        (publish ? publishCommentSuccessHandler : saveCommentSuccessHandler)
                    );
                } else {
                    AssignmentComment[action](
                        {courseId: $stateParams.id, imsccId: $stateParams.imsccId, userId: studentId},
                        $scope.comment,
                        (publish ? publishCommentSuccessHandler : saveCommentSuccessHandler)
                    );
                }
            };

            function saveCommentSuccessHandler(data, getHeaders) {
                uploadFiles(getCommentId(getHeaders().location));
                if (!$scope.uploadInProgress) {
                    // reload comment and show pop up immediately if there are no files to upload
                    if (Principal.isInRole('OP_VIEW_COURSE_SECTIONS')) {
                        $scope.comment = AssignmentComment.getUnpublished(
                            {courseId: $stateParams.id, imsccId: $stateParams.imsccId, userId: $stateParams.studentId});
                    } else {
                        $scope.comment = AssignmentComment.getUnpublished(
                            {courseId: $stateParams.id, imsccId: $stateParams.imsccId});
                    }
                    alert('Сохранено');
                }
            }

            function publishCommentSuccessHandler(data, getHeaders) {
                uploadFiles(getCommentId(getHeaders().location));
                if (!$scope.uploadInProgress) {
                    // reload last 10 comments immediately if there are no files to upload
                    $scope.clearMessageForm();
                    $scope.page = 1;
                    $scope.loadComments($stateParams);
                }
            }

            function getCommentId(locationStr) {
                var locationParts = locationStr.split('/');
                return locationParts[locationParts.indexOf('assignmentComments') + 1];
            }

            $scope.$watch('files', addToUploadList);

            $scope.$watch('comment.note', enableOrDisableSubmit);

            $scope.removeFile = function (fileId) {
                for (var i = 0; i < $scope.comment.files.length; i++) {
                    if ($scope.comment.files[i].id === fileId) {
                        $scope.comment.files.splice(i, 1);
                        break;
                    }
                }
                AssignmentComment.removeFile({id: $scope.comment.id, fileId: fileId});
            };

            /**
             * Enables or disables form submission. A user can save his/her answer only if it contains a note
             * or attached file.
             */
            function enableOrDisableSubmit() {
                if ($scope.filesToUpload.length === 0 && (!$scope.comment.note || $scope.comment.note.length === 0)) {
                    $scope.commentForm.isValid.$setValidity('required', false);
                } else {
                    $scope.commentForm.isValid.$setValidity('required', true);
                }
            }

            $scope.isFlashInstalled = function () {
                var hasFlash = false;
                try {
                    var fo = new ActiveXObject('ShockwaveFlash.ShockwaveFlash');
                    if (fo) {
                        hasFlash = true;
                    }
                } catch (e) {
                    if (navigator.mimeTypes && navigator.mimeTypes['application/x-shockwave-flash'] !== undefined && navigator.mimeTypes['application/x-shockwave-flash'].enabledPlugin) {
                        hasFlash = true;
                    }
                }
                return hasFlash;
            };

            /**
             * Whether or not HTML5 File API is supported.
             *
             * @returns {Boolean} true if supported or false otherwise
             */
            $scope.isFileSupported = function () {
                return (typeof File !== 'undefined' && typeof FileReader !== 'undefined');
            };

            $scope.removeFromUploadList = removeFromUploadList;

            $scope.getFileName = function (filePath) {
                var pathParts = filePath.split('/');
                return pathParts[pathParts.length - 1];
            };

            function addToUploadList() {
                if ($scope.files === null || $scope.files.length === 0) {
                    return;
                }

                skipFile:for (var i in $scope.files) {
                    for (var j in $scope.filesToUpload) {
                        if ($scope.files[i].name === $scope.filesToUpload[j].name) {
                            continue skipFile;
                        }
                    }
                    $scope.filesToUpload.push($scope.files[i]);
                }

                enableOrDisableSubmit();
            }

            function removeFromUploadList(fileName) {
                for (var i = 0; i < $scope.filesToUpload.length; i++) {
                    if ($scope.filesToUpload[i].name === fileName) {
                        $scope.filesToUpload.splice(i, 1);
                        break;
                    }
                }

                enableOrDisableSubmit();
            }

            function uploadFiles(msgId) {
                if ($scope.filesToUpload.length > 0) {
                    $scope.uploadInProgress = true;
                    for (var i = 0; i < $scope.filesToUpload.length; i++) {
                        doUpload($scope.filesToUpload[i], msgId);
                    }
                }
            }

            function doUpload(file, id) {
                Upload.upload({
                    url: 'api/assignmentComments/' + id +'/file',
                    file: file
                }).progress(function (evt) {
                    file.uploadProgress = parseInt(100.0 * evt.loaded / evt.total);
                }).success(function (data, status, headers) {
                    file.uploadSuccessful = true;
                    setTimeout(function () {
                        removeAndReload(file.name);
                    }, 2000);
                }).error(function (data, status, headers) {
                    file.uploadError = true;
                    setTimeout(function () {
                        removeAndReload(file.name);
                    }, 5000);
                });
            }

            /**
             * Removes file from upload list and loads comments if the uploading is done.
             *
             * @param fileName the name of file to remove
             */
            function removeAndReload(fileName) {

                removeFromUploadList(fileName);

                if ($scope.filesToUpload.length === 0) {
                    $scope.uploadInProgress = false;
                    if ($scope.comment.published) {
                        $scope.clearMessageForm();
                        $scope.page = 1;
                        $scope.loadComments($stateParams);
                    } else {
                        // do not clear the form if comment not published, just reload it
                        if (Principal.isInRole('OP_VIEW_COURSE_SECTIONS')) {
                            $scope.comment = AssignmentComment.getUnpublished(
                                {courseId: $stateParams.id, imsccId: $stateParams.imsccId, userId: $stateParams.studentId});
                        } else {
                            $scope.comment = AssignmentComment.getUnpublished(
                                {courseId: $stateParams.id, imsccId: $stateParams.imsccId});
                        }
                        alert('Сохранено');
                    }
                }

                $scope.$apply();
            }

        },
        templateUrl: 'app/entities/course/openTask/openTaskComments.html'
    };
});
