/**
 * Created by oem on 21.12.16.
 */
'use strict';

angular.module('lmsApp')
    .factory('Users', function ($resource, API) {
        var newUserApi = '/admin/users'
        return $resource(newUserApi, {}, {

            getAll: {
                method: 'GET',
                isArray: true,
                url: newUserApi
            },
            getById: {
                method: 'GET',
                url: newUserApi + '/:id'
            },
            deleteById: {
                method: 'DELETE',
                url: newUserApi + '/:id'
            },
            updateActivationStatus: {
                method: 'PUT',
                url: newUserApi + '/activate'
            },
            create: {
                method: 'POST',
                url: newUserApi,
                transformResponse: function(data, headers){
                    var response = {}
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            },
            update: {
                method: 'PUT',
                url: newUserApi,
                transformResponse: function(data, headers){
                    var response = {}
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            },
            getTutors: {
                method: 'GET',
                url: newUserApi + '/group/tutors',
                isArray: true
            },
            getTeachersByCourse: {
                method: 'GET',
                url: newUserApi + '/teachers/:courseId',
                isArray: true
            },
            getStudentsByGroup: {
                method: 'GET',
                url: newUserApi + '/group/students/:groupId',
                isArray: true
            },
            getParentsForStudent: {
                method: 'GET',
                url: newUserApi + '/parents/:id',
                isArray: true
            },
            getStudenById: {
                method: 'GET',
                url: newUserApi + '/student/:id'
            },
            addParent: {
                method: 'POST',
                url: newUserApi + '/parents'
            },
            deleteParent: {
                method: 'DELETE',
                url: newUserApi + '/parents'
            },
            distanceEducationCoordinators: {
                method: 'GET',
                url: newUserApi + '/school/coordinators/:schoolId',
                isArray: true
            },
            teachers: {
                method: 'GET',
                url: newUserApi + '/school/teachers/:schoolId',
                isArray: true
            },
            tutors: {
                method: 'GET',
                url: newUserApi + '/school/tutors/:schoolId',
                isArray: true
            },
            headTeachers: {
                method: 'GET',
                url: newUserApi + '/school/headteachers/:schoolId',
                isArray: true
            },
            deputyHeadTeachers: {
                method: 'GET',
                url: newUserApi + '/school/deputy/:schoolId',
                isArray: true
            },
            methodologists: {
                method: 'GET',
                url: newUserApi + '/school/methodologists/:schoolId',
                isArray: true
            },
            admins: {
                method: 'GET',
                url: newUserApi + '/school/admins/:schoolId',
                isArray: true
            },
            psychologists: {
                method: 'GET',
                url: newUserApi + '/school/psychologists/:schoolId',
                isArray: true
            },
            getTeacherCourses: {
                method: 'GET',
                url: newUserApi + '/courses/teacher/:teacherId'
            },
            getMethodologistCourses: {
                method: 'GET',
                url: newUserApi + '/courses/methodologist/:methodologistId'
            },
            deleteTeacherCourse: {
                method: 'DELETE',
                url: newUserApi + '/course/teacher'
            },
            deleteMethodologistCourse: {
                method: 'DELETE',
                url: newUserApi + '/course/methodologist'
            },
            addTeacherCourse: {
                method: 'POST',
                url: newUserApi + '/course/teacher'
            },
            addMethodologistCourse: {
                method: 'POST',
                url: newUserApi + '/course/methodologist'
            },
            getAllUsersForConversation: {
                method: 'GET',
                url: newUserApi + '/message',
                isArray: true
            },
            getUsersForVideoConference: {
                method: 'GET',
                url: newUserApi + '/conference',
                isArray: true
            },
            getUsersForStat: {
                method: 'GET',
                url: '/conversation/users/',
                isArray: true
            },
            getLocCoord: {
                method: 'GET',
                url: newUserApi + '/location/coordinators',
                isArray: true
            },
            getLocAdmins: {
                method: 'GET',
                url: newUserApi + '/location/admins',
                isArray: true
            },
            // getStatFile: {
            //     method: 'GET',
            //     url: '/report/journal?course=295&groupID=3688594',
            //     isArray: true
            // }
        });
    });
