'use strict';

angular.module('lmsApp')
    .factory('AssignmentProgress', function ($resource) {
        return $resource('api/assignmentProgresses/', {}, {

            getAllByCourseIdAndStudentIdsFromLesson: {
                method: 'GET',
                url: 'api/assignmentProgresses/course/:courseId/lesson',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                },
                isArray: true
            },
            getAllByCourseIdAndStudentIdsFromTopic: {
                method: 'GET',
                url: 'api/assignmentProgresses/course/:courseId/topic',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                },
                isArray: true
            },
            updateRequireAndHomeAssignmentById: {
                method: 'PUT',
                url: '/api/assignmentProgresses/:id/require/:require/home/:home',
                params: {
                    id: '@id',
                    require: '@require',
                    home: '@home'
                }
            },
            getAllByAssignmentIdAndTeacherId: {
                method: 'GET',
                url: 'api/assignmentProgresses/courses/:courseId/assignments/:imsccId/teacher',
                isArray: true
            },
            getStatus: {
                method: 'GET',
                url: 'api/assignmentProgresses/status/courses/:courseId/assignments/:imsccId/students/:userId'
            },
            getByAssignmentIdAndStudentId: {
                method: 'GET',
                url: 'api/assignmentProgresses/courses/:courseId/assignments/:imsccId/students/:userId'
            },
            setMark: {
                method: 'PUT',
                url: 'api/assignmentProgresses/mark/courses/:courseId/assignments/:imsccId/students/:userId'
            },
            publishMark: {
                method: 'PUT',
                url: 'api/assignmentProgresses/:id/mark/published'
            }
        });
    });
