'use strict';

angular.module('lmsApp')
    .controller('SettingsController', function ($scope, Principal, Auth, Upload, User, UserSettings, Locations) {
        // photo to upload
        $scope.file = null;

        // error flags
        $scope.success = null;
        $scope.error = null;
        $scope.errorUpload = {};
        $scope.errorUpload.weight = false;
        $scope.errorUpload.contentType = false;
        $scope.errorUpload.extension = false;
        $scope.errorUpload.other = false;

        // fetch account
        Principal.identity().then(function(account) {
            $scope.settingsAccount = account;
            UserSettings.get({id: $scope.settingsAccount.id}, function(data) {
                $scope.userSettings = data;
                Locations.query({type: 'TERRITORY'},function (result) {
                    $scope.territories = result;
                    for (var i = 0; i < $scope.territories.length; i++) {
                        if ($scope.userSettings.currentAddress !== null &&
                            $scope.userSettings.currentAddress.territory !== null &&
                            $scope.userSettings.currentAddress.territory.id == $scope.territories[i].id) {
                            $scope.userSettings.currentAddress.territory = $scope.territories[i];
                        }
                    }
                });


            });
        });

        // update account data
        $scope.save = function () {
            Auth.updateAccount($scope.settingsAccount).then(function() {
                $scope.error = null;
                $scope.success = 'OK';
                Principal.identity().then(function(account) {
                    $scope.settingsAccount = account;
                });
            }).catch(function() {
                $scope.success = null;
                $scope.error = 'ERROR';
            });
            UserSettings.update($scope.userSettings, function() {
                $scope.error = null;
                $scope.success = 'OK';
                UserSettings.get({id: $scope.settingsAccount.id}, function(data) {
                    $scope.userSettings = data;
                    $scope.userSettings.birthDate = new Date(data.birthDate);
                    Locations.query({type: 'TERRITORY'}, function (result) {
                        $scope.territories = result;
                        for (var i = 0; i < $scope.territories.length; i++) {
                            if ($scope.userSettings.currentAddress !== null &&
                                $scope.userSettings.currentAddress.territory !== null &&
                                $scope.userSettings.currentAddress.territory.id == $scope.territories[i].id) {
                                $scope.userSettings.currentAddress.territory = $scope.territories[i];
                            }
                        }
                    });
                });
            }, function(err) {
                $scope.success = null;
                $scope.error = 'ERROR';
            });
        };

        // rough validation
        $scope.isFileSupported = function() {
            return (typeof File !== 'undefined' && typeof FileReader !== 'undefined');
        };

        // check for "Upload!" button enabling
        $scope.isFilePresentAndSupported = function() {
            return ($scope.file  && $scope.isFileSupported() );
        };

        // uploading profile photo
        $scope.uploadPhoto = function() {
            // multiple file prohibited in the view, thus could be either 0 or 1
            if ($scope.file.size > 1) {
                Upload.upload({
                    // POST /account/profile_photo saves photo
                    url: 'api/account/profile_photo',
                    file: $scope.file
                }).success(function () {
                    // reload on successful upload
                    $scope.profilePhotoPath = '';
                    User.get({login: $scope.settingsAccount.login}, function (data) {
                        $scope.settingsAccount.profilePhotoPath = data.profilePhotoPath;
                        $scope.clearFiles();
                        $scope.errorUpload = {weight: false, contentType: false, extension: false, other: false};
                    });
                }).error(function (data, status, headers) {
                    $scope.errorUpload = {weight: false, contentType: false, extension: false, other: false};
                    if (status === 400) {
                        if (headers('Failure') === 'Uploaded file is heavier then 500KB.') {
                            $scope.errorUpload.weight = true;
                        } else if (headers('Failure') === 'Uploaded file\'s content type is not allowed.') {
                            $scope.errorUpload.contentType = true;
                        } else if (headers('Failure') === 'Uploaded file is not a supported picture.') {
                            $scope.errorUpload.extension = true;
                        } else if (headers('Failure') === 'Uploaded file is larger then 500x500.') {
                            $scope.errorUpload.large = true;
                        }
                    }
                    $scope.clearFiles();
                });
            }
        };

        $scope.clearFiles = function() {
            $scope.file = null;
        };
    });
