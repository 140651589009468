'use strict';
// Чтобы в админке при создании/редактировании заданий модальное окно с несохраненными данными не закрывалось при случайном клике на бэкграунде.
angular.module('lmsApp').service('StaticBackdropModal', [
  'dialogs',
  function(dialogs) {
    var BACKDROP = {
      backdrop: 'static',
      keyboard: false
    };
    this.create = function(url, ctrlr, data, opts, ctrlAs) {
      var options = opts ? opts : BACKDROP;
      return dialogs.create(url, ctrlr, data, options, ctrlAs);
    };
    this.error = function(header, msg, opts) {
      return dialogs.error(header, msg, opts);
    };
    this.notify = function(header, msg, opts) {
      return dialogs.notify(header, msg, opts);
    };
    this.confirm = function(header, msg, opts) {
      return dialogs.confirm(header, msg, opts);
    };
    this.wait = function(header, msg, progress, opts) {
      return dialogs.wait(header, msg, progress, opts);
    };
  }
]);
