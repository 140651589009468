'use strict';

angular.module('lmsApp')
    .directive('userImportLoading', ['$http', '$translate', function ($http, $translate) {
        return {
            restrict: 'A',
            scope: {
                name: '@userImportLoading'
            },
            link: function (scope, element, attrs) {
                var isLoading = function () {
                        return $http.pendingRequests.length > 0;
                    },
                    translated = '';

                $translate('lmsApp.userImport.steps.' + scope.name).then(function (translatedValue) {
                    translated = translatedValue;
                    element.text(translated);
                });

                element.on('click', function () {
                    var unbindWatcher = scope.$watch(isLoading, function (v) {
                        if (v) {
                            element.html('<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>');
                        } else {
                            element.text(translated);
                            unbindWatcher();
                        }
                    });
                });
            }
        };
    }]);
