'use strict';

angular.module('lmsApp').service('StaticPageHelper', [
  'dialogs',
  '$rootScope',
  function(dialogs, $rootScope) {
    var validHtmlMask = '<html><head><meta http-equiv="Content-Type" content="text/html; charset=utf-8"><title></title></head><body></body></html>',
      arrru = new Array(
        'Я',
        'я',
        'Ю',
        'ю',
        'Ч',
        'ч',
        'Ш',
        'ш',
        'Щ',
        'щ',
        'Ж',
        'ж',
        'А',
        'а',
        'Б',
        'б',
        'В',
        'в',
        'Г',
        'г',
        'Д',
        'д',
        'Е',
        'е',
        'Ё',
        'ё',
        'З',
        'з',
        'И',
        'и',
        'Й',
        'й',
        'К',
        'к',
        'Л',
        'л',
        'М',
        'м',
        'Н',
        'н',
        'О',
        'о',
        'П',
        'п',
        'Р',
        'р',
        'С',
        'с',
        'Т',
        'т',
        'У',
        'у',
        'Ф',
        'ф',
        'Х',
        'х',
        'Ц',
        'ц',
        'Ы',
        'ы',
        'Ь',
        'ь',
        'Ъ',
        'ъ',
        'Э',
        'э'
      ),
      arren = new Array(
        'Ya',
        'ya',
        'Yu',
        'yu',
        'Ch',
        'ch',
        'Sh',
        'sh',
        'Sh',
        'sh',
        'Zh',
        'zh',
        'A',
        'a',
        'B',
        'b',
        'V',
        'v',
        'G',
        'g',
        'D',
        'd',
        'E',
        'e',
        'E',
        'e',
        'Z',
        'z',
        'I',
        'i',
        'J',
        'j',
        'K',
        'k',
        'L',
        'l',
        'M',
        'm',
        'N',
        'n',
        'O',
        'o',
        'P',
        'p',
        'R',
        'r',
        'S',
        's',
        'T',
        't',
        'U',
        'u',
        'F',
        'f',
        'H',
        'h',
        'C',
        'c',
        'Y',
        'y',
        '`',
        '`',
        "'",
        "'",
        'E',
        'e'
      ),
      that = this;
    this.makeValidHtml = function(text, title) {
      if (!text || text.length === 0 || text === '') {
        text = validHtmlMask;
      } else {
        if (!/^<html/.test(text)) {
          var str = validHtmlMask;
          text = str.replace(/<body><\/body>/, '<body>' + text + '</body>');
        }
      }
      return text.replace(/<title>.*<\/title>/, '<title>' + (title ? title : '') + '</title>');
    };
    this.updateMetaIdentifier = function(html, identifier) {
      if (html && identifier) {
        var metaReg = /<meta[^>]+name=(\"|\')identifier(\"|\')[^>]+>/,
          headReg = /<head>.*<\/head>/,
          meta = '<meta name="identifier" content="' + identifier + '">',
          headEl;
        if (metaReg.test(html)) {
          html = html.replace(metaReg, meta);
        } else {
          if (headReg.test(html)) {
            html = html.replace(/<head>/, '<head>' + meta);
          } else {
            throw new SyntaxError('No head element');
          }
        }
        return html;
      } else {
        throw new SyntaxError('No attributes');
      }
    };
    this.makeValidFilename = function(filename) {
      if (filename) {
        filename = cyrill_to_latin(filename);
        filename = filename
          .replace(/\//g, '')
          .replace(/\.html$/g, '')
          .replace(/[^a-zA-Z0-9_]+/g, '-');
        return filename + '.html';
      }
    };
    this.copyToClipboard = function(idElement) {
      var elem = angular.element(idElement);
      elem[0].select();
      try {
        document.execCommand('copy');
      } catch (err) {
        console.log('unable to copy');
      }
    };
    this.initEditor = function(editorInstance, selector, data, _courseId) {
      if ($('#' + selector).length) {
        tinyMCE.init({
          selector: '#' + selector,
          plugins: 'link code contextmenu table textcolor colorpicker lists advlist',
          contextmenu: 'link image inserttable | cell row column deletetable lmsformula',
          external_plugins: {
            lmsimage: window.location.origin +
              '/app/components/util/tinymce/plugins/lmsimage/lmsimage.js',
            lmsformula: window.location.origin +
              '/app/components/util/tinymce/plugins/lmsformula/lmsformula.js'
          },
          extended_valid_elements: 'a[onclick|ng-click|*]',
          toolbar: 'nowrapbutton verticalAlign newdocument undo redo | bold italic underline strikethrough | forecolor backcolor | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | bullist numlist | link lmsimage code | table | lmsformula',
          language: 'ru',
          language_url: '/i18n/tinyMCE/ru.js',
          course_id: _courseId,
          convert_urls: false,
          formats: {
            nowrap_format: {
              inline: 'span',
              styles: {
                'white-space': 'nowrap',
                display: 'inline-block',
                'vertical-align': 'baseline'
              },
              attributes: {
                class: 'nowrap'
              }
            },
            verticalAlignMiddle: {
              selector: 'img',
              styles: {
                'vertical-align': 'middle'
              }
            },
            verticalAlignBaseline: {
              selector: 'img',
              styles: {
                'vertical-align': 'baseline'
              }
            },
            verticalAlignBottom: {
              selector: 'img',
              styles: {
                'vertical-align': 'bottom'
              }
            }
          },
          setup: function(editor) {
            editor.on('init', function(event) {
              editorInstance.instance = editor;
              editorInstance.instance.setContent(data);
              if (!data) {
                editor.execCommand('fontSize', false, '14pt');
              }
              $rootScope.$broadcast('tinyMCE ready');
            });
            editor.addButton('nowrapbutton', {
              text: 'NoBr',
              stateSelector: 'span.nowrap',
              tooltip: 'Запретить перенос',
              icon: false,
              onclick: function(event) {
                tinyMCE.activeEditor.formatter.toggle('nowrap_format');
              }
            });
            editor.addButton('verticalAlign', {
              type: 'listbox',
              text: 'VAlign',
              icon: false,
              onselect: function(e) {
                var val = this.value();
                switch (val) {
                  case 1:
                    tinyMCE.activeEditor.formatter.toggle('verticalAlignMiddle');
                    break;
                  case 2:
                    tinyMCE.activeEditor.formatter.toggle('verticalAlignBaseline');
                    break;
                  case 3:
                    tinyMCE.activeEditor.formatter.toggle('verticalAlignBottom');
                    break;
                }
              },
              values: [
                {
                  text: 'VAlign:middle',
                  stateSelector: 'img',
                  value: 1
                },
                {
                  text: 'VAlign:baseline',
                  stateSelector: 'img',
                  value: 2
                },
                {
                  text: 'VAlign:bottom',
                  stateSelector: 'img',
                  value: 3
                }
              ],
              onPostRender: function() {
                var self = this;
                editor.on('click', function(e) {
                  if (e.target.nodeName !== 'IMG') {
                    self.value(0);
                    return;
                  }
                  switch (e.target.style.verticalAlign) {
                    case 'middle':
                      self.value(1);
                      break;
                    case 'baseline':
                      self.value(2);
                      break;
                    case 'bottom':
                      self.value(3);
                      break;
                    default:
                      break;
                  }
                });
              }
            });
          },
          content_style: '.nowrap { white-space:nowrap;background-color:#dbdbdb; }'
        });
      } else {
        setTimeout(
          function() {
            that.initEditor(editorInstance, selector, data, _courseId);
          },
          500
        );
      }
    };
    function cyrill_to_latin(text) {
      if (text) {
        for (var i = 0; i < arrru.length; i++) {
          var reg = new RegExp(arrru[i], 'g');
          text = text.replace(reg, arren[i]);
        }
        return text;
      }
    }
  }
]);
