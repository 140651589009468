'use strict';

angular.module('lmsApp')
    .factory('AuditsService', function ($http) {
        return {
            findByDates: function (fromDate, toDate, page, per_page) {
                var formatDate = function (dateToFormat) {
                    if (dateToFormat !== undefined && !angular.isString(dateToFormat)) {
                        return dateToFormat.getYear() + '-' + dateToFormat.getMonth() + '-' + dateToFormat.getDay();
                    }
                    return dateToFormat;
                };
                return $http.get('api/audits/byDates', {
                    params: {
                        fromDate: formatDate(fromDate),
                        toDate: formatDate(toDate),
                        page: page,
                        per_page: per_page
                    }
                }).then(function (response) {
                    return response;
                });
            }
        };
    });
