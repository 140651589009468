'use strict';

angular.module('lmsApp').filter('schoolFilter', function () {
	return function (items, id) {
		var filtered = [];

		if (id === undefined || id === '') {
			return items;
		}

		angular.forEach(items, function (item) {
			if (item.territory && item.territory.id === id) {
				filtered.push(item);
			}
		});

		return filtered;
	};
});
