'use strict';

angular.module('lmsApp').directive('scrollToLastMessage', function () {
    return {
        restrict: 'A',
        require: '?^conversationMessages',
        link: function (scope, element) {
            if (scope.$last) {
                var offset = 0;
                // element's position() doesn't work correctly
                setTimeout(function() {
                    element.siblings().each(function () {
                        offset += $(this).outerHeight(true);
                    });
                    offset += element.outerHeight();
                    element.parent().scrollTop(offset);
                }, 1);
            }
        }
    };
}).directive('lmsInfiniteScroll', function () {
    return {
        restrict: 'A',
        scope: {
            disabled: '=lmsInfiniteScrollDisabled',
            watchParam: '=lmsInfiniteScrollWatch'
        },
        link: function (scope, element, attrs) {
            var rawElement = element[0];
            var loading = false;

            element.on('scroll', function() {
                if (scope.disabled) {
                    return;
                }

                if (rawElement.scrollTop <= 150) {
                    var offset = rawElement.scrollHeight - rawElement.scrollTop;
                    element.outerHeight();
                    // load more items
                    if (!loading) {
                        loading = true;
                        scope.$parent.$apply(attrs.lmsInfiniteScroll);
                        scope.$parent.$watch(scope.watchParam, function () {
                            // maintain the scroll offset
                            setTimeout(function () {
                                rawElement.scrollTop = rawElement.scrollHeight - offset;
                                loading = false;
                            }, 1);
                        });
                    }
                }
            });
        }
    };
});