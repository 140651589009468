/**
 * Created by nlyubich on 25.06.2015.
 */
'use strict';

angular.module('lmsApp')

    // replace mark in short answer question with text input
    .directive('parseShort', function ($compile) {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                parseShort: '='
            },
            link: function (scope, element, attrs) {

                // add correct/incorrect marker
                var addition = '<span ng-show="$root.isFinished && $root.responseString[\'' + scope.parseShort.imsccId + '\'][\'0\'].correct != undefined" ' +
                    'ng-class="{true:\'correct\', false:\'incorrect\'}[$root.responseString[\'' + scope.parseShort.imsccId + '\'][\'0\'].correct]">' +
                    '&#9632;&nbsp;' +
                    '</span>';

                // add input with feedback
                addition += '<input pu-elastic-input type="text" ' +
                    'ng-model="$root.quizAnswers[\'' + scope.parseShort.imsccId + '\'][\'0\']"/>' +
                    '<span ng-show="$root.isFinished" ' +
                    'ng-class="$root.responseString[\'' + scope.parseShort.imsccId + '\'][\'0\'].correct ? \'feedback correct\' : \'feedback incorrect\'" ' +
                    'ng-bind-html="$root.responseString[\'' + scope.parseShort.imsccId + '\'][\'0\'].text"></span>';

                if (scope.parseShort.text.indexOf('[]') < 0) {
                    addition = '<p>' + scope.parseShort.text + '</p>' + addition;
                } else {
                    addition = scope.parseShort.text.replace('[]', addition);
                }

                element.html($compile(addition)(scope));
            }
        };
    })

    // replace multiple marks in multiple blanks question with text inputs
    .directive('parseMultiple', function ($compile) {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                parseMultiple: '='
            },
            link: function (scope, element, attrs) {

                scope.questionText = '<p>' + scope.parseMultiple.text + '</p>';
                angular.forEach(scope.parseMultiple.responses, function (value, key) {

                    // correct/incorrect marker
                    var addition =
                        '<span ng-show="$root.isFinished && $root.responseString[\'' + scope.parseMultiple.imsccId + '\'][\'' + key + '\'].correct != undefined" ' +
                        'ng-class="{true:\'correct\', false:\'incorrect\'}[$root.responseString[\'' + scope.parseMultiple.imsccId + '\'][\'' + key + '\'].correct]">' +
                        '&#9632;&nbsp;' +
                        '</span>';

                    // input with feedback
                    addition +=
                        '<input pu-elastic-input type="text" ng-model="$root.quizAnswers[\'' + scope.parseMultiple.imsccId + '\'][\'' + key + '\']"/>' +
                        '<span ng-show="$root.isFinished" ' +
                        'ng-class="$root.responseString[\'' + scope.parseMultiple.imsccId + '\'][\'' + key + '\'].correct ? \'feedback correct\' : \'feedback incorrect\'" ' +
                        'ng-bind-html="$root.responseString[\'' + scope.parseMultiple.imsccId + '\'][\'' + key + '\'].text"></span>';

                    scope.questionText = scope.questionText.replace('[' + value.text + ']', addition);
                });

                element.html($compile(scope.questionText)(scope));
            }
        };
    })

    // replace marks in multiple dropdown question with dropdown inputs
    .directive('parseDropdown', function ($compile) {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                parseDropdown: '='
            },
            link: function (scope, element, attrs) {
                scope.questionText = '<p>' + scope.parseDropdown.text + '</p>';
                angular.forEach(scope.parseDropdown.responses, function (value, key) {

                    // correct/incorrect marker
                    var select = '<span ng-show="$root.isFinished && $root.responseString[\'' + scope.parseDropdown.imsccId + '\'][\'' + key + '\'].correct != undefined" ' +
                        'ng-class="{true:\'correct\', false:\'incorrect\'}[$root.responseString[\'' + scope.parseDropdown.imsccId + '\'][\'' + key + '\'].correct]">' +
                        '&#9632;&nbsp;' +
                        '</span>';

                    // select root element
                    var answerModel = '$root.quizAnswers[\'' + scope.parseDropdown.imsccId + '\'][\'' + key + '\']';
                    select += '<select ng-style="{\'width\': getMaxLength()}" name="selc" ng-model="' + answerModel + '">';
                    select += '<option selected="selected" value="">[Выбрать]</option>';

                    // options
                    angular.forEach(value.items, function (value, key) {
                        select += '<option value="' + value.imsccId + '">' + value.text + '</option>';
                    });

                    select += '</select>';

                    // feedback
                    select += '<span ng-show="$root.isFinished" ' +
                        'ng-class="$root.responseString[\'' + scope.parseDropdown.imsccId + '\'][\'' + key + '\'].correct ? \'feedback correct\' : \'feedback incorrect\'" ' +
                        'ng-bind-html="$root.responseString[\'' + scope.parseDropdown.imsccId + '\'][\'' + key + '\'].text"></span>';

                    scope.questionText = scope.questionText.replace('[' + value.text + ']', select);
                });
                element.html($compile(scope.questionText)(scope));
            }
        };
    });
