'use strict';

angular
  .module('lmsApp')
  .controller(
    'LearningclassDetailController',
    function($scope, $stateParams, Learningclass, School) {
      $scope.learningclass = {};
      $scope.load = function(id) {
        Learningclass.get({ id: id }, function(result) {
          $scope.learningclass = result;
        });
      };
      $scope.load($stateParams.id);
    }
  );
