'use strict';

angular.module('lmsApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('statistic', {
                parent: 'admin',
                url: '/statistic',
                data: {
                    roles: ['ROLE_ADMIN', 'ROLE_LOCAL_ADMIN', 'ROLE_SCHOOL_ADMIN'],
                    pageTitle: 'Статистика'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/statistic/statistic.html',
                        controller: 'StatisticController'
                    }
                }
            });
    });
