'use strict';

angular.module('lmsApp')
    .service('RubricManager', ['dialogs', function (dialogs) {

        // Variables
        var _editRubricTemplate = '/app/components/entities/course/rubricManagerModal.html',
            editor = {};

        // Methods
        this.openRubric = openRubric;

        function openRubric(courseId, rubric, topics) {
            if (!courseId) {
                return false;
            }

            var dlg = dialogs.create(_editRubricTemplate, 'RubricManagerCtrl', {
                'courseId': courseId,
                'data': rubric ? rubric : null,
                'editor': editor,
                'topics': topics
            }, {
                keyboard: true
            });
            dlg.result.then(function (data) {
                killEditorInstance();
            }, function (data) {
                killEditorInstance();
            });
        }

        function killEditorInstance() {
            editor.instance = null;
            if (tinyMCE.get('rubricText')) {
                tinyMCE.get('rubricText').remove();
            }
        }
    }])
    .controller('RubricManagerCtrl', ['$scope', '$uibModalInstance', 'toastr', 'data', 'FilesService', 'StaticPageHelper', 'uuid4', 'Rubric', '$q', '$http', '$rootScope',
        function ($scope, $uibModalInstance, toastr, initObj, FilesService, StaticPageHelper, uuid4, Rubric, $q, $http, $rootScope) {
            // Variables
            var _courseId, cls, _editor;

            $scope.rubric = {};
            $scope.topics = [];
            $scope.selectedTopic = null;
            $scope.edit = false;
            $scope.configPath = {
                "domainUrl": window.location.origin,
                "courses": "upload/courses/",
                "courseFiles": "files/",
                "rubricPath": "web_resources/",
            };
            $scope.rubricLink = '';

            // Methods
            $scope.cancel = cancel;
            $scope.save = save;
            $scope.copyToClipboard = StaticPageHelper.copyToClipboard;

            init();

            function init() {

                if (!initObj.courseId) {
                    $uibModalInstance.close();
                    return false;
                }

                _courseId = initObj.courseId;
                _editor = initObj.editor;

                if (initObj.topics && initObj.topics.length > 0) {
                    $scope.topics = initObj.topics;
                }

                if (initObj.data) {
                    var filePath;

                    $scope.rubric = initObj.data;
                    filePath = getFullFilePath($scope.rubric.path);

                    $scope.topics.some(function (topic) {
                        if (topic.id === $scope.rubric.topic.id) {
                            $scope.selectedTopic = topic;
                            return true;
                        }
                    });

                    getRubricFileByUrl(filePath).then(function (response) {
                        var rubricText;

                        $scope.rubric.text = response; // file body
                        rubricText = $scope.rubric.text.replace(/^([\s\S]*)<body[^>]*>([\s\S]*)<\/body>([\s\S]*)$/gi, '$2');
                        $scope.rubric.fileName = $scope.rubric.path.replace(/^.*[\\\/]/, ''); // filename
                        $scope.edit = true;
                        if (!_editor.instance) {
                            StaticPageHelper.initEditor(_editor, 'rubricText', rubricText || '', _courseId);
                        } else {
                            _editor.instance.setContent(rubricText || '');
                        }
                    });
                } else {
                    $scope.edit = false;
                    $scope.rubric.requiredByDefault = false;

                    if (!_editor.instance) {
                        StaticPageHelper.initEditor(_editor, 'rubricText', '', _courseId);
                    } else {
                        _editor.instance.setContent('');
                    }
                }
            }

            function getFullFilePath(path) {
                // console.log('AAAAAAAAAAAAAAAAAAAAAAAA')
                if (_courseId) {
                    return '/' + $scope.configPath.courses + _courseId + '/' + $scope.configPath.courseFiles + path;
                }
            }

            function getRubricFileByUrl(path) {
                var dfd = $q.defer();
                $http.get($scope.configPath.domainUrl + path + '?cacheBuster=' + uuid4.generate()).then(function (response) {
                    dfd.resolve(String(response.data));
                });
                return dfd.promise;
            }

            function cancel() {
                $uibModalInstance.dismiss('Canceled');
            }

            function save() {
                if ($scope.rubric.name && $scope.rubric.name.length > 0 && !(/^\s+$/.test($scope.rubric.name)) && $scope.rubric.fileName && $scope.rubric.fileName.length > 0 && !(/^\s+$/.test($scope.rubric.fileName))) {
                    if (_editor.instance) {
                        $scope.rubric.text = _editor.instance.getContent();
                    }
                    if (!$scope.rubric.path) {
                        $scope.rubric.path = $scope.configPath.rubricPath + $scope.selectedTopic.folder + '/' + $scope.rubric.fileName;
                    }
                    try {
                        $scope.rubric.text = StaticPageHelper.makeValidHtml($scope.rubric.text, $scope.rubric.name);
                        $scope.rubric.fileName = StaticPageHelper.makeValidFilename($scope.rubric.fileName);
                        FilesService.editText({
                            'id': _courseId,
                            'path': $scope.rubric.path
                        }, $scope.rubric.text, function (response) {
                            if ($scope.rubric.id) {
                                Rubric.update($scope.rubric, sendNotifications);
                            } else {
                                Rubric.create($scope.rubric, sendNotifications);
                            }
                        });
                    } catch (e) {
                        alert('Неверный формат документа.');
                        return false;
                    }
                }
            }

            function sendNotifications() {
                $uibModalInstance.close('Fine');
                $rootScope.$broadcast('rubricFileSaved');
                toastr.success('Файл рубрики сохранён');
            }

            var linkPrefix = '<a href="#" onclick="LmsProxy.getInstance().showResource(event, \'../../',
                rubricName, rubricFilename, defferedTopicFolder;

            $scope.$watch('rubric', function (v) {
                rubricFilename = v.fileName;
                rubricName = v.name;

                if (v.path) {
                    var reg = /(?:web_resources\/)(\S+)(?:\/)(\S+)/g;
                    var rubricLink = reg.exec(v.path);
                    var rubricFolder = defferedTopicFolder && defferedTopicFolder !== void 0 ? defferedTopicFolder : rubricLink[1] ;

                    $scope.rubricLink = linkPrefix + 'web_resources/' + rubricFolder + rubricLink[2] + '\')">' + (v.name ? v.name : '...') + '</a>';
                } else if (v.fileName) {
                    var rubricFolder = defferedTopicFolder && defferedTopicFolder !== void 0 ? defferedTopicFolder : '' ;
                    $scope.rubricLink = linkPrefix + ($scope.configPath.rubricPath + rubricFolder + StaticPageHelper.makeValidFilename(rubricFilename)) + '\')">' + (rubricName ? rubricName : '...') + '</a>';
                }
                if (v.name && !$scope.edit) {
                    $scope.rubric.fileName = StaticPageHelper.makeValidFilename(v.name);
                }
            }, true);

            $scope.$watch('selectedTopic', function(selT){
                if (selT === void 0) {
                    if ($scope.rubricLink.length > 0) {
                        $scope.rubricLink = linkPrefix + ($scope.configPath.rubricPath + StaticPageHelper.makeValidFilename(rubricFilename)) + '\')">' + (rubricName ? rubricName : '...') + '</a>';
                    }
                    defferedTopicFolder = null;
                }

                if (selT !== null && $scope.rubric.fileName) {
                    defferedTopicFolder = selT.folder + '/';
                    $scope.rubricLink = linkPrefix + ($scope.configPath.rubricPath + defferedTopicFolder + StaticPageHelper.makeValidFilename(rubricFilename)) + '\')">' + (rubricName ? rubricName : '...') + '</a>';

                } else if (selT !== null) {
                    defferedTopicFolder = selT.folder + '/';
                }
            });

            // workaround for disabled tinyMCE input fields
            $(document).on('focusin', function (e) {
                if ($(e.target).closest('.mce-window').length) {
                    e.stopImmediatePropagation();
                }
            });
    }]);
