'use strict';

angular.module('lmsApp').config(function ($stateProvider) {
    $stateProvider.state('userImport', {
        parent: 'entity',
        url: '/userImport',
        data: {
            roles: ['OP_MANAGE_USERS'],
            pageTitle: 'lmsApp.userImport.home.title'
        },
        views: {
            'content@': {
                templateUrl: 'app/admin/userImport/userImport.html',
                controller: 'UserImportController'
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('userImport');
                return $translate.refresh();
            }]
        }
    });
    $stateProvider.state('userImport2', {
        parent: 'entity',
        url: '/userImport2',
        data: {
            roles: ['OP_MANAGE_USERS'],
            pageTitle: 'lmsApp.userImport.home.title'
        },
        views: {
            'content@': {
                templateUrl: 'app/admin/userImport/userImport2.html',
                controller: 'UserImport2Controller'
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('userImport');
                return $translate.refresh();
            }]
        }
    });
    $stateProvider.state('userImport3', {
        parent: 'entity',
        url: '/userImport3',
        data: {
            roles: ['OP_MANAGE_USERS'],
            pageTitle: 'lmsApp.userImport.home.title'
        },
        views: {
            'content@': {
                templateUrl: 'app/admin/userImport/userImport3.html',
                controller: 'UserImport3Controller'
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('userImport');
                return $translate.refresh();
            }]
        }
    });
});
