'use strict';

angular.module('lmsApp')
    .controller('videoConfDetailController', function ($scope, $state, $stateParams,
                                                       videoConf) {
        $scope.load = function (id) {
            videoConf.get({id: id}, function (result) {
                $scope.usersInMeeting = result;
            });
        };
        $scope.load($stateParams.id);
    }
);
