'use strict';

angular.module('lmsApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('courseSection', {
                parent: 'entity',
                url: '/courseSection',
                data: {
                    roles: ['OP_MANAGE_COURSE_SECTIONS', 'OP_VIEW_COURSE_SECTIONS'],
                    pageTitle: 'lmsApp.courseSection.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/courseSection/courseSections.html',
                        controller: 'CourseSectionController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('courseSection');
                        $translatePartialLoader.addPart('userGroup');
                        return $translate.refresh();
                    }]
                }
            })
            .state('courseSectionDetail', {
                parent: 'entity',
                url: '/courseSection/:id',
                data: {
                    roles: ['OP_MANAGE_COURSE_SECTIONS', 'OP_VIEW_COURSE_SECTIONS'],
                    pageTitle: 'lmsApp.courseSection.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/courseSection/courseSection-detail.html',
                        controller: 'CourseSectionDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('courseSection');
                        return $translate.refresh();
                    }]
                }
            })
            .state('courseSectionSubscription', {
                parent: 'entity',
                url: '/courseSection/user/:id',
                data: {
                    roles: ['OP_VIEW_COURSE_SECTION_SUBSCRIPTIONS'],
                    pageTitle: 'lmsApp.courseSection.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/courseSection/courseSection-subscription.html',
                        controller: 'CourseSectionSubscriptionController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('courseSection');
                        return $translate.refresh();
                    }]
                }
            })
            .state('courseSectionOpenTaskTable', {
                parent: 'entity',
                url: '/courseSection/:id/openTaskTable',
                data: {
                    roles: ['OP_MANAGE_COURSE_SECTIONS', 'OP_VIEW_COURSE_SECTIONS'],
                    pageTitle: 'lmsApp.courseSection.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/courseSection/courseSection-openTaskTable.html',
                        controller: 'CourseSectionOpenTaskTableController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('courseSection');
                        return $translate.refresh();
                    }]
                }
            })
            .state('courseSectionStudentResults', {
                parent: 'entity',
                url: '/courseSection/:id/students/:studentId',
                data: {
                    roles: ['OP_MANAGE_COURSE_SECTIONS', 'OP_VIEW_COURSE_SECTIONS'],
                    pageTitle: 'lmsApp.studentProgress.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/courseSection/courseSection-studentResults.html',
                        controller: 'CourseSectionStudentResultsController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('studentProgress');
                        $translatePartialLoader.addPart('course');
                        return $translate.refresh();
                    }]
                }
            })
            .state('quizAttempt', {
                parent: 'entity',
                url: '/courseSection/:id/students/:studentId/quizProgresses/:quizProgressId/attempts/:attempt',
                data: {
                    roles: ['OP_MANAGE_COURSE_SECTIONS', 'OP_VIEW_COURSE_SECTIONS'],
                    pageTitle: 'lmsApp.studentProgress.quizzes.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/quiz/quizAttempt.html',
                        controller: 'QuizAttemptController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('studentProgress');
                        $translatePartialLoader.addPart('course');
                        return $translate.refresh();
                    }]
                }
            });
    });
