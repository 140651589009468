'use strict';

angular.module('lmsApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('courseOpenTasks', {
                parent: 'entity',
                url: '/course/:id/openTasks',
                data: {
                    roles: ['OP_MANAGE_COURSES', 'OP_VIEW_COURSES'],
                    pageTitle: 'lmsApp.course.openTasks.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/course/openTask/openTasks.html',
                        controller: 'CourseOpenTasksController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('course');
                        return $translate.refresh();
                    }]
                }
            })
            .state('courseOpenTask', {
                parent: 'entity',
                url: '/course/:id/openTasks/:imsccId',
                data: {
                    roles: ['OP_MANAGE_COURSES', 'OP_VIEW_COURSES'],
                    pageTitle: 'lmsApp.course.openTasks.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/course/openTask/openTask.html',
                        controller: 'CourseOpenTaskController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('course');
                        return $translate.refresh();
                    }]
                }
            })
            .state('courseOpenTaskStudentList', {
                parent: 'entity',
                url: '/course/:id/openTasks/:imsccId/studentList',
                data: {
                    roles: ['OP_MANAGE_COURSES', 'OP_VIEW_COURSES'], // todo: adjust roles
                    pageTitle: 'lmsApp.course.openTasks.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/course/openTask/openTaskStudentList.html',
                        controller: 'CourseOpenTaskStudentListController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('course');
                        return $translate.refresh();
                    }]
                }
            })
            .state('courseOpenTaskTeacherView', {
                parent: 'entity',
                url: '/course/:id/openTasks/:imsccId/student/:studentId',
                data: {
                    roles: ['OP_MANAGE_COURSES', 'OP_VIEW_COURSES'], // todo: adjust roles
                    pageTitle: 'lmsApp.course.openTasks.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/course/openTask/openTask.html',
                        controller: 'CourseOpenTaskController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('course');
                        return $translate.refresh();
                    }]
                }
            });
    });
