'use strict';

// Предложить автоматически скачать текстовый файл с паролями после выполнения импорта

angular.module('lmsApp').controller('ParentImportController', [
    '$scope',
    'Locations',
    'School',
    'ImportUsers',
    '$timeout',
    'usSpinnerService',
    function($scope, Locations, School, ImportUsers, $timeout, usSpinnerService) {

        var TAB = '\t';
        var ENTER = '\n';

        $scope.spinnerUserImport2 = false;


        $scope.selectedRegion = null;
        $scope.selectedTerritory = null;
        $scope.selectedSchool = null;
        $scope.importData = null;
        $scope.dataSent = false;
        $scope.linesWithErrors = [];
        $scope.validatedData = {validRows: [], invalidRows: []};
        $scope.importSuccessData = [];

        $scope.linesWithErrorsString = function() {
            var result_str = "";

            for (var i = 0; i < $scope.linesWithErrors.length; i++) {

                result_str += $scope.linesWithErrors[i] + ", ";
            }

            return result_str;
        }


        $scope.data = {
          regions: [],
          territories: [],
          schools: []
        }

        function spinnerShow() {
            $scope.spinnerUserImport2 = true;
            $timeout(function () {
                usSpinnerService.spin('spinnerUserImport2');
            }, 0);
        }

        function spinnerHide() {
            $scope.spinnerUserImport2 = false;
            usSpinnerService.stop('spinnerUserImport2');
        }

        init();


        function init() {
            Locations.query({
                type: 'REGION'
            }, function (regions) {
                // Сортируем
                var regionsSorted = regions.sort(function (itemA, itemB) {
                    if (itemA.name < itemB.name) {
                        return -1;
                    }
                    return 1;
                });
                // ---
                $scope.data.regions = regionsSorted;
            });
        };

        $scope.onRegionChange = function() {
            if (!!$scope.selectedRegion) {
                Locations.children({ id: $scope.selectedRegion.id }, function (result) {
                    // Сортируем
                    var territoriesSorted = result.sort(function (itemA, itemB) {
                        if (itemA.name < itemB.name) {
                            return -1;
                        }
                        return 1;
                    });
                    // ---
                    $scope.data.territories = territoriesSorted;
                });
            }
            $scope.selectedTerritory = null;
            $scope.selectedSchool = null;
        }

        $scope.onTerritoryChange = function() {
            if (!!$scope.selectedTerritory) {
                var schools = School.getByTerritory({
                    id: $scope.selectedTerritory.id
                });
                // Сортируем
                var schoolsSorted = schools.sort(function (itemA, itemB) {
                    if (itemA.name < itemB.name) {
                        return -1;
                    }
                    return 1;
                });
                // ---
                $scope.data.schools = schoolsSorted
            }
            $scope.selectedSchool = null;
        }

        $scope.reset = function() {
            $scope.selectedRegion = null;
            $scope.selectedTerritory = null;
            $scope.selectedSchool = null;
            $scope.importData = null;
            $scope.dataSent = null;
            $scope.linesWithErrors = [];
            $scope.validatedData = {validRows: [], invalidRows: []};
            $scope.importSuccessData = [];
        }

        $scope.checkImportData = function() {
            $scope.dataSent = true;
            $scope.validatedData = {validRows: [], invalidRows: []};
            $scope.importSuccess = [];

            var parsed_data = parseInput($scope.importData);

            if (parsed_data.invalid_lines.length) {
                $scope.linesWithErrors = parsed_data.invalid_lines;
            }

            else {
                $scope.linesWithErrors = [];
                spinnerShow();
                ImportUsers.checkParentsData(parsed_data.correct_lines,
                function(response) {
                    spinnerHide();
                    $scope.validatedData = response;
                    // Получить валидированные данные приведенные к норм формату и дальше отправить запрос на импорт
                },
                function(error){
                    spinnerHide();
                    switch (error.status) {
                        case 400:
                            alert("Неверное количество столбцов. Должно быть 4 или 5")
                            break
                        default:
                            alert(error.status + ": " + error.statusText)
                    }
                    // Обработать разные ошибки с разными сообщениями
                }
                );
            }

        }

        $scope.importParents = function() {

            spinnerShow();
            $scope.linesWithErrors = [];
            ImportUsers.importParents($scope.validatedData.validRows,
            function(response) {
                spinnerHide();
                $scope.importSuccessData = response;
//                $scope.validatedData = response;
                // Получить валидированные данные приведенные к норм формату и дальше отправить запрос на импорт
            },
            function(error){
                    spinnerHide();
                    alert(error.status + ": " + error.statusText)
                }
            );
//            }
        }

        $scope.createFile = function() {
            var csv = "Фамилия,Имя,Отчество,Логин,Пароль\n";

            angular.forEach($scope.importSuccessData, function(parent) {
                csv += parent.last_name + ",";
                csv += parent.first_name + ",";
                csv += parent.middle_name + ",";
                csv += parent.login + ",";
                csv += parent.password + "\n";
                }
            )

            var data = new Blob([csv]);
            var importDataFile = document.getElementById("importDataFile");
            importDataFile.href = URL.createObjectURL(data);
        }

        function parseInput(string) {
            var correct_lines = [];
            var invalid_lines = [];
            var rows = string.split('\n');
            var row;

            for (var i = 0; i < rows.length; i++) {
                row = rows[i].trim().split('\t')
                if (row.length != 4 && row.length != 5) {
                   invalid_lines.push(i + 1);
                }
                else {
                    row = [$scope.selectedSchool.id].concat(rows[i].trim().split('\t'))
                    correct_lines.push(row);
                }
            }
            return {correct_lines: correct_lines, invalid_lines: invalid_lines};
        }


    }
])
