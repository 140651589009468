'use strict';

angular.module('lmsApp')
    .controller('BookshelfController', function ($scope, Bookshelf) {
        $scope.bookshelfs = [];
        $scope.loadAll = function () {
            Bookshelf.query(function (result) {
                $scope.bookshelfs = result;
            });
        };
        $scope.loadAll();
        $scope.search = function (row) {
            return (angular.lowercase(row.name).indexOf($scope.query || '') !== -1 || angular.lowercase(row.parallel).toString().indexOf($scope.query || '') !== -1);
        };
    });
