'use strict';

angular.module('lmsApp')
    .controller('StudentParentsRedactController', function ($scope, $stateParams, Users, User) {
        $scope.user = {};
        $scope.parentsList = {};
        $scope.excludedIds = [];
        $scope.roleParent = 'ROLE_PARENT';
        $scope.parentsError;
        $scope.parentOptions = {
            'page': 1,
            'per_page': 10,
            'sorting_fields': ['id'],
            'sorting_direction': 'asc'
        };
	    $scope.flag = false;

        $scope.load = function (id) {
            Users.getStudenById({'id': id}, function (user) {
                $scope.user = user;
                $scope.getParents();
            });
        };

        // function getUserById(id) {
        //     id = parseInt(id, 10);
        //     for (var i in $scope.parentsList) {
        //         if ($scope.parentsList[i].id && $scope.parentsList[i].id === id) {
        //             return $scope.parentsList[i];
        //         }
        //     }
        //     return null;
        // }

        function removeAddedParentsFromList(arrUserInTable, arrUser) {
            if (arrUserInTable && arrUserInTable.length === 0) {
                return;
            }

            for (var z in arrUserInTable) {
                remove(arrUser, arrUserInTable[z]);
            }
        }

        function remove(arr, item) {
            for (var i = arr.length; i--;) {
                if (arr[i].id === item.id) {
                    arr.splice(i, 1);
                }
            }
        }

        function removeUserById(id) {
            id = parseInt(id, 10);
            for (var n = 0; n < $scope.user.parents.length; n++) {
                if ($scope.user.parents[n].id === id) {
                    $scope.user.parents.splice(n, 1);
                    return;
                }
            }
        }

        $scope.showAddUserDialog = function () {
            $('#addUserModal').modal('show');
        };

        $scope.showDeleteUserDialog = function () {
            $('#deleteUserModal').modal('show');
        };

        $scope.addUser = function () {
		    $scope.flag = true;
            var addable = {
                            "firstId": $scope.user.id,
                            "secondId": $scope.newUser.id
                          }
            Users.addParent(addable,
                function (data) {
                    $('#addUserModal').modal('hide');
			        $scope.flag = false;
                    $scope.newUser = void 0;
                    $scope.load($stateParams.id);
                },
                function(err){
                    switch(err.status){
                        case 409: 
                            $scope.parentsError = "Вы можете добавить только 2-х родителей"
                            break;
                        case 403:
                            $scope.parentsError = "недостаточно прав"
                            break
                        default:
                            $scope.parentsError = "Ошибка"
                    }
                });
        };

        $scope.deleteUser = function () {
            var addable = {
                            "id": $scope.user.id,
                            "parent_id": +$scope.removedUserId
                          }            
            Users.deleteParent(addable,
                function (data) {
                    $('#deleteUserModal').modal('hide');
                    $scope.load($stateParams.id);
                });
        };

        $scope.getParents = function(loadOptions) {
            loadOptions = (loadOptions) ? loadOptions : $scope.parentOptions;
            loadOptions.id = $scope.user.id;
            if($scope.excludedIds && $scope.excludedIds.length > 0) {
                loadOptions.excluded_ids = $scope.excludedIds.join(',');
            }
            Users.getParentsForStudent(loadOptions, function (result, headers) {
                $scope.parentsList = {
                    'items' : result,
                    'total' : headers('X-Total-Count'),
                };
                removeAddedParentsFromList($scope.user.parents, $scope.parentsList.items);
            });
        };


        $scope.load($stateParams.id);
    });
