'use strict';

angular.module('lmsApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('courseDomain', {
                parent: 'entity',
                url: '/courseDomain',
                data: {
                    roles: ['OP_VIEW_COURSE_GROUP', 'OP_MANAGE_COURSE_GROUP'],
                    pageTitle: 'CourseDomain'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/courseDomain/courseDomain.html',
                        controller: 'CourseDomainController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('courseDomain');
                        return $translate.refresh();
                    }]
                }
            })
            .state('courseDomainDetail', {
                parent: 'entity',
                url: '/courseDomain/:id',
                data: {
                    roles: ['OP_VIEW_COURSE_GROUP', 'OP_MANAGE_COURSE_GROUP'],
                    pageTitle: 'CourseDomain'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/courseDomain/courseDomain-detail.html',
                        controller: 'CourseDomainDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('courseDomain');
                        return $translate.refresh();
                    }]
                }
            });
    });
