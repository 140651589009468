'use strict';
angular.module('lmsApp').config(function($stateProvider) {
  $stateProvider.state('coursePreparingForOlympicsQuizzes', {
    parent: 'entity',
    url: '/course/:id/preparingForOlympicsQuizzes',
    data: {
      roles: ['ROLE_ADMIN', 'ROLE_COURSE_DEVELOPER'],
      pageTitle: 'lmsApp.course.quizzes.title'
    },
    views: {
      'content@': {
        templateUrl: 'app/entities/course/preparingForOlympics/quizzes/quizzes.html',
        controller: 'CoursePreparingForOlympicsQuizzesController'
      }
    },
    resolve: {
      translatePartialLoader: [
        '$translate',
        '$translatePartialLoader',
        function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('course');
          return $translate.refresh();
        }
      ]
    }
  });
});
