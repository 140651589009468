'use strict';

angular.module('lmsApp')
    .factory('Test', function ($resource) {
        return $resource('api/tests/:id', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data){
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'create': {method: 'POST'},
            'getAll': {
                method: 'GET',
                url: 'api/tests',
                isArray: true
            },
            'checkUpload': {
                method: 'GET',
                url: 'api/tests/:id/upload/check'
            }
        });
    });
