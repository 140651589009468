/* globals $ */
'use strict';

angular.module('lmsApp')
    .directive('longList', ['$compile', function($compile) {
        return {
            restrict: 'E',
            templateUrl: 'app/components/longList/longList.html',
            scope: {
                listDataModel : '=',
                listDataMethod : '=',
                listModelFields : '=',
                listSelectType : '@',
                ngModel : '=',
                listOnSelect : '=',
                listOnSearch : '=',
                listOnChange : '=',
                listPagPerPage : '=',
                listOnSelPage : '=',
                listSearch : '=',
                emptyOption : '@'
            },
            link: function (scope, element) {

                var listMode;
                var SINGLE_MODE = 'single',
                    MULTIPLE_MODE = 'multiple',
                    DEF_PER_PAGE = 10;

                scope.listData = [];
                scope.fields = [];
                scope.searchText = '';
                scope.llPagination = {
                    perPage : scope.listPagPerPage ? scope.listPagPerPage : DEF_PER_PAGE,
                    page : 1,
                    total : 0
                };
                scope.listHide = false;
                scope.sorting = {
                    fields: ['id'],
                    direction: 'asc'
                };

                function init() {
                    if(!scope.listModelFields || !scope.listDataModel) {
                        return false;
                    }

                    listMode = (scope.listSelectType === SINGLE_MODE || scope.listSelectType === MULTIPLE_MODE) ? scope.listSelectType : SINGLE_MODE;

                    if(listMode === MULTIPLE_MODE && !scope.listOnSelect) {
                        return false;
                    }
                }

                scope.search = function(text) {
                    scope.llPagination.page = 1;
                    scope.searchText = text;
                    scope.loadData();
                    if(event && event.keyCode === 13) {
                        event.preventDefault();
                    }
                };

                scope.chooseItem = function(item) {
                    scope.ngModel = item;
                    if(scope.listOnSelect) {
                        scope.listOnSelect();
                    }
                };

                scope.loadData = function() {
                    if(scope.listDataMethod) {
                        scope.listDataMethod({
                            'page' : scope.llPagination.page,
                            'per_page' : scope.llPagination.perPage,
                            'sorting_fields' : scope.sorting.fields,
                            'sorting_direction' : scope.sorting.direction,
                            'search_text' : scope.searchText
                        });
                    }
                };

                scope.updateListData = function() {
                    if(scope.listDataModel) {
                        scope.listData = (scope.listDataModel.items) ? scope.listDataModel.items : [];
                        scope.llPagination.total = (scope.listDataModel.total) ? scope.listDataModel.total : scope.listData.length;
                        scope.llPagination.page = (scope.listDataModel.page) ? scope.listDataModel.page : scope.llPagination.page;
                    }
                };

                scope.dropModel = function() {
                    scope.ngModel = null;
                };

                scope.sort = function(field) {
                    if(field && field !== '') {
                        if(scope.sorting.fields[0] === field) {
                            scope.sorting.direction = (scope.sorting.direction === 'asc') ? 'desc' : 'asc';
                        } else {
                            scope.sorting.fields[0] = field;
                            scope.sorting.direction = 'asc';
                        }
                    }
                    scope.loadData();
                };

                scope.$watch('listDataModel', function(v) {
                    if(v) {
                        scope.updateListData();
                    }
                }, true);

                scope.$watch('ngModel', function(v) {
                    if(v) {
                        if(scope.listOnChange) {
                            scope.listOnChange();
                        }
                        scope.listHide = true;
                    } else {
                        scope.listHide = false;
                    }
                }, true);

                scope.$on('clearLongList', function() {
                    scope.searchText = '';
                    scope.llPagination.page = 1;
                    var items = document.querySelectorAll('.longlistSearchText');
                    for(var i=0; i<items.length; i++) {
                        items[i].value = '';
                    }
                });

                init();
            }
        };
    }]);
