'use strict';

angular.module('lmsApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('videoRecord', {
                parent: 'entity',
                url: '/videoRecord',
                data: {
                    roles: ['OP_VIEW_CONFERENCES', 'OP_MANAGE_CONFERENCES'],
                    pageTitle: 'lmsApp.videoRecord.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/videoRecord/videoRecords.html',
                        controller: 'videoRecordController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('videoRecord');
                        return $translate.refresh();
                    }]
                }
            });
    });
