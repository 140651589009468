/* globals $ */
'use strict';

angular.module('lmsApp')
  .directive('showValidation', ['$timeout', '$rootScope', '$interval', function ($timeout, $rootScope, $interval) {
    return {
      restrict: 'A',
      require: 'form',
      link: function (scope, element, attr) {
        var toggle = function () {
          element.find('.form-group').each(function () {
            var $formGroup = angular.element(this);
            var $inputs = $formGroup.find('input[ng-model],textarea[ng-model],select[ng-model]');
            if ($inputs.length > 0) {
              $inputs.each(function () {
                var $input = angular.element(this);
                $timeout(function () {
                  var isInvalid = $input.hasClass('ng-invalid');
                  $formGroup.toggleClass('has-error', isInvalid);
                }, 100);
              });
            }
          });
        };

        element.on("change", function () {
          toggle();
        });

        var init = false;
        $interval(function () {
          if (element.is(':visible')) {
            if (!init) {
              toggle();
            }
            init = true;
          } else {
            init = false;
          }
        }, 150);
      }
    };
  }]);
