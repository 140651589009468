'use strict';

angular.module('lmsApp')
    .controller('StudentParentsDetailController', function ($scope, $stateParams, Users) {
        $scope.user = {};

        $scope.load = function (id) {
            Users.getStudenById({'id': id}, function (user) {
                $scope.user = user;
            });
        };

        $scope.load($stateParams.id);

    });
