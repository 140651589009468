'use strict';

angular.module('lmsApp')
    .controller('CourseDomainDetailController', function ($scope, $stateParams, CourseDomain) {
        $scope.courseDomain = {
            id: null,
            domainName: null,
            courseGroups: []
        };
        $scope.load = function (id) {
            CourseDomain.get({id: id}, function (result) {
                $scope.courseDomain = result;
            });
        };
        $scope.load($stateParams.id);
    });
