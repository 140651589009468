'use strict';

angular.module('lmsApp').directive('conversationMessages', function ($stateParams, $window, $location, Conversation, Upload) {
    return {
        restrict: 'E',
        controller: function ($scope) {

            $scope.uploadInProgress = false;
            $scope.messages = [];
            $scope.files = [];
            $scope.filesToUpload = [];

            $scope.loadMessages = function (id) {
                Conversation.getMessages({convId: id}, function (data) {
                    $scope.messages = data;
                });
            };

            $scope.loadMessages($stateParams.id);

            $scope.clearMessageForm = function () {
                $scope.message = {text: null, draft: false};
                $scope.messageForm.$setPristine();
                $scope.messageForm.$setUntouched();
            };

            $scope.sendMessage = function () {
                Conversation.createMessage({convId: $scope.conversation.id}, $scope.message,
                    function (data, getHeaders) {
                        uploadFiles(getMessageId(getHeaders().location));
                        if (!$scope.uploadInProgress) {
                            if ($scope.conversation.isDistribution) {
                                var newId = getHeaders().location.replace('/api/conversations/', '');
                                newId = newId.substring(0, newId.indexOf('/'));
                                $location.path('/conversation/' + newId);
                            }
                            $scope.clearMessageForm();
                            $scope.loadMessages($scope.conversation.id);
                        }
                    }
                );

            };

            $scope.$watch('files', addToUploadList);

            $scope.isFlashInstalled = function () {
                var hasFlash = false;
                try {
                    var fo = new ActiveXObject('ShockwaveFlash.ShockwaveFlash');
                    if (fo) {
                        hasFlash = true;
                    }
                } catch (e) {
                    if (navigator.mimeTypes && navigator.mimeTypes['application/x-shockwave-flash'] !== void 0 && navigator.mimeTypes['application/x-shockwave-flash'].enabledPlugin) {
                        hasFlash = true;
                    }
                }
                return hasFlash;
            };

            /**
             * Whether or not HTML5 File API is supported.
             *
             * @returns {Boolean} true if supported or false otherwise
             */
            $scope.isFileSupported = function () {
                return (typeof File !== 'undefined' && typeof FileReader !== 'undefined');
            };

            $scope.removeFromUploadList = removeFromUploadList;

            $scope.getFileName = function (filePath) {
                var pathParts = filePath.split('/');
                return pathParts[pathParts.length - 1];
            };

            function getMessageId(locationStr) {
                var locationParts = locationStr.split('/');
                return locationParts[locationParts.indexOf('messages') + 1];
            }

            function addToUploadList() {

                if ($scope.files === null || $scope.files.length === 0) {
                    return;
                }

                skipFile:for (var i in $scope.files) {
                    for (var j in $scope.filesToUpload) {
                        if ($scope.files[i].name === $scope.filesToUpload[j].name) {
                            continue skipFile;
                        }
                    }
                    $scope.filesToUpload.push($scope.files[i]);
                }
            }

            function removeFromUploadList(fileName) {
                for (var i = 0; i < $scope.filesToUpload.length; i++) {
                    if ($scope.filesToUpload[i].name === fileName) {
                        $scope.filesToUpload.splice(i, 1);
                        break;
                    }
                }
            }

            function uploadFiles(msgId) {
                if ($scope.filesToUpload.length > 0) {
                    $scope.uploadInProgress = true;
                    for (var i = 0; i < $scope.filesToUpload.length; i++) {
                        doUpload($scope.filesToUpload[i], msgId);
                    }
                }
            }

            function doUpload(file, msgId) {
                Upload.upload({
                    url: 'conversation/message/' + msgId + '/file',
                    file: file
                }).progress(function (evt) {
                    file.uploadProgress = parseInt(100.0 * evt.loaded / evt.total);
                }).success(function (data, status, headers) {
                    file.uploadSuccessful = true;
                    setTimeout(function () {
                        removeAndReload(file.name);
                    }, 2000);
                }).error(function (data, status, headers) {
                    file.uploadError = true;
                    setTimeout(function () {
                        removeAndReload(file.name);
                    }, 5000);
                });
            }

            /**
             * Removes file from upload list and loads messages if the uploading is done.
             *
             * @param fileName the name of file to remove
             */
            function removeAndReload(fileName) {

                removeFromUploadList(fileName);

                if ($scope.filesToUpload.length === 0) {
                    $scope.uploadInProgress = false;
                    $scope.clearMessageForm();
                    $scope.loadMessages($scope.conversation.id);
                }

                $scope.$apply();
            }
        },
        templateUrl: 'app/entities/conversation/conversation-messages.html'
    };
});
