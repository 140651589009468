'use strict';

angular.module('lmsApp')
    .service('Uuid', function () {
        String.prototype.lpad = function(padString, length) {
            var str = this;
            while (str.length < length) {
                str = padString + str;
            }
            return str;
        };
        
        this.getNewKey = function(obj) {
            var newKey = 1;
            for (var key in obj) {
                if (key.indexOf('zzz') === 0 && key.length > 10 && !isNaN(key.substring(3, 7)) && parseInt(key.substring(3, 7)) >= newKey) {
                    newKey = parseInt(key.substring(3, 7)) + 1;
                }
            }
            newKey = newKey.toString().lpad('0', 4);
            return newKey;
        };
    });
