'use strict';

angular.module('lmsApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('conversation', {
                parent: 'entity',
                url: '/conversation',
                data: {
                    roles: [],
                    pageTitle: 'lmsApp.conversation.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/conversation/conversations.html',
                        controller: 'ConversationController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('conversation');
                        return $translate.refresh();
                    }]
                }
            })
            .state('conversationDetail', {
                parent: 'entity',
                url: '/conversation/:id',
                data: {
                    roles: [],
                    pageTitle: 'lmsApp.conversation.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/conversation/conversation-detail.html',
                        controller: 'ConversationDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('conversation');
                        return $translate.refresh();
                    }]
                }
            });
    });
