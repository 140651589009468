'use strict';

angular.module('lmsApp')
    .factory('QuizProgress', function ($resource) {
        return $resource('api/quizProgresses/', {}, {
            getAttempt: {
                method: 'GET',
                url: '/api/quizProgresses/:id/attempts/:attempt'
            },
            getQuiz: {
                method: 'GET',
                url: '/api/quizProgresses/:id/quiz'
            },
            getMaxAttempts: {
                method: 'GET',
                url: '/api/quizProgresses/maxAttempts',
                isString: true
            }
        });
    })
    .factory('Quiz', function ($resource) {
        return $resource('api/quizzes/', {}, {
            update: {
                method: 'PUT'
            },
            getCorrectAnswers: {
                method: 'GET',
                url: 'api/courses/:courseId/quizzes/:quizImsccId/correct'
            },
            deleteById: {
                method: 'DELETE',
                url: '/api/quizzes/:id'
            },
            getQuestionSection: {
                method: 'GET',
                url: 'api/quizzes/:id/questionSection'
            },
            updateQuestion: {
                method: 'PUT',
                url: 'api/quizzes/:id/questions'
            },
            deleteQuestion: {
                method: 'DELETE',
                url: 'api/quizzes/:id/questions/:imsccId'
            },
            deleteQuisesVariation: {
                method: 'DELETE',
                url: 'api/quizzes/te/variant'
            }
        });
    })
    .factory('Question', function ($resource) {
        return $resource('api/quizzes/', {}, {
            updateQuestionUp: {
                method: 'PUT',
                url: 'api/quizzes/question/:id/up'
            },
            updateQuestionDown: {
                method: 'PUT',
                url: 'api/quizzes/question/:id/down'
            }
        });
    });
