'use strict';

angular
    .module('lmsApp')
    .controller(
        'CourseDetailController',
        function($scope, $rootScope, $stateParams, Course, $state, $http) {
            $scope.course = {};
            $scope.syllabus = '/';
            $scope.loading = false;
            $scope.goToFiles = function(id) {
                $state.go('files', { courseId: id });
            };
            $scope.regenerateProgress = function(id) {
                if ($scope.loading) {
                    return;
                }
                $scope.loading = true;
                $http
                    .post(
                        'api/courses/validateCoursesAssignmentProgresses/?ids=' +
                            id,
                        null
                    )
                    .then(
                        function(rez) {
                            console.log($rootScope);
                            $scope.loading = false;
                        },
                        function(err) {
                            $scope.loading = false;
                            console.log(err);
                        }
                    );
            };
            $scope.load = function(id) {
                Course.get({ id: id }, function(result) {
                    $scope.course = result;
                    $rootScope.currentCourse = result;
                });
            };

            $scope.load($stateParams.id);
        }
    );
