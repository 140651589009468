'use strict';

angular.module('lmsApp').config(function ($stateProvider) {
    $stateProvider.state('parentImport', {
        parent: 'admin',
        url: '/parentImport',
        data: {
            roles: ['OP_MANAGE_USERS'],
            pageTitle: 'Импорт родителей'
        },
        views: {
            'content@': {
                templateUrl: 'app/admin/parentImport/parentImport.html',
                controller: 'ParentImportController'
            }
        }
    });
});
