'use strict';

angular.module('lmsApp')
    .controller('VideoConfConfigRecordController', function ($scope, $stateParams, $window, videoConf) {
        $scope.load = function (id) {
            videoConf.getRecords({recordId: id}, function (result) {
                $scope.videoRecords = result;
            });
        };
        $scope.load($stateParams.id);
        $scope.showRecords = function (url) {
            $window.open(url, '_blank');
        };
        $scope.delete = function (id) {
            videoConf.deleteRecord({recordId: id}, function (result) {
                $scope.load($stateParams.id);
            });
        };
    });
