'use strict';

angular.module('lmsApp')
    // disable ability to enter chars in input. Allow to enter only one comma.
    .directive('numbersOnly', function() {
        return {
            require: 'ngModel',
            link: function(scope, element, attrs, modelCtrl) {
                var commaInText = false;

                // event.type должен быть keypress
                function getChar(event) {
                    if (event.which === null) { // IE
                        if (event.keyCode < 32) return null; // спец. символ
                        return String.fromCharCode(event.keyCode);
                    }

                    if (event.which !== 0 && event.charCode !== 0) { // все кроме IE
                        if (event.which < 32) return null; // спец. символ
                        return String.fromCharCode(event.which); // остальные
                    }
                    return null; // спец. символ
                }

                function checkComma() {
                    if (!element[0].value.match(',')) {
                        commaInText = false;
                    }
                    if (commaInText) {
                        return false;
                    }
                    commaInText = true;
                }

                element.bind('keypress', function(keyEvent) {
                    var keyData = getChar(keyEvent);

                    if (keyData.match(/[^0-9,]/g)) {
                        return false;
                    } else if (keyData === ',') {
                        return checkComma();
                    }
                });
            }
        };
    });
