'use strict';

angular.module('lmsApp').config(function($stateProvider) {
  $stateProvider.state('notifications', {
    parent: 'admin',
    url: '/notifications',
    data: {
      roles: ['ROLE_ADMIN'],
      pageTitle: 'lmsApp.course.wiki.title'
    },
    views: {
      'content@': {
        templateUrl: 'app/admin/notifications/notifications.html',
        controller: 'NotificationsController'
      }
    },
    resolve: {
      translatePartialLoader: [
        '$translate',
        '$translatePartialLoader',
        function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('notifications');
          return $translate.refresh();
        }
      ]
    }
  });
});
