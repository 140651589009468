'use strict';

angular.module('lmsApp')
    .factory('UserSettings', ['$resource',function ($resource) {
        return $resource('api/userSettings/:id', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                url: 'api/userSettings',
            }
        });
    }]);
