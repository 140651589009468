'use strict';

angular.module('lmsApp')
    .controller('sectionsInGroupController', function ($scope, $rootScope, $stateParams, CourseSection) {
        $scope.sections = [];

        $scope.loadAll = function (id) {
            CourseSection.getAllByUserGroupId({id: id}, function (sections) {
                $scope.sections = sections;
            });
        };
        $rootScope.idgroup = $stateParams.id;
        $scope.loadAll($stateParams.id);
    });
