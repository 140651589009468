'use strict';

angular.module('lmsApp')
    .provider('Auth', function() {
        var appRoles = [];
        var loginUrl;

        return {

            setAppRoles: function(roles) {
                if(angular.isArray(roles)) {
                    appRoles = roles;
                }
            },

            setLoginUrl: function(url) {
                loginUrl = url;
            },

            $get: function($rootScope, $state, $q, $translate, Principal, AuthServerProvider, Account, Register, Activate, Password, $window) {
                function login(credentials, callback) {
                    var cb = callback || angular.noop;
                    var deferred = $q.defer();

                    /*jshint validthis:true */
                    AuthServerProvider.login(credentials).then(function (data) {
                        // retrieve the logged account information
                        Principal.identity(true).then(function(account) {
                            // After the login the language will be changed to
                            // the language selected by the user during his registration
                            $translate.use(account.langKey);
                            deferred.resolve(data);
                        });
                        return cb();
                    }).catch(function (err) {
                        this.logout();
                        deferred.reject(err);
                        return cb(err);
                    }.bind(this));

                    return deferred.promise;
                }

                function logout() {
                    AuthServerProvider.logout();
                    Principal.authenticate(null);
                }

                function checkAppRoles() {
                    return (appRoles && appRoles.length > 0 && Principal.isInAnyRole(appRoles));
                }

                function getLoginUrl() {
                    return loginUrl;
                }

                function goLoginPage() {
                    $window.location.href = getLoginUrl();
                }

                function authorize(force) {
                    return Principal.identity(force)
                        .then(function() {
                            var isAuthenticated = Principal.isAuthenticated();

                            if(!checkAppRoles() && $rootScope.toState.name !== 'appaccessdenied') {
                                if (isAuthenticated) {
                                    $state.go('appaccessdenied');
                                } else {
                                    $state.go('login');
                                }
                            }

                            if ($rootScope.toState.data.roles && $rootScope.toState.data.roles.length > 0 && !Principal.isInAnyRole($rootScope.toState.data.roles)) {
                                if (isAuthenticated) {
                                    // user is signed in but not authorized for desired state
                                    $state.go('accessdenied');
                                }
                                else {
                                    // user is not authenticated. stow the state they wanted before you
                                    // send them to the signin state, so you can return them when you're done
                                    $rootScope.returnToState = $rootScope.toState;
                                    $rootScope.returnToStateParams = $rootScope.toStateParams;
                                    // now, send them to the signin state so they can log in
                                    $state.go('login');
                                }
                            }
                        });
                }
                function createAccount(account, callback) {
                    var cb = callback || angular.noop;

                    /*jshint validthis:true */
                    return Register.save(account,
                        function () {
                            return cb(account);
                        },
                        function (err) {
                            this.logout();
                            return cb(err);
                        }.bind(this)).$promise;
                }

                function updateAccount(account, callback) {
                    var cb = callback || angular.noop;

                    /*jshint validthis:true */
                    return Account.save(account,
                        function () {
                            return cb(account);
                        },
                        function (err) {
                            return cb(err);
                        }.bind(this)).$promise;
                }

                function activateAccount(key, callback) {
                    var cb = callback || angular.noop;

                    /*jshint validthis:true */
                    return Activate.get(key,
                        function (response) {
                            return cb(response);
                        },
                        function (err) {
                            return cb(err);
                        }.bind(this)).$promise;
                }

                function changePassword(newPassword, callback) {
                    var cb = callback || angular.noop;

                    return Password.save(newPassword, function () {
                        return cb();
                    }, function (err) {
                        return cb(err);
                    }).$promise;
                }

                return {
                    login: login,
                    logout: logout,
                    authorize: authorize,
                    createAccount: createAccount,
                    updateAccount: updateAccount,
                    activateAccount: activateAccount,
                    changePassword: changePassword,
                    checkAppRoles: checkAppRoles,
                    getLoginUrl: getLoginUrl,
                    goLoginPage: goLoginPage
                };
            }
        };
    });
