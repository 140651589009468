'use strict';

angular.module('lmsApp')
    .controller('StudentParentsOpenTaskTableController', function ($scope, $stateParams, CourseSection, AssignmentProgress, $q, $translate) {

        $scope.courseId = null;
        $scope.courseSection = null;
        $scope.students = [];
        $scope.assignments = [];
        $scope.studentIds = [];
        $scope.sectionId = '';

        $scope.gridOptions = {
            headerRowHeight: 130,
            data: 'dataForGrid',
            columnDefs: 'columnDefs'
        };

        $scope.options = [{
            value: 0,
            label: $translate.instant('lmsApp.courseSection.matrix.openTaskTable.selectLabelForNone')
        }, {
            value: 1,
            label: $translate.instant('lmsApp.courseSection.matrix.openTaskTable.selectLabelForRequire')
        }, {
            value: 2,
            label: $translate.instant('lmsApp.courseSection.matrix.openTaskTable.selectLabelForHomeWork')
        }];

        $scope.load = function (studentId, id) {
            $scope.sectionId = id;
            $scope.studentId = studentId;
            CourseSection.get({id: id}, function (result) {
                init(studentId, result);
                var reqObj = {
                    courseId: $scope.courseId,
                    studentIds: $scope.studentIds.join(',')
                };
                $q.all([
                        getAssignmentsFromLesson(reqObj),
                        getAssignmentsFromTopic(reqObj)]
                ).then(function (results) {
                        initGrid(results[0].concat(results[1]));
                    });
            });
        };

        $scope.handleAssignmentProgress = function (entity) {
            AssignmentProgress
                .updateRequireAndHomeAssignmentById(calculateOutputObject(entity));
        };

        function getAssignmentsFromLesson(reqObj) {
            var deferred = $q.defer();
            AssignmentProgress
                .getAllByCourseIdAndStudentIdsFromLesson(reqObj, function (res) {
                    deferred.resolve(res);
                });
            return deferred.promise;
        }

        function getAssignmentsFromTopic(reqObj) {
            var deferred = $q.defer();
            AssignmentProgress
                .getAllByCourseIdAndStudentIdsFromTopic(reqObj, function (res) {
                    deferred.resolve(res);
                });
            return deferred.promise;
        }

        function initGrid(result) {
            sortByField(result, 'studentId');
            initAssignmentsArr(result);
            prepareDataForGrid(result);
            prepareColumnDefsForGrid();
        }

        function initAssignmentsArr(result) {
            var assignments = [];
            var BreakException = {};
            try {
                /*jshint validthis:true */
                result.forEach(function (item, index, arr) {
                    if (index !== 0 && item.studentId !== arr[index - 1].studentId) {
                        throw BreakException;
                    }
                    assignments.push(item.assignment);
                }, this);
            } catch (e) {
                if (e !== BreakException) {
                    throw e;
                }
            }
            sortByField(assignments, 'id');
            $scope.assignments = assignments;
        }

        function getFieldFromArrById(nameField, arr, id) {
            id = parseInt(id, 10);

            for (var i in arr) {
                if (arr[i].id && arr[i].id === id) {
                    return arr[i][nameField];
                }
            }
            return null;
        }

        function prepareColumnDefsForGrid() {
            var countFieldsWithAssignmentProgress = $scope.assignments.length;
            var columnDefs = [
                {
                    field: 'name',
                    displayName: $translate.instant('lmsApp.courseSection.userLogin'),
                    headerClass: 'openTaskTableHeader',
                    minWidth: 150
                }
            ];
            for (var i = 0; i < countFieldsWithAssignmentProgress; i++) {
                columnDefs.push({
                    field: 'assignmentProgress' + i,
                    headerCellTemplate: '<div ng-class="isHover" ' +
                    'ng-mouseleave="isHover = \'headCellHoverLeave\'" ng-mouseenter="isHover = \'headCellHoverEnter\'"' +
                    ' class="openTaskTableHeader ngHeaderText">' + $scope.assignments[i].imsccId + '\n' + $scope.assignments[i].title + '</div>',

                    headerClass: 'openTaskTableHeader',
                    minWidth: 120,
                    cellTemplate: '<select ng-disabled="true"' +
                    'ng-model="row.entity.assignmentProgress' + i + '.value" convert-to-number ng-options="item.value as item.label for item in options">'
                });
            }
            $scope.columnDefs = columnDefs;
        }

        function sortByField(result, field) {
            result.sort(function (a, b) {
                return a[field] - b[field];
            });
        }

        function getAssignmentProgressByStudentIdAndAssignmentId(studentId, assignmentId, result) {
            for (var i in result) {
                if (result[i].studentId === studentId && result[i].assignment.id === assignmentId) {
                    return result[i];
                }
            }
        }

        function prepareDataForGrid(result) {
            var dataForGrid = [];
            var countAssignments = $scope.assignments.length;
            var countRow = result.length / countAssignments;
            for (var n = 0; n < countRow; n++) {
                var studentId = result[n * countAssignments].studentId;
                var row = {
                    name: getFieldFromArrById('login', $scope.students, studentId)
                };
                for (var i = 0; i < countAssignments; i++) {
                    var assignmentId = $scope.assignments[i].id;
                    var ap = getAssignmentProgressByStudentIdAndAssignmentId(
                        studentId, assignmentId, result);
                    row['assignmentProgress' + i] = {
                        id: ap.id,
                        value: calculateInputValueAndDisabled(ap),
                        isDisabled: ap.isDisabled
                    };
                }
                dataForGrid.push(row);
            }
            $scope.dataForGrid = dataForGrid;
        }

        function calculateOutputObject(entity) {
            var output = {
                id: entity.id,
                require: false,
                home: false
            };
            if (entity.value !== 0) {
                output.require = true;
            }
            if (entity.value === 2) {
                output.home = true;
            }
            return output;
        }

        function calculateInputValueAndDisabled(assignmentProgress) {
            assignmentProgress.isDisabled = false;
            if (assignmentProgress.required === false) {
                return 0;
            }
            if (assignmentProgress.existTopicProgress === true || assignmentProgress.assignment.keyQuestion === true) {
                assignmentProgress.isDisabled = true;
                return 1; // and disabled
            }
            if (assignmentProgress.required === true && assignmentProgress.isHomeAssignment === false) {
                return 1;
            }
            if (assignmentProgress.required === true && assignmentProgress.isHomeAssignment === true) {
                return 2;
            }
        }

        function init(studentId, result) {
            $scope.courseSection = result;
            $scope.courseId = result.course.id;
            var cp = result.courseProgress;
            for (var i in cp) {
                if (cp[i].user.id === studentId) {
                    $scope.students.push(cp[i].user);
                    $scope.studentIds.push(cp[i].user.id);
                }
            }
        }

        $scope.load($stateParams.studentId, $stateParams.id);
    });