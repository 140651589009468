'use strict';

angular.module('lmsApp')
    .controller('StudentsForParentController', function ($scope, User, Account) {
        $scope.studentsList = [];

        $scope.loadAll = function () {
            Account.get(function (result) {
                $scope.account = result.data;
                User.getAllParentId({id: $scope.account.id}, function (result) {
                    $scope.studentsList = result;
                });
            });
        };

        $scope.loadAll();

    });
