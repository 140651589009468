'use strict';

angular.module('lmsApp')
	.config(function ($stateProvider) {
		$stateProvider
			.state('orgStructure', {
				parent: 'entity',
				url: '/orgStructure',
				data: {
					roles: ['ROLE_ADMIN', 'ROLE_LOCAL_ADMIN'],
					pageTitle: 'lmsApp.region.home.title'
				},
				views: {
					'content@': {
						templateUrl: 'app/entities/orgStructure/orgStructures.html',
						controller: 'orgStructureController'
					}
				},
				resolve: {
					translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
						$translatePartialLoader.addPart('orgStructure');
						$translatePartialLoader.addPart('region');
						$translatePartialLoader.addPart('territory');
						$translatePartialLoader.addPart('school');
                        $translatePartialLoader.addPart('userGroup');
						$translatePartialLoader.addPart('learningclass');
						return $translate.refresh();
                    }]
				}
			});
	});
