'use strict';

angular.module('lmsApp')
    .factory('NotificationsService', function ($resource) {
        return $resource('api/notifications', {}, {
            'getAll': {
                method: 'GET',
                isArray: true
            },
            'getAllPublished': {
                method: 'GET',
                isArray: true,
                url: "api/notifications/published"
            },
            'get': {
                method: 'GET',
                url: "api/notifications/:id"
            },
            'create': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            },
            'delete': {
                method: 'DELETE',
                url: "api/notifications/:id"
            }
        });
    });
