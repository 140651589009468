'use strict';

angular.module('lmsApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('cruduser', {
                parent: 'entity',
                url: '/cruduser',
                data: {
                    roles: ['OP_MANAGE_USERS'],
                    pageTitle: 'lmsApp.cruduser.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/cruduser/crudusers.html',
                        controller: 'CruduserController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('cruduser');
                        return $translate.refresh();
                    }]
                }
            })
            .state('cruduserDetail', {
                parent: 'entity',
                url: '/cruduser/:id',
                data: {
                    roles: ['OP_MANAGE_USERS'],
                    pageTitle: 'lmsApp.cruduser.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/cruduser/cruduser-detail.html',
                        controller: 'CruduserDetailController'

                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('cruduser');
                        return $translate.refresh();
                    }]
                }
            });
    });
