'use strict';

angular.module('lmsApp')
    .controller('StudentsForParentDetailController', function ($scope, $stateParams, CourseSection) {
        $scope.studentId = '';
        $scope.load = function (id) {
            CourseSection.getUserSubscriptions({id: $stateParams.id}, function (sections) {
                $scope.courseSections = sections;
                $scope.studentId = id;
            });
        };

        $scope.load($stateParams.id);
    });




