'use strict';

angular.module('lmsApp').controller('TestDetailController', function ($scope, $stateParams, Test) {

    $scope.test = {};

    $scope.load = function (id) {
        Test.get({id: id}, function(result) {
            $scope.test = result;
        });
    };

    $scope.load($stateParams.id);

});
