/**
 * Created by oem on 21.12.16.
 */
'use strict';

angular.module('lmsApp')
    .factory('Conversations', function ($resource, API) {
        var conversationApi = API.newAdmin + '/conversations'
        return $resource(conversationApi, {}, {

            create: {
                method: 'POST',
                url: conversationApi
            },
            update: {
                method: 'PUT',
                url: conversationApi
            },
            addParticipant: {
                method: 'POST',
                url: conversationApi + '/participants'
            },
            deleteParticipant: {
                method: 'DELETE',
                url: conversationApi + '/participants'
            },
            deleteAllParticipant: {
                method: 'DELETE',
                url: conversationApi + '/participants/all/:conversationId'
            },
            addGroup: {
                method: 'POST',
                url: conversationApi + '/groups'
            }
        });
    })
