/**
 * Created 22.06.2017
 */
'use strict';
/**
 * Директива, для проверки существования файла wiki с таким же названием на сервере
 */
angular.module('lmsApp')
    .directive('wikiPermissionSave', ['FilesService', '$timeout', function (FilesService, $timeout) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function ($scope, $element, $attrs, ngModelCtrl) {
                var timeout = null;
                var validate = function (value) {
                    if (timeout) {
                        $timeout.cancel(timeout);
                    };
                    timeout = $timeout(function () {
                        if (!value) {
                            ngModelCtrl.$setValidity('permission', false);
                            return;
                        }
                        var courseId = $scope.wiki.courseId;
                        FilesService.exists({ id: courseId, 'path': $scope.configPath.wikiPath + '/' + $scope.wiki.filename + '.html' }).$promise.then(function (response) {
                            if (response.headers['file-exists'] == 'true') {
                                ngModelCtrl.$setValidity('permission', false);
                                ngModelCtrl.$setValidity('exists', false);
                            } else {
                                ngModelCtrl.$setValidity('permission', true);
                                ngModelCtrl.$setValidity('exists', true);
                            }
                        }).catch(function (e) {
                            // 
                        });
                    }, 1000);
                };
                ngModelCtrl.$parsers.unshift(function (viewValue) {
                    validate(viewValue);
                    return viewValue;
                });
                ngModelCtrl.$formatters.unshift(function (modelValue) {
                    validate(modelValue);
                    return modelValue;
                });
            }
        };
    }]);