'use strict';

angular.module('lmsApp')
    .factory('Assignment', function ($resource) {
        return $resource('api/assignments/:id', {}, {
            'updateOpenTask': {
                method: 'PUT',
                url: 'api/assignments/openTask',
                transformResponse: function (data, headers) {
                    var a = headers().error_msg;
                    return {content: data, headers: headers()};
                }
            },
            'createOpenTask': {
                method: 'POST',
                url: 'api/assignments/openTask',
                transformResponse: function (data) {
                    return {content: data};
                }
            },
            'deleteOpenTask': {
                method: 'DELETE',
                url: 'api/assignments/:id/openTask'
            },
            'deleteOpenTaskVariation': {
                method: 'DELETE',
                url: 'api/assignments/te/variant'
            }
        });
    });
