'use strict';

angular.module('lmsApp').factory('Region', function ($resource) {
	return $resource('api/regions/:id', {}, {
		'get': {
			method: 'GET',
			transformResponse: function (data) {
				data = angular.fromJson(data);
				return data;
			}
		},
		'update': {
			method: 'PUT'
		}
	});
});
