'use strict';

angular.module('lmsApp')
    .controller('methodologistCoursesDetailController', function ($scope, $stateParams, Users) {
        $scope.user = {};

        $scope.load = function (id) {
            Users.getMethodologistCourses({'methodologistId': id}, function (user) {
                $scope.user = user;
            })
        };

        $scope.load($stateParams.id)

    });
