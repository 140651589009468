'use strict';
angular.module('lmsApp').controller('CreateCourseSectionsFormCtrl',
    function ($scope, UserGroup, Users, Classes, CourseSection, Principal, Region, Territory, SchoolNew, Locations, Learningclass, Upload, $timeout) {

        $scope.examples = [{
            class: '2А',
            name: 'Русский язык',
            classNumber: '2',
            login: 'a.teacher'
        },{
            class: '4В',
            name: 'Окружающий мир',
            classNumber: '4',
            login: 'b.teacher'
        }, {
            class: '7Б',
            name: 'Английский язык',
            classNumber: '7',
            login: 'c.teacher'
        }];

        $scope.csError = null;
        $scope.listFile = null;
        $scope.locations = {
            regions: [],
            territories: [],
            schools: []
        };
        $scope.selectedLocation = {
            region: null,
            territory: null,
            school: null
        };

        $scope.clear = function () {
            $scope.listFile = null;
            $scope.selectedLocation = {
                region: null,
                territory: null,
                school: null
            };
            setInitCurrentYear();
            $scope.logUrl = null;
            $scope.logs = [];
            $scope.csError = null;
            angular.element("input[type='file']").val(null);
        };

        $scope.onRegionChange = function () {
            if ($scope.selectedLocation.region) {
                $scope.locations.territories = Locations.children({
                    id: $scope.selectedLocation.region.id
                });
            }
            $scope.selectedLocation.territory = null;
            $scope.selectedLocation.school = null;
        };

        $scope.onTerritoryChange = function () {
            if ($scope.selectedLocation.territory) {
                $scope.locations.schools = SchoolNew.getByTerritory({
                    id: $scope.selectedLocation.territory.id
                });
            }
            $scope.selectedLocation.school = null;
        };

        init();

        function init() {
            Locations.query({
                type: 'REGION'
            }, function (regions) {
                $scope.locations.regions = regions;
            });
            Learningclass.getYears(function (years) {
                $scope.years = years;
                setInitCurrentYear();
            });
        }

        function setInitCurrentYear() {
            var curYear = $scope.years.filter(function (obj) {
                return obj.current;
            })[0];
            $scope.selectedCurrentYear = curYear;
            $scope.selectedNextYear = $scope.years.filter(function (obj) {
                return obj.value === curYear.value + 1;
            })[0];
        }

        $scope.uploadList = function (file) {
            file.upload = Upload.upload({
//                    url: '/api/courseSections/transport',
                    url: '/admin/transfer-sections',
                    file: file,
                    data: {
                        currentYear: $scope.selectedCurrentYear.value,
                        nextYear: $scope.selectedNextYear.value,
                        schoolId: $scope.selectedLocation.school.id
                    }
                },
                function (response) {
                    $scope.csError = response.data.errorCS;
                });

            file.upload.progress(function () {
                $scope.csError = null;
                $scope.transferringInProgress = true;
            }).then(function (response) {
                    $scope.logs = [];
                    $scope.transferringInProgress = false;
                    var content = "\tЖурнал созданных и не созданных секций курсов для учеников школы " +
                        $scope.selectedLocation.school.name + " на " + $scope.selectedNextYear.value + " год\n\n";
                    content += parseResponse(response.data);
                    var blob = new Blob([content], { type: 'text/plain' });
                    $scope.logFileName = "log-" + new Date().toJSON() + ".txt";
                    $scope.logUrl = (window.URL || window.webkitURL).createObjectURL(blob);
                },
                function (response) {
                    $scope.csError = response.data.errorCS;
                });


        };

        function parseResponse (data) {
            var result = "****************************************************************************************************************************************************************\n";
            result += "\tСозданные секции курсов:\n";
            result += data.createdCourseSections.join('\n');
            result += "\n****************************************************************************************************************************************************************\n";
            $scope.logs.push({
                name: "Созданные секции курсов:",
                count: data.createdCourseSections.length
            });

            result += "\tНе созданные секции курсов:\n";
            result += data.notCreatedCoursesSections.join('\n');
            result += "\n****************************************************************************************************************************************************************\n";
            $scope.logs.push({
                name: "Не созданные секции курсов:",
                count: data.notCreatedCoursesSections.length
            });

            result += "\tНе созданы секции курсов из-за незаполненной информации в файле загрузки:\n";
            result += data.notCreatedNoInformation.join('\n');
            result += "\n****************************************************************************************************************************************************************\n";
            $scope.logs.push({
                name: "Не созданы секции курсов из-за незаполненной информации в файле загрузки:",
                count: data.notCreatedNoInformation.length
            });

            result += "\tНе существующие секции курсов:\n";
            result += data.coursesSectionExists.join('\n');
            result += "\n****************************************************************************************************************************************************************\n";
            $scope.logs.push({
                name: "Не существующие секции курсов:",
                count: data.coursesSectionExists.length
            });

            return result;
        }

    });
