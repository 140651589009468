'use strict';

angular.module('lmsApp')
    .controller('ImsccPackagesController', function ($scope, $stateParams, Course) {
        $scope.imsccPackages = {};
        $scope.exportLetsGo = false;
        $scope.load = function (id) {
            $scope.courseId = id;

            Course.get({id: id}, function (result) {
                $scope.course = result;
            });

            Course.getImsccPackage({id: id}, function (result) {
                $scope.imsccPackages = result;
            });
        };

        $scope.export = function () {
            $scope.exportLetsGo = true;
            Course.export({id: $scope.courseId}, function () {
                $('#exportModal').modal('hide');
                $scope.load($scope.courseId);
                $scope.exportLetsGo = false;
            });
        };

        $scope.load($stateParams.id);
    });
