'use strict';

angular.module('lmsApp').factory('Classes', function($resource, API) {
  var classApi = '/admin/classes';
  return $resource(
    classApi,
    {},
    {
      getFreeClasses: {
        method: 'GET',
        isArray: true,
        url: classApi + '/free'
      },
      getAll: {
        method: 'GET',
        url: classApi + '/',
        isArray: true
      }
    }
  );
});
