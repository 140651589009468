'use strict';

angular.module('lmsApp')
    .controller('CruduserDetailController', function ($scope, $stateParams, Users, UserSettings, UserGroup, School, Learningclass) {
        $scope.user = {};
        $scope.userSettings = {};
        $scope.roles = [];
        // $scope.hiddenRoles = [];
        $scope.load = function (id) {
            Users.getById({id: id}, function (result) {
                $scope.user = result;
                $scope.roles = result.roles;
                $scope.user.birthDate = new Date(result.birthDate)
                $scope.userSettings = $scope.user.info[0];
            });
        };

        function checkStudentInGroup(group, id) {
            for (var i = 0; i < group.users.length; i++) {
                if (group.users[i].id === parseInt(id)) {
                    return group;
                }
            }
        }

        function checkGroupInClass(learningClass, name) {
            if(learningClass && learningClass.userGroup && learningClass.userGroup.id) {
                if (learningClass.userGroup.name === name) {
                    return learningClass;
                }
            } else {
                return false;
            }
        }

        function checkTeacherInSchool(school, id) {
            for (var i = 0; i < school.teachers.length; i++) {
                if (school.teachers[i].id === parseInt(id)) {
                    return school;
                }
            }
        }

        $scope.load($stateParams.id);
    });
