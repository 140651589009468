'use strict';

angular.module('lmsApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('studentParents', {
                parent: 'entity',
                url: '/studentparents',
                data: {
                    roles: ['OP_MANAGE_PARENTS'],
                    pageTitle: 'lmsApp.studentParents.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/studentParents/studentParents.html',
                        controller: 'StudentParentsController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('studentParents');
                        return $translate.refresh();
                    }]
                }
            })
            .state('studentParentsDetail', {
                parent: 'entity',
                url: '/studentparents/:id',
                data: {
                    roles: ['OP_MANAGE_PARENTS'],
                    pageTitle: 'lmsApp.studentParents.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/studentParents/studentParents-detail.html',
                        controller: 'StudentParentsDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('studentParents');
                        return $translate.refresh();
                    }]
                }
            })
            .state('studentParentsRedact', {
                parent: 'entity',
                url: '/studentparents/redact/:id',
                data: {
                    roles: ['OP_MANAGE_PARENTS'],
                    pageTitle: 'lmsApp.studentParents.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/studentParents/studentParents-redact.html',
                        controller: 'StudentParentsRedactController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('studentParents');
                        return $translate.refresh();
                    }]
                }
            })
            .state('studentsForParent', {
                parent: 'entity',
                url: '/studentsforparent',
                data: {
                    roles: ['OP_VIEW_PARENTS'],
                    pageTitle: 'lmsApp.studentParents.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/studentParents/studentsForParent.html',
                        controller: 'StudentsForParentController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('studentParents');
                        return $translate.refresh();
                    }]
                }
            })
            .state('studentsForParentDetail', {
                parent: 'entity',
                url: '/studentsforparent/:id',
                data: {
                    roles: ['OP_VIEW_PARENTS'],
                    pageTitle: 'lmsApp.studentParents.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/studentParents/studentsForParent-detail.html',
                        controller: 'StudentsForParentDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('studentParents');
                        return $translate.refresh();
                    }]
                }
            })
            .state('studentsCoursesForParent', {
                parent: 'entity',
                url: '/studentsforparent/:id',
                data: {
                    roles: ['OP_VIEW_PARENTS'],
                    pageTitle: 'lmsApp.studentParents.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/studentParents/studentCourseSection-subscription.html',
                        controller: 'StudentCourseSectionSubscriptionController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('studentParents');
                        return $translate.refresh();
                    }]
                }
            })
            .state('studentsForParentSection', {
                parent: 'entity',
                url: '/studentsforparent/:studentId/section/:id',
                data: {
                    roles: ['OP_VIEW_PARENTS'],
                    pageTitle: 'lmsApp.studentParents.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/studentParents/studentsForParent-section-detail.html',
                        controller: 'StudentsForParentSectionDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('courseSection');
                        return $translate.refresh();
                    }]
                }
            })
            .state('studentParentsOpenTaskTable', {
                parent: 'entity',
                url: '/studentsforparent/:studentId/section/:id/openTaskTable',
                data: {
                    roles: ['OP_VIEW_PARENTS'],
                    pageTitle: 'lmsApp.courseSection.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/studentParents/studentParents-openTaskTable.html',
                        controller: 'StudentParentsOpenTaskTableController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('courseSection');
                        return $translate.refresh();
                    }]
                }
            })
            .state('studentResultsForParents', {
                parent: 'entity',
                url: '/studentsforparent/:studentId/section/:id/studentResults',
                data: {
                    roles: ['OP_VIEW_PARENTS'],
                    pageTitle: 'lmsApp.studentProgress.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/studentParents/studentParents-studentResults.html',
                        controller: 'StudentParentsStudentResultsController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('studentProgress');
                        $translatePartialLoader.addPart('course');
                        return $translate.refresh();
                    }]
                }
            })
            .state('studentParentsQuizAttempt', {
                parent: 'entity',
                url: '/studentsforparent/:studentId/section/:id/studentResults/quizProgresses/:quizProgressId/attempts/:attempt',
                data: {
                    roles: ['OP_VIEW_PARENTS'],
                    pageTitle: 'lmsApp.studentProgress.quizzes.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/studentParents/studentParents-quizAttempt.html',
                        controller: 'StudentParentsQuizAttemptController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('studentProgress');
                        $translatePartialLoader.addPart('course');
                        return $translate.refresh();
                    }]
                }
            });
    });
