'use strict';

angular.module('lmsApp')
    .factory('Cruduser', function ($resource) {
        return $resource('api/crudusers/:id', {}, {
            'batchCreate': {
                method: 'POST',
                isArray: true,
                url: 'api/crudusers/batch'
            }
        });
    });
