'use strict';

angular
  .module('lmsApp')
  .controller(
    'CourseWikiController',
    function($scope, $stateParams, Course, uuid4, Uuid, $q, $http, FilesService, WikiManager, $rootScope) {
      var _editor = {};
      $scope.course = {};
      $scope.courseWikiPages = [];
      $scope.wiki = {};
      $scope.wikiImsccIdMap = {};

      $scope.configPath = {
        domainUrl: window.location.origin,
        courses: 'upload/courses/',
        courseFiles: 'files/',
        wikiPath: 'wiki_content/'
      };
      $scope.pagination = {
        page: 1,
        total: 0,
        perPage: 20,
        showAll: true
      };

      $scope.sorting = {
        fields: ['name'],
        direction: 'asc'
      };

      $scope.load = function(id) {
        $scope.courseId = id;
        Course.get({ id: id }, function(result) {
          $scope.course = result;
        });
        var _otOptions = {
          id: id,
          path: $scope.configPath.wikiPath,
          sorting_fields: $scope.sorting.fields,
          sorting_direction: $scope.sorting.direction,
          folder: false
        };

        if (!$scope.pagination.showAll) {
          _otOptions.page = $scope.pagination.page;
          _otOptions.per_page = $scope.pagination.perPage;
        }

        FilesService.get(_otOptions, function(result, headers) {
          $scope.pagination.total = headers('X-Total-Count');
          $scope.courseWikiPages = result.files;
        });
      };

      $scope.createWiki = function() {
        WikiManager.openWiki($scope.courseId);
      };

      $scope.editWiki = function(index) {
        WikiManager.openWiki($scope.courseId, $scope.courseWikiPages[index]);
      };

      $scope.confirmDelete = function(index) {
        $scope.toDelete = $scope.courseWikiPages[index];
        $('#deleteConfirmation').modal({
          backdrop: 'static',
          keyboard: false
        });
      };

      $scope.delete = function() {
        if ($scope.courseId) {
          FilesService.deleteFile(
            { id: $scope.courseId, path: $scope.configPath.wikiPath + $scope.toDelete.name },
            function() {
              $scope.toDelete = null;
              $('#deleteConfirmation').modal('hide');
              $scope.load($stateParams.id);
            }
          );
        }
      };

      $scope.sortWikiList = function(fields) {
        if (fields && fields.length > 0) {
          if (fields[0] === $scope.sorting.fields[0]) {
            $scope.sorting.direction = $scope.sorting.direction === 'asc' ? 'desc' : 'asc';
          } else {
            $scope.sorting.fields = fields;
            $scope.sorting.direction = 'asc';
          }
          $scope.load($stateParams.id);
        }
      };

      $scope.changePerPageCount = function(number) {
        $scope.pagination.perPage = number;
        $scope.pagination.page = 1;
        $scope.pagination.showAll = false;
        $scope.load($stateParams.id);
      };

      $scope.changePerPageToAll = function() {
        $scope.pagination.showAll = true;
        $scope.load($stateParams.id);
      };

      // workaround for disabled tinyMCE input fields
      $(document).on('focusin', function(e) {
        if ($(e.target).closest('.mce-window').length) {
          e.stopImmediatePropagation();
        }
      });

      $scope.load($stateParams.id);

      $rootScope.$on('wikiFileSaved', function() {
        $scope.load($stateParams.id);
      });
    }
  );
