'use strict';

angular.module('lmsApp')
    .factory('UserGroup', function ($resource) {
        return $resource('api/userGroups/:id', {}, {
            'query': {
                method: 'GET',
                isArray: true
            },

            'getOneById': {
                method: 'GET',
                url: 'admin/userGroup/:id'
            },

            'getAllRich': {
                method: 'GET',
                url: 'admin/userGroups/rich',
                isArray: true
            },
            'get': {
                method: 'GET',
                isArray: false,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },

            'create': {
                method: 'POST',
                url: 'admin/userGroups/createGroup'
            },

            'update': {
                method: 'PUT',
                url: 'admin/userGroups/updateGroup'
            },
            'getAllByTutorId': {
                method: 'GET',
                url: 'api/userGroups/findByTutorId/:tutorId',
                isArray: true
            },
            'getFreeUserGroups': {
                method: 'GET',
                url: 'api/userGroups/free/:currentUserGroupId',
                isArray: true
            },
            'getAllGroups': {
                method: 'GET',
                url: 'api/userGroups',
                isArray: true
            },
            'checkLearningClasses': {
                method: 'GET',
                url: 'api/userGroups/checkLearningClasses',
                isArray: true
            }
        });
    });
