angular.module('lmsApp')
    .factory('Groups', function ($resource, API) {
        var groupsApi = API.newAdmin + '/groups'
        return $resource(groupsApi, {}, {
            getAllFreeGroups: {
                method: 'GET',
                isArray: true,
                url: groupsApi + '/message'
            },
        })
    })