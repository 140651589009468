'use strict';

angular
  .module('lmsApp')
  .service('OpenTaskManager', [
    'StaticBackdropModal',
    function(StaticBackdropModal) {
      // Variables
      var _editWikiTemplate = '/app/components/entities/course/openTaskManagerModal.html',
        editor = {};

      // Methods
      this.openTask = openTask;

      function openTask(courseId, wiki) {
        if (!courseId) {
          return false;
        }

        var dlg = StaticBackdropModal.create(_editWikiTemplate, 'OpenTaskManagerCtrl', {
          courseId: courseId,
          data: wiki ? wiki : null,
          editor: editor
        });
        dlg.result.then(
          function(data) {
            killEditorInstance();
          },
          function(data) {
            killEditorInstance();
          }
        );
      }

      function killEditorInstance() {
        editor.instance = null;
        if (tinyMCE.get('openTaskText')) {
          tinyMCE.get('openTaskText').remove();
        }
      }
    }
  ])
  .controller('OpenTaskManagerCtrl', [
    '$scope',
    '$uibModalInstance',
    'toastr',
    'data',
    'FilesService',
    'StaticPageHelper',
    'uuid4',
    'Uuid',
    '$q',
    '$http',
    '$rootScope',
    'Course',
    'Assignment',
    function(
      $scope,
      $uibModalInstance,
      toastr,
      initObj,
      FilesService,
      StaticPageHelper,
      uuid4,
      Uuid,
      $q,
      $http,
      $rootScope,
      Course,
      Assignment
    ) {
      // Variables
      var _courseId, cls, _editor;

      $scope.openTask = {};
      $scope.openTaskSettings = true;
      $scope.openTaskImsccIdMap = {};
      $scope.edit = false;
      $scope.configPath = {
        domainUrl: window.location.origin,
        courses: 'upload/courses/',
        courseFiles: 'files/',
        openTaskPath: 'wiki_content/'
      };
      $scope.openTaskCourseLink = '';
      $scope.error = null;

      // Methods
      $scope.cancel = cancel;
      $scope.save = save;
      $scope.copyToClipboard = StaticPageHelper.copyToClipboard;

      init();

      function init() {
        if (!initObj.courseId) {
          $uibModalInstance.close();
          return false;
        }

        _courseId = initObj.courseId;
        _editor = initObj.editor ? initObj.editor : {};

        getCourse(_courseId);

        var imsccId = Uuid.getNewKey($scope.openTaskImsccIdMap) + uuid4.generate(),
          imsccIdText = ['<a onclick="LmsProxy.getInstance().openTask(event, \'', '\')" href="#">'];
        $scope.openTask.imsccId = imsccId;
        $scope.beginLink = imsccIdText[0] + imsccId + imsccIdText[1];
        $scope.imsccId = $scope.beginLink + '</a>';
        if (_editor.instance) {
          _editor.instance.setContent('');
        }
        StaticPageHelper.initEditor(_editor, 'openTaskText', '', _courseId);
      }

      function getFullFilePath(fileName) {
        if (_courseId) {
          return '/' +
            $scope.configPath.courses +
            _courseId +
            '/' +
            $scope.configPath.courseFiles +
            $scope.configPath.wikiPath +
            fileName;
        }
      }

      function cancel() {
        $uibModalInstance.dismiss('Canceled');
      }

      function save() {
        $scope.openTask.course = { id: _courseId };
        if (_editor.instance) {
          $scope.openTask.text = _editor.instance.getContent();
        }
        if (!$scope.openTask.openTaskForTopic) {
          $scope.openTask.path = null;
        } else {
          if ($scope.openTask.openTaskFileName === null) {
            $scope.openTask.openTaskFileName = 'none';
          }
          $scope.openTask.path = $scope.openTask.openTaskFileName;
        }
        Assignment.updateOpenTask(
          $scope.openTask,
          function(response) {
            $scope.cancel();
            toastr.success('ЗОО сохранено');
          },
          function(response) {
            $scope.error = response.headers('Failure');
          }
        );
      }
      function getCourse(courseId) {
        if (!courseId) {
          return false;
        }
        Course.get({ id: courseId, expand: 'topics' }, function(result) {
          $scope.course = result;
          $scope.topics = $scope.course.topics;
        });
      }
      $scope.$watch('openTask.title', function() {
        if (
          $scope.openTask.title !== void 0 &&
          $scope.openTask.title !== null &&
          $scope.openTask.title !== ''
        ) {
          $scope.imsccId = $scope.beginLink + $scope.openTask.title + '</a>';
        }
      });
    }
  ]);
