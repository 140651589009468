'use strict';

angular.module('lmsApp')
    .directive('lmsLoading', ['$http', function ($http) {
        return {
            restrict: 'A',
            transclude: true,
            replace: true,
            template: '<div>' +
                         '<div ng-hide="loaded">' +
                              '<h4 class="text-center">' +
                                '<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>&nbsp;<span translate="entity.action.loading">Loading</span>...' +
                                '</h4>' +
                         '</div>' +
                         '<div ng-show="loaded" ng-transclude></div>' +
                     '</div>',
            scope: {
                loaded: '=lmsLoading'
            },
            link: function (scope, element, attrs) {
                var unbindWatcher;

                scope.isLoading = function () {
                    return $http.pendingRequests.length > 0;
                };

                unbindWatcher = scope.$watch(scope.isLoading, function (v) {
                    if (v) {
                        scope.loaded = false;
                    } else {
                        scope.loaded = true;
                        unbindWatcher();
                    }
                });
            }
        };
    }]);
