angular.module('lmsApp').controller('NotificationsController', function ($scope, NotificationsService) {

    $scope.loadAll = function () {
        NotificationsService.getAll(function (result) {
            $scope.notifications = result;
        })
    };

    $scope.update = function (notification) {
        if(notification.isPublished === undefined)
            notification.isPublished = false;
        NotificationsService.update(notification, function (result) {
            $scope.refresh();
        })
    };
    $scope.delete = function (notification) {
        console.log(notification.id)
        NotificationsService.delete({
            id: notification.id
        }, function (result) {
            $scope.refresh();
        })
    };
    $scope.initNotification = function (notification) {
        notification = notification || {};
        notification.isPublished = notification.isPublished || false
    }

    $scope.showUpdate = function (notification) {
        NotificationsService.get({
            id: notification.id
        }, function (result) {
            $scope.notification = result;
            $('#saveNotificationModal').modal('show');
        });
    };


    $scope.refresh = function () {
        $scope.loadAll();
        $('#saveNotificationModal').modal('hide');
        $scope.clear();
    };

    $scope.clear = function () {
        $scope.notification = null;
        $scope.editForm.$setPristine();
        $scope.editForm.$setUntouched();
    };

    $scope.loadAll();
});
