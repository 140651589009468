'use strict';

angular.module('lmsApp')
    .controller('CourseSectionDetailController', function ($scope, $stateParams, CourseSection, Course, Users) {

        $scope.courseSection = {};
        $scope.userList = []; // todo: refactor this part if user number increase, possible memory leak
        $scope.newCourseProgressId = '';
        $scope.removedCourseProgressId = '';
        $scope.roleStudent = 'ROLE_STUDENT';

        $scope.load = function (id) {
            CourseSection.getOneById({id: id}, function(result) {
                $scope.courseSection = result;
                Users.getAll({'role' : $scope.roleStudent}, function(users){
                    $scope.userList = users;
                    removeAddedStudentFromList($scope.courseSection.courseProgress, $scope.userList);
                });
            });
        };

        function getUserById(id) {
            id = parseInt(id, 10);

            for (var i in $scope.userList) {
                if ($scope.userList[i].id && $scope.userList[i].id === id) {
                    return $scope.userList[i];
                }
            }
            return null;
        }

        function removeCourseProgressById(id) {
            id = parseInt(id, 10);

            for (var n = 0; n < $scope.courseSection.courseProgress.length; n++) {
                if ($scope.courseSection.courseProgress[n].id === id) {
                    $scope.courseSection.courseProgress.splice(n, 1);
                    return;
                }
            }
        }

        function removeAddedStudentFromList(arrUserInTable, arrUser){
            if(arrUserInTable.length === 0){
                $scope.userList = arrUser;
                return;
            }
            for(var z in arrUserInTable){
                remove(arrUser, arrUserInTable[z].user);
            }
        }

        function remove(arr, item) {
            for(var i = arr.length; i--;) {
                if(arr[i].id === item.id) {
                    arr.splice(i, 1);
                }
            }
        }

        $scope.addCourseProgress = function () {
            var obj = {};
            obj.user = getUserById($scope.newCourseProgressId);
            $scope.courseSection.courseProgress.push(obj);

            CourseSection.update($scope.courseSection,
                function (data) {
                    $scope.courseSection = data;
                    $('#addModal').modal('hide');
                    $scope.load($stateParams.id);
                    $scope.clear();
                }
            );
        };

        $scope.deleteCourseProgress = function () {
            removeCourseProgressById($scope.removedCourseProgressId);

            CourseSection.update($scope.courseSection,
                function (data) {
                    $scope.courseSection = data;
                    $('#deleteModal').modal('hide');
                    $scope.load($stateParams.id);
                    $scope.clear();
                }
            );
        };

        $scope.showAddDialog = function () {
            $('#addModal').modal('show');
        };

        $scope.showDeleteDialog = function () {
            $('#deleteModal').modal('show');
        };

        $scope.clear = function () {
            $scope.newCourseProgressId = '';
            $scope.addCourseProgressForm.$setPristine();
            $scope.addCourseProgressForm.$setUntouched();

            $scope.removedCourseProgressId = '';
            $scope.deleteCourseProgressForm.$setPristine();
            $scope.deleteCourseProgressForm.$setUntouched();
        };

        $scope.load($stateParams.id);
    });
