'use strict';

angular.module('lmsApp').controller('RegionController', ['$scope', 'Region', 'Locations', function ($scope, Region, Locations) {
    $scope.regions = [];
    $scope.regionError = '';
    $scope.pagination = {
        page: 1,
        total: 0,
        perPage: 20
    };
    $scope.paginationVisible = false;
    $scope.loadAll = function () {
        Locations.query({
            page: $scope.pagination.page,
            per_page: $scope.pagination.perPage,
            type: 'REGION'
        }, function (result, headers) {
            $scope.regions = result;
            $scope.pagination.total = headers('X-Total-Count');
            $scope.paginationVisible = ($scope.pagination.perPage < $scope.pagination.total) ? true : false;
        });
    };
    $scope.loadAll();

    $scope.changePerPageCount = function (number) {
        $scope.pagination.perPage = number;
        $scope.pagination.page = 1;
        $scope.loadAll();
    };

    $scope.saveRegion = function() {
        var locationId = $scope.region.id,
            locationFn = !!locationId ? 'editLocation' : 'addLocation',
            regionParams = {
                name:  $scope.region.name,
                type: 'REGION'
            }

        !!locationId && _.assign(regionParams, {id: locationId});

        Locations[locationFn](regionParams, function() {
            $scope.loadAll();
            $('#saveRegionModal').modal('hide');
            $scope.clear();
        });
    };

    $scope.update = function (id) {
        Region.get({
            id: id
        }, function (result) {
            $scope.region = result;
            $('#saveRegionModal').modal('show');
        });
    };

    $scope.delete = function (id) {
        Region.get({
            id: id
        }, function (result) {
            $scope.region = result;
            $('#deleteRegionConfirmation').modal('show');
        });
    };

    $scope.confirmDelete = function (id) {
        Region.delete({
                id: id
            },
            function () {
                $scope.loadAll();
                $('#deleteRegionConfirmation').modal('hide');
                $scope.clear();
            },
            function (response) {
                $scope.regionError = response.headers('Failure');
            });
    };

    $scope.clear = function () {
        $scope.region = {
            name: null,
            id: null
        };
        $scope.regionError = '';
        $scope.editForm.$setPristine();
        $scope.editForm.$setUntouched();
    };
    }]);
