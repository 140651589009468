'use strict';

angular.module('lmsApp').controller(
    'StatisticController', [
        '$scope',
        'StatisticResource',
        'usSpinnerService',
        '$timeout',
        'Locations',
        'SchoolNew',
        function($scope, StatisticResource, usSpinnerService, $timeout, Locations, SchoolNew){

            $scope.spinnerStatistic = false;

            $scope.regions = null;
            $scope.territories = null;
            $scope.schools = null;

            $scope.errorMessage = null;

            function spinnerShow() {
                $scope.spinnerStatistic = true;
                $timeout(function () {
                    usSpinnerService.spin('spinnerStatistic');
                }, 0);
            }
    
            function spinnerHide() {
                $scope.spinnerStatistic = false;
                usSpinnerService.stop('spinnerStatistic');
            }

            init();

            function init() {
                spinnerShow();
                Locations.query({
                        type: "REGION"
                    }, 
                    function(regions) {
                        spinnerHide();
                        $scope.regions = regions;
                    },
                    function(error) {
                        spinnerHide();
                        console.log("error status: " + error.status);
                        console.log("error text: " + error.statusText);
                        $scope.errorMessage = 'Во время загрузки страницы произошла ошабка. Попробуйте перезагрузить страницу или обратитесь в службу технической поддержки'
                    }
                )
            }

            $scope.getStatistic = function(fromDate, toDate, statisticType) {
                $scope.errorMessage = null;
                var params = {
                    from: Date.parse(fromDate),
                    till: Date.parse(toDate)
                }

                params[statisticType] = $scope[statisticType];

                spinnerShow();
                StatisticResource.getStatistic(params, 
                    function(response, getHeaders) {
                        spinnerHide();
                        var headers = getHeaders();

                        var blob = new Blob([response.data], {type: headers['content-type']});

                        var a = document.createElement('a');
                        document.body.appendChild(a);
                        var url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = "statistic.zip";
                        a.click();
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                    },
                    function(error) {
                        spinnerHide();
                        $scope.errorMessage = 'Возникла ошибка! Повторите попытку или обратитесь в службу технической поддержки'
                        console.log("error status: " + error.status);
                        console.log("error text: " + error.statusText);

                    }
                );
            }

            $scope.reset = function() {
                $scope.school = null;
                $scope.territory = null;
                $scope.region = null;
                $scope.errorMessage = null;
            }

            $scope.regionChange = function() {
                $scope.school = null;
                $scope.territory = null;
                
                if ($scope.region) {
                    spinnerShow();
                    Locations.children({
                            id: $scope.region
                        }, 
                        function(territories) {
                            spinnerHide();
                            $scope.territories = territories;
                        },
                        function(error) {
                            spinnerHide();
                            console.log("error status: " + error.status);
                            console.log("error text: " + error.statusText);
                            $scope.errorMessage = 'Во время загрузки страницы произошла ошабка. Попробуйте перезагрузить страницу или обратитесь в службу технической поддержки'
                        }
                    )
                }
            }

            $scope.territoryChange = function() {
                $scope.school = null;
                
                if ($scope.territory) {
                    spinnerShow();
                    SchoolNew.getByTerritory({
                            id: $scope.territory
                        }, 
                        function(schools) {
                            spinnerHide();
                            $scope.schools = schools;
                        },
                        function(error) {
                            spinnerHide();
                            console.log("error status: " + error.status);
                            console.log("error text: " + error.statusText);
                            $scope.errorMessage = 'Во время загрузки страницы произошла ошабка. Попробуйте перезагрузить страницу или обратитесь в службу технической поддержки'
                        }
                    )
                }
            }

        }
    ]
)