'use strict';

angular.module('lmsApp')
    .controller('CourseOpenTaskStudentListController', function ($scope, $stateParams, Course, Principal, AssignmentProgress) {

        $scope.openTask = Course.getOpenTask({id: $stateParams.id, taskImsccId: $stateParams.imsccId});
        $scope.courseId = $stateParams.id;
        $scope.asmntProgresses = [];

        $scope.load = function (imsccId) {
            if (Principal.isInRole('OP_MANAGE_COURSE_SECTIONS')) {
                // load assignment progresses for all course sections
                // todo: add fetch method for the admin
            } else {
                // load assignment progresses for the course section that belongs to the current teacher
                $scope.asmntProgresses = AssignmentProgress.getAllByAssignmentIdAndTeacherId({courseId: $scope.courseId, imsccId: imsccId});
            }
        };
        $scope.load($stateParams.imsccId);
    });
