'use strict';

angular.module('lmsApp')
    .controller('LessonSummaryController', function ($scope, $rootScope, $stateParams, Course) {

        $scope.course = $rootScope.currentCourse;
        $scope.lesson = null;

        $scope.load = function (params) {

            // don't load the course, if it's already in scope
            if (!$scope.course || $scope.course.id !== +params.id) {
                $scope.course = Course.get({id: params.id}, function (result) {
                    $scope.course = result;
                });
            }

            // don't load topics, if they are already in scope
            if ($scope.topics && $scope.topics.length > 0) {
                $scope.lesson = getLesson($scope.topics, params);
                return;
            }
            $scope.topics = Course.getDynamicSyllabus({id: params.id}, function (result) {
                $scope.topics = result;
                $scope.lesson = getLesson(result, params);
            });
        };

        $scope.load($stateParams);

        function getLesson(topics, params) {
            var lesson = topics.map(function (topic) {
                if (topic.id === +params.topicId) {
                    return topic.lessons;
                } else {
                    return null;
                }
            }).filter(function (a) {
                return (a);
            }).reduce(function (a, b) {
                // flatten an array of lessons
                return a.concat(b);
            }).filter(function (lesson) {
                return (lesson.id === +params.lessonId);
            });

            if (lesson.length > 0) {
                return lesson[0];
            } else {
                return null;
            }
        }

    });
