(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('lmsApp')
        .constant('VERSION', "1.4.1")
        .constant('GIT', {
	"message": "Merge branch 'dev' into 'master'\n\nDev to master\n\nSee merge request lms-mob-edu/lms-legacy/legacy-front!94",
	"commit": "81cee3fdde2022e087b82f8a4161b6af958427be",
	"branch": "master",
	"date": "2023-02-10T07:12:01.000Z"
})
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('ENV', "prod")
        .constant('API', {
	"newAdmin": "/adm",
	"course": "/course"
})
        .constant('innopolis', undefined)
        .constant('innopolis_stage', undefined)
;
})();
