'use strict';
/**
 * Created by AMoskvin on 16.11.2015.
 */
angular.module('lmsApp')
    .service('ImageViewer', ['dialogs', function (dialogs) {

        // Variables
        var openImageTemplate = '/app/admin/files/imageViewerModal.html',
            viewer = {};

        // Methods
        this.openImage = openImage;

        function openImage(path) {
            if (!path) {
                return false;
            }

            var dlg = dialogs.create(openImageTemplate, 'imageViewerCtrl', {'path': path ? path : null}, {windowClass: 'image-viewer'});

        }
    }])
    .controller('imageViewerCtrl', ['$scope', '$modalInstance', 'data',
        function ($scope, $modalInstance, initObj) {

            $scope.filePath = '';
            // Methods
            $scope.cancel = cancel;

            init();

            function init() {

                if (!initObj.path) {
                    $modalInstance.close();
                    return false;
                }
                $scope.filePath = initObj.path;
            }

            function cancel() {
                $modalInstance.dismiss('Canceled');
            }
        }]);
