'use strict';

angular
  .module('lmsApp')
  .controller(
    'CourseQuizController',
    function($scope, $rootScope, $log, $state, $stateParams, Course, Principal, User, Quiz) {
      $scope.quiz = {};
      $rootScope.rightAnswersShown = false;
      $scope.load = function(id, imsccId) {
        $rootScope.rightAnswersShown = false;
        $scope.courseId = id;
        $scope.imsccId = imsccId;
        $rootScope.isFinished = false;
        Principal.identity().then(function(account) {
          User.get({ login: account.login }, function(user) {
            $scope.userId = user.id;
            Course.isQuizAvailable(
              { id: $scope.courseId, quizImsccId: $scope.imsccId, studentId: $scope.userId },
              function(data) {
                $scope.attemptsLeft = data.left;
                if (data.allowed) {
                  $scope.isAllowed = true;
                }
              }
            );
          });
        });

        Course.get({ id: id }, function(result) {
          $scope.course = result;
        });

        Course.getQuiz({ id: id, quizImsccId: imsccId }, function(quiz) {
          $scope.quiz = quiz;
          $rootScope.responseString = {};
          $rootScope.quizAnswers = {};
          Course.getQuizDraft(
            { id: $scope.courseId, quizImsccId: $scope.imsccId, studentId: $scope.userId },
            function(data) {
              if (Object.getOwnPropertyNames(data).length > 0) {
                $rootScope.quizAnswers = data;
              }
            }
          );
        });
      };

      $scope.load($stateParams.id, $stateParams.imsccId);

      $scope.confirmGetCorrectAnswers = function() {
        $('#getCorrectAnswersConfirmation').modal({
          backdrop: 'static',
          keyboard: false
        });
      };

      $scope.getCorrectAnswers = function() {
        Quiz.getCorrectAnswers(
          { courseId: $scope.courseId, quizImsccId: $scope.imsccId },
          function(data) {
            $rootScope.quizAnswers = data;
            $rootScope.isFinished = true;
            $rootScope.rightAnswersShown = true;
            $('#getCorrectAnswersConfirmation').modal('hide');
          }
        );
      };

      $scope.sendAnswer = function(quizAnswers) {
        Course.answerQuiz(
          { id: $scope.courseId, quizImsccId: $scope.imsccId, studentId: $scope.userId },
          quizAnswers,
          function(data) {
            $rootScope.responseString = data;
            $scope.answer = data['0']['0'].text;
            $rootScope.isFinished = true;
            if ($scope.attemptsLeft === 1) {
              $scope.isAllowed = false;
            }
          }
        );
      };

      $scope.saveDraft = function(quizAnswers) {
        Course.saveQuizDraft(
          { id: $scope.courseId, quizImsccId: $scope.imsccId, studentId: $scope.userId },
          quizAnswers,
          function() {
            $('#saveDraftModal').modal('show');
          }
        );
      };

      $scope.reloadRoute = function() {
        $scope.isAllowed = false;
        $state.reload();
      };

      $scope.goBack = function() {
        if (
          $rootScope.previousStateName.length === 0 || $rootScope.previousStateName === 'courseQuiz'
        ) {
          $state.go('courseQuizzes', $stateParams);
        } else {
          $state.go($rootScope.previousStateName, $rootScope.previousStateParams);
        }
      };
    }
  );
