'use strict';

angular.module('lmsApp')
    .factory('CourseGroup', function ($resource) {
        return $resource('api/courseGroups/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getAll': {
                method: 'GET',
                url: 'api/courseGroups',
                isArray: true
            },
        });
    })
  .factory('CourseType', function ($resource) {
    return $resource('api/courseTypes/:id', {}, {
      'get': {
        method: 'GET',
        isArray: true
      },
      'getAll': {
        method: 'GET',
        url: 'api/courseTypes',
        isArray: true
      }
    });
  });