"use strict";

angular.module("lmsApp").controller("FilesController", [
    "$scope",
    "FilesService",
    "Course",
    "Upload",
    "$http",
    "$timeout",
    "$stateParams",
    "uuid4",
    "Quiz",
    "Uuid",
    "Assignment",
    "WikiManager",
    "ImageViewer",
    "StaticPageHelper",
    "OpenTaskManager",
    "QuizManager",
    "FilesManager",
    "$q",
    "$window",
    function(
        $scope,
        FilesService,
        Course,
        Upload,
        $http,
        $timeout,
        $stateParams,
        uuid4,
        Quiz,
        Uuid,
        Assignment,
        WikiManager,
        ImageViewer,
        StaticPageHelper,
        OpenTaskManager,
        QuizManager,
        FilesManager,
        $q,
        $window
    ) {
        // Variables
        var _editor = {};
        $scope.courseId = $stateParams.courseId;
        $scope.courses = null;
        $scope.course = {};
        $scope.coursePath = null;
        $scope.currentPath = null;
        $scope.currentPathData = null;
        $scope.currentFile = null;
        $scope.fileSelected = false;
        $scope.errorUpload = {};
        $scope.errorUpload.weight = false;
        $scope.errorUpload.contentType = false;
        $scope.errorUpload.extension = false;
        $scope.errorUpload.other = false;
        $scope.selectedNode = null;
        $scope.fileName = null;
        $scope.beginLink = "";
        $scope.dataForTree = [];
        $scope.treeOptions = {
            nodeChildren: "children",
            dirSelectable: true,
            isLeaf: function(node) {
                return !(node.children && angular.isArray(node.children));
            },
            injectClasses: {
                ul: "a1",
                li: "a2",
                liSelected: "a7",
                iExpanded: "a3",
                iCollapsed: "a4",
                iLeaf: "a5",
                label: "a6",
                labelSelected: "a8"
            },
            equality: function(node1, node2) {
                return node1 === node2;
            }
        };
        $scope.configPath = {
            domainUrl: window.location.origin,
            courses: "upload/courses/",
            courseFiles: "files/"
        };
        $scope.fileLinkDownload = null;
        $scope.quiz = {};
        $scope.quizMap = {};
        $scope.quizImsccIdMap = {};
        $scope.courseQuizzes = [];
        $scope.topics = [];
        $scope.openTask = {};

        // Methods
        $scope.getCoursePath = getCoursePath;
        $scope.selectNode = selectNode;
        $scope.showFile = showFile;
        $scope.goParentPath = goParentPath;
        $scope.goHomePath = goHomePath;
        $scope.clearFiles = clearFiles;
        $scope.isFileSupported = isFileSupported;
        $scope.uploadFiles = uploadFiles;
        $scope.reloadCurrentPath = reloadCurrentPath;
        $scope.createFolder = createFolder;
        $scope.copyToClipboard = StaticPageHelper.copyToClipboard;

        init();

        function init() {
            Course.get({ id: $stateParams.courseId, expand: "topics" }, function(result) {
                $scope.course = result;
                $scope.topics = $scope.course.topics;
                var id = $scope.course.id;
                Course.getQuizzes({ id: id }, function(result) {
                    $scope.courseQuizzes = result;
                    angular.forEach($scope.courseQuizzes, function(value) {
                        $scope.quizMap[value.id] = value;
                        $scope.quizImsccIdMap[value.imsccId] = true;
                    });
                    angular.forEach($scope.topics, function(topic) {
                        angular.forEach(topic.lessons, function(lesson) {
                            angular.forEach(lesson.quizzes, function(quiz) {
                                if ($scope.quizMap[quiz.id]) {
                                    $scope.quizMap[quiz.id].topic = topic;
                                    $scope.quizMap[quiz.id].lesson = { id: lesson.id };
                                }
                            });
                        });
                    });
                });
            });
        }

        $scope.editFile = function() {
            FilesManager.openFile($scope.courseId, $scope.fileLinkDownload, {
                windowClass: "edit-lesson-page"
            });
        };

        $scope.openFilePlain = function() {
            $window.open(
                $scope.configPath.domainUrl +
                "/" +
                $scope.configPath.courses +
                $scope.courseId +
                "/" +
                $scope.configPath.courseFiles +
                $scope.fileLinkDownload,
                "_blank"
            );
        };

        $scope.initFile = function(file) {
            $scope.fileName = file.name;
            showFile($scope.currentPath + "/" + $scope.fileName);
            $scope.fileLinkDownload = $scope.currentPath + "/" + $scope.fileName; //getFileDownloadLink();
        };

        $scope.showDeleteFileModal = function() {
            $("#deleteFileEditor").modal("show");
        };

        $scope.deleteFile = function() {
            FilesService.deleteFile({ id: $scope.courseId, path: $scope.currentFile }, function() {
                $scope.fileSelected = false;
                $scope.currentFile = "";
                $scope.reloadCurrentPath();
            });
        };

        // Add Quizes from filebrowser
        $scope.initEditor = function(editorInstance, selector, data) {
            tinyMCE.init({
                selector: "#" + selector,
                plugins: "link image code contextmenu table textcolor colorpicker lists advlist",
                contextmenu: "link image inserttable | cell row column deletetable lmsformula",
                external_plugins: {
                    lmsformula: window.location.origin +
                    "/app/components/util/tinymce/plugins/lmsformula/lmsformula.js"
                },
                extended_valid_elements: "a[onclick|ng-click|*]",
                toolbar: "nowrapbutton verticalAlign newdocument undo redo | bold italic underline strikethrough | forecolor backcolor | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | bullist numlist | link image code | table | lmsformula",
                language: "ru",
                language_url: "/i18n/tinyMCE/ru.js",
                formats: {
                    nowrap_format: { inline: 'span', styles: { 'white-space': 'nowrap', 'display': 'inline-block', 'vertical-align': 'baseline' }, attributes: { class: 'nowrap' } },
                    verticalAlignMiddle: { selector: 'img', styles: { 'vertical-align': 'middle' } },
                    verticalAlignBaseline: { selector: 'img', styles: { 'vertical-align': 'baseline' } },
                    verticalAlignBottom: { selector: 'img', styles: { 'vertical-align': 'bottom' } }
                },
                setup: function(editor) {
                    editor.on("init", function(event) {
                      editorInstance.instance = editor;
                      editorInstance.instance.setContent(data);
                      if (!data) {
                        editor.execCommand("fontSize", false, "14pt");
                      }
                    });

                    editor.on("SetContent", function(e) {
                        // replace links in newly inserted images
                        if (e.content.indexOf("<img") === 0) {
                            editor.setContent(
                                editor
                                    .getContent()
                                    .replace(
                                        /src="\/?(web_resources|wiki_content)/gim,
                                        'src="/upload/courses/' + $stateParams.id + "/files/$1"
                                    )
                            );
                        }
                    });

                    editor.addButton('nowrapbutton', {
                        text: 'NoBr',
                        stateSelector: 'span.nowrap',
                        tooltip: 'Запретить перенос',
                        icon: false,
                        onclick: function (event) {
                            tinyMCE.activeEditor.formatter.toggle('nowrap_format');
                        }
                    });
                    editor.addButton('verticalAlign', {
                        type: 'listbox',
                        text: 'VAlign',
                        icon: false,
                        onselect: function (e) {
                            var val = this.value();
                            switch (val) {
                                case 1:
                                    tinyMCE.activeEditor.formatter.toggle('verticalAlignMiddle');
                                    break;
                                case 2:
                                    tinyMCE.activeEditor.formatter.toggle('verticalAlignBaseline');
                                    break;
                                case 3:
                                    tinyMCE.activeEditor.formatter.toggle('verticalAlignBottom');
                                    break;
                            }
                        },
                        values: [
                            {text: 'VAlign:middle', stateSelector: 'img', value: 1},
                            {text: 'VAlign:baseline', stateSelector: 'img', value: 2},
                            {text: 'VAlign:bottom', stateSelector: 'img', value: 3}
                        ],
                        onPostRender: function () {
                            var self = this;
                            editor.on('click', function (e) {
                                if (e.target.nodeName !== 'IMG') {
                                    self.value(0);
                                    return;
                                }
                                switch (e.target.style.verticalAlign) {
                                    case 'middle':
                                        self.value(1);
                                        break;
                                    case 'baseline':
                                        self.value(2);
                                        break;
                                    case 'bottom':
                                        self.value(3);
                                        break;
                                    default:
                                        break;
                                }
                            });
                        }
                    });
                },
                content_style: ".nowrap { white-space:nowrap;background-color:#dbdbdb; }"
            });
        };

        $scope.addTest = function() {
            QuizManager.openQuiz($scope.courseId);
        };

        $scope.addOpenTask = function() {
            OpenTaskManager.openTask($scope.courseId);
        };

        $scope.addWiki = function() {
            WikiManager.openWiki($scope.courseId);
        };

        $scope.addImageViewer = function(fileName) {
            showFile($scope.currentPath + "/" + fileName);
            $scope.fileLinkDownload = getFileDownloadLink();
            ImageViewer.openImage($scope.configPath.domainUrl + $scope.fileLinkDownload);
        };

        //save Quiz
        $scope.saveQuiz = function() {
            $scope.quiz.course = { id: $scope.courseId };
            if (_editor.instance) {
                $scope.quiz.description = _editor.instance.getContent();
            }
            Quiz.update(
                $scope.quiz,
                function(response) {
                    $scope.quiz.id = response.id;
                    $scope.quizSettings = false;
                    $scope.quizQuestions = true;
                    $scope.edit = true;
                    var modal = document.getElementById("saveQuizModal");
                    modal.scrollTop = 0;
                    toastr.success("Тест сохранён");
                },
                function(response) {
                    $scope.error = response.headers("Failure");
                }
            );
        }; //save openTask
        $scope.saveOpenTask = function() {
            $scope.openTask.course = {
                id: $scope.courseId
            };
            if (_editor.instance) {
                $scope.openTask.text = _editor.instance.getContent();
            }
            if (!$scope.openTask.openTaskForTopic) {
                $scope.openTask.path = null;
            } else {
                if ($scope.openTask.openTaskFileName === null) {
                    $scope.openTask.openTaskFileName = "none";
                }
                $scope.openTask.path = $scope.openTask.openTaskFileName;
            }
            Assignment.updateOpenTask(
                $scope.openTask,
                function(response) {
                    $("#addOpenTaskModal").modal("hide");
                    toastr.success("ЗОО сохранено");
                },
                function(response) {
                    $scope.error = response.headers("Failure");
                }
            );
        };
        $scope.checkFieldsInOpenTask = function() {
            if ($scope.openTask.openTaskForTopic || $scope.openTask.keyQuestion) {
                $scope.openTask.requiredByDefault = true;
                $scope.openTask.markCanBeGiven = true;
            }
        };
        function getCourses() {
            $scope.courses = Course.query();
        }
        function getCoursePath(id, path) {
            var fileNames = "";
            if (id) {
                path = path ? path.replace(/^\//, "") : ""; // Если перешли в корень, или находимся в нём, то инициализируем filesName и выводим только 2 папки
                if (path === "") {
                    fileNames = "web_resources, wiki_content";
                    FilesService.get(
                        {
                            id: id,
                            path: path,
                            fileNames: fileNames
                        },
                        function(response) {
                            $scope.currentPath = path;
                            $scope.currentPathData = response;
                            if(response.folders) $scope.currentPathData.folders.sort(naturalCompare);
                            if(response.files) $scope.currentPathData.files.sort(function(obj1, obj2) {
                                return naturalCompare(obj1.name, obj2.name)
                            });
                        }
                    );
                } else {
                    // Иначе мы находимся в какой-то папке web_resources или wiki_content, либо в других, поэтмоу показываем все файлы и папки
                    FilesService.get({ id: id, path: path }, function(response) {
                        $scope.currentPath = path;
                        $scope.currentPathData = response;
                        if(response.folders) $scope.currentPathData.folders.sort(naturalCompare);
                        if(response.files) $scope.currentPathData.files.sort(function(obj1, obj2) {
                            return naturalCompare(obj1.name, obj2.name)
                        });
                    });
                }
            }
        }
        function getCourseRootPath(cid) {
            if (cid) {
                FilesService.get({ id: cid }, function(response) {
                    $scope.coursePath = response;
                });
            }
        }
        function selectNode(node) {
            if (!node.children) {
                // $scope.getCoursePath($scope.course.id,'web_resources/' + getParentPath(getNodePath(node)));
                $scope.getCoursePath($scope.course.id, "/" + getParentPath(node.path));
                showFile(node.path);
            } else {
                // $scope.getCoursePath($scope.course.id, 'web_resources/' + getNodePath(node));
                $scope.getCoursePath($scope.course.id, "/" + node.path);
            }
        }
        function goParentPath() {
            if ($scope.currentPath && $scope.currentPath !== "" && !/^\s+$/.test($scope.currentPath)) {
                $scope.getCoursePath($scope.course.id, getParentPath($scope.currentPath));
                if ($scope.expandedNodes && $scope.expandedNodes !== void 0) {
                    $scope.expandedNodes.pop();
                }
            }
        }
        function getParentPath(path) {
            return path.substring(0, path.lastIndexOf("/"));
        }
        function showFile(filePath) {
            $scope.fileSelected = true;
            $scope.currentFile = filePath;
        }
        function getNodePath(node) {
            return node.parent ? getNodePath(node.parent) + "/" + node.name : node.name;
        }
        function getCourseTree(cid) {
            if (cid) {
                FilesService.getTree(
                    {
                        id: cid,
                        fileNames: "web_resources, wiki_content"
                    },
                    function(response) {
                        response[0].children = makeSort(response[0].children);
                        $scope.dataForTree = buildDataForTree(response);
                    }
                );
            }
        }
        function makeSort(arr) {
            arr.forEach(function(item){
                if(item.children) makeSort(item.children)
            });
            arr.sort(function(obj1, obj2) {
                return naturalCompare(obj1.name, obj2.name);
            });
            return arr;
        }
        function goHomePath() {
            $scope.path = "";
            if ($scope.course.id) {
                // Возращаемся в корневую дирректорию в файловом браузере
                $scope.getCoursePath($scope.course.id, "");
                // Возращаемся в корневую дирректорию в дереве файлов, путём ссброса массива раскрытых папок
                $scope.expandedNodes.length = 0;
            }
        }
        $scope.makeFile = function() {
            FilesService.editText(
                {
                    id: $scope.courseId,
                    path: $scope.currentFile
                },
                $scope.raw_html
            );
        };
        function buildDataForTree(data, parent) {
            if (data && data.length > 0) {
                data.forEach(function(el) {
                    if (el != null) {
                        if (parent) {
                            el.path = parent.path ? parent.path + "/" + el.name : parent.name + "/" + el.name;
                        } else {
                            el.path = el.name;
                        }
                        if (el.children && angular.isArray(el.children) && el.children.length > 0) {
                            buildDataForTree(el.children, el);
                        }
                    }
                });
                return data;
            } else {
                return [];
            }
        }

        function uploadOneFile(file) {
          var filePathForUpload = "api/files/course/" + $scope.course.id + "/files?path=" + encodePath($scope.currentPath) + "/" + encodePath(file.name);
          var uploadPromise = Upload.upload({
            url: filePathForUpload,
            file: file
          });
          uploadPromise.then(function (response) {
          }).catch(function (response) {
            $scope.errorUpload = {
              weight: false,
              contentType: false,
              extension: false,
              other: false
            };
            if (status === 400) {
              if (response.headers("Failure") === "Uploaded file is heavier then 500KB.") {
                $scope.errorUpload.weight = true;
              } else if (
                response.headers("Failure") ===
                "Uploaded file's content type is not allowed."
              ) {
                $scope.errorUpload.contentType = true;
              } else if (
                response.headers("Failure") === "Uploaded file is not a supported picture."
              ) {
                $scope.errorUpload.extension = true;
              }
            }
          });
          return uploadPromise;
        }

        function uploadFiles(files) {
          var dfd = $q.defer(), filePath;
          if (!Array.isArray(files)) {
            $scope.files = $.makeArray(files);
          } else {
            $scope.files = files;
          }
          if ($scope.course && $scope.course.id && $scope.files.length > 0) {
            angular.forEach($scope.files, function (file, key) {
              if (file && !file.$error) {
                var filePathForTest = "api/files/check/course/" + $scope.course.id + "/files?path=" + encodePath($scope.currentPath) + "/" + encodePath(file.name);
                var checkUploadPromise = Upload.upload({
                  url: filePathForTest,
                  file: file
                });
                // Типо проверяет есть ли файл по такому пути
                checkUploadPromise.then(function (response) {
                  // Если нет делаем аплоад
                  uploadOneFile(file).then(function (response) {
                    $timeout(function () {
                      $scope.errorUpload = {
                        weight: false,
                        contentType: false,
                        extension: false,
                        other: false
                      };
                      if ($scope.files.length === key + 1) {
                        dfd.resolve();
                      }
                    });
                  }).catch(function () {
                    dfd.reject();
                  });
                  // --------------------------
                }).catch(function (response) {
                  // Если уже есть файл по такому пути
                  if (response.status === 409 && confirm("Файл с таким именем уже существует. Продолжить загрузку?")) {
                    uploadOneFile(file).then(function (response) {
                      $timeout(function () {
                        $scope.errorUpload = {
                          weight: false,
                          contentType: false,
                          extension: false,
                          other: false
                        };
                        if ($scope.files.length === key + 1) {
                          dfd.resolve();
                        }
                      });
                    }).catch(function () {
                      dfd.reject();
                    });
                  }
                });
                // --------------------------
              }
            });
          }
          dfd.promise.then(
            function () {
              $scope.reloadCurrentPath();
            },
            function () {
              $scope.clearFiles();
              console.log("upload error");
            }
          );
        }

        function clearFiles() {
            $scope.files = [];
        }
        function reloadCurrentPath() {
            if ($scope.course && $scope.course.id) {
                $scope.getCoursePath($scope.course.id, $scope.currentPath ? $scope.currentPath : "");
                getCourseTree($scope.course.id);
            }
        } // rough validation
        function isFileSupported() {
            return typeof File !== "undefined" && typeof FileReader !== "undefined";
        }
        function getFileDownloadLink() {
            if ($scope.fileSelected && $scope.currentFile) {
                return "/" +
                    $scope.configPath.courses +
                    $scope.course.id +
                    "/" +
                    $scope.configPath.courseFiles +
                    $scope.currentFile.replace(/^\//, "");
            }
        }
        function createFolder() {
            var folderName = prompt("Введите имя папки:", "");
            if ($scope.course && $scope.course.id && folderName && !/^\s+$/.test(folderName)) {
                folderName = encodePath(folderName);
                folderName = $scope.currentPath + "/" + folderName;
                FilesService.editDirectory({ id: $scope.course.id, path: folderName }, {}).$promise.then(
                    function() {
                        $scope.reloadCurrentPath();
                    },
                    function() {}
                );
            }
        }
        function encodePath(path) {
            if (path && angular.isString(path) && path !== "" && !/^\s+$/.test(path)) {
                path = path.replace(/^\s+|\s+$/g, "").replace(/\s+/g, " "); // trim and replace spaces
                path = path.replace(/%*/g, "");
                // remove special symbols
                path = encodeURIComponent(path);
                // encode special
                return path;
            } else {
                throw SyntaxError('Wrong string format of "path"');
            }
        }
        $scope.$watch("currentFile", function(v) {
            if (v && $scope.fileSelected) {
                $scope.fileLinkDownload = getFileDownloadLink();
            }
        });
        $scope.$watch("quiz.title", function() {
            if ($scope.quiz.title !== void 0 && $scope.quiz.title !== null && $scope.quiz.title !== "") {
                $scope.imsccId = $scope.beginLink + $scope.quiz.title + "</a>";
            }
        });
        $scope.$watch("openTask.title", function() {
            if (
                $scope.openTask.title !== void 0 &&
                $scope.openTask.title !== null &&
                $scope.openTask.title !== ""
            ) {
                $scope.imsccId = $scope.beginLink + $scope.openTask.title + "</a>";
            }
        });
        $scope.$watch("currentPath", function() {
            $scope.fileSelected = false;
        });
        function naturalCompare(a, b) {
            var ax = [], bx = [];
            a.replace(/(\d+)|(\D+)/g, function(_, $1, $2) {
                ax.push([$1 || Infinity, $2 || ""]);
            });
            b.replace(/(\d+)|(\D+)/g, function(_, $1, $2) {
                bx.push([$1 || Infinity, $2 || ""]);
            });
            while (ax.length && bx.length) {
                var an = ax.shift();
                var bn = bx.shift();
                var nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
                if (nn) return nn;
            }
            return ax.length - bx.length;
        }
        $scope.$watch("course", function(v) {
            if (v && v.id) {
                getCourseTree(v.id);
                getCoursePath(v.id, "");
            }
        });
    }
]);
