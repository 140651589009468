'use strict';

angular.module('lmsApp').filter('learningClassFilter', function () {
	return function (items, id) {
		var filtered = [];

		if (id === undefined || id === '') {
			return items;
		}

		angular.forEach(items, function (item) {
			if (item && item.school && item.school.id === id) {
				filtered.push(item);
			}
		});

		return filtered;
	};
});
