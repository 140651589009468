'use strict';

angular.module('lmsApp')
    .controller('groupSectionController', function ($scope, Account, UserGroup) {
        $scope.groups = [];
        $scope.account = {};

        $scope.loadAll = function () {
            Account.get(function (result) {
                $scope.account = result.data;
                UserGroup.getAllByTutorId({tutorId: $scope.account.id}, function (groups) {
                    $scope.groups = groups;
                });
            });
        };
        $scope.loadAll();
    });
