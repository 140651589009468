'use strict';

angular.module('lmsApp').controller('TestController', function ($scope, Test, TestUploader, $translate) {

    $scope.tests = [];
    $scope.testError = null;
    $scope.sorting = {
        sorting_fields: 'id',
        direction: 'asc',
        search_text: ''
    };
    $scope.pagination = {
        page: 1,
        total: 0,
        per_page: 20,
        sorting_fields: ['id']
    };
    $scope.errors = '';


    // =====================================

    $scope.clear = clear;
    $scope.create = create;
    $scope.update = update;
    $scope.deleteTest = deleteTest;
    $scope.loadAll = loadAll;
    $scope.confirmDelete = confirmDelete;
    $scope.updateAndUpload = updateAndUpload;
    $scope.changePerPageCount = changePerPageCount;

    init();


    // =====================================

    function init() {
        $scope.loadAll();
    }

    function changePerPageCount(number) {
        $scope.pagination.per_page = number;
        $scope.pagination.page = 1;
        $scope.loadAll();
    }

    function loadAll() {
        var _opts = {
            page: $scope.pagination.page,
            per_page: $scope.pagination.per_page,
            sorting_fields: $scope.sorting.sorting_fields,
            sorting_direction: $scope.sorting.direction,
            search_text: $scope.sorting.search_text
        };
        Test.getAll(_opts, function (result, headers) {
            $scope.pagination.total = headers('X-Total-Count');
            $scope.tests = result;
        });
    }

    function create() {
        Test.create($scope.test,
            function () {
                $scope.loadAll();
                $('#saveTestModal').modal('hide');
                $scope.clear();
            });
    }

    // ================================================================================

    function update(id) {
        Test.get({
            id: id
        }, function (result) {
            $scope.clear();
            $scope.test = result;
            $('#updateTestModal').modal('show');
        });
    }

    function deleteTest(id) {
        Test.get({
            id: id
        }, function (result) {
            $scope.clear();
            $scope.test = result;
            $('#deleteTestConfirmation').modal('show');
        });
    }

    function confirmDelete(id) {
        Test.delete({
                id: id
            },
            function () {
                $scope.loadAll();
                $('#deleteTestConfirmation').modal('hide');
                $scope.clear();
            },
            function (response) {
                $scope.testError = response.headers('Failure');
            });
    }


    function clear() {
        $scope.test = {
            id: null,
            name: null,
            uuid: null,
            path: null
        };
        $scope.testCartridge = null;
        $scope.testError = '';
        $scope.progress = 0;
        $scope.unpackingInProgress = false;
        $scope.editForm.$setPristine();
        $scope.editForm.$setUntouched();
        $scope.updateForm.$setPristine();
        $scope.updateForm.$setUntouched();
        document.getElementById('test-update-form').elements['cartridge'].value = '';
    }

    // ============================================================

    function updateAndUpload() {
        $scope.testError = null;
        var checkCartridge = function (cartridge, test) {
            Test.checkUpload({id: test.id}, function (success) {
                    uploadCartridge(cartridge, test)
                },
                function (error) {
                    if (error.status == 409 && confirm($translate.instant('lmsApp.test.confirmOverride'))) {
                        uploadCartridge(cartridge, test)
                    } else {
                        $scope.testError = error.headers().failure;
                    }
                });
        };

        function uploadCartridge(cartridge, test) {
            TestUploader.upload(cartridge, test.id, {
                progress: function (evt) {
                    $scope.progress = parseInt(100.0 * evt.loaded / evt.total);
                    if ($scope.progress === 100) {
                        $scope.unpackingInProgress = true;
                    }
                },
                success: function (data, status) {
                    $scope.loadAll();
                    $('#updateTestModal').modal('hide');
                    $scope.clear();
                },
                error: function (data, status, headers) {
                    if (headers('Failure')) {
                        $scope.testError = decodeURIComponent(headers('Failure'));
                    } else if (data && data.message) {
                        $scope.testError = data.message;
                    } else {
                        $scope.testError = 'Неизвестная ошибка';
                    }
                }
            })
        }

        Test.update($scope.test, function () {
            // successful test update, upload the cartridge
            if ($scope.testCartridge) {
                checkCartridge($scope.testCartridge, $scope.test);
                return;
            }

            $scope.loadAll();
            $('#updateTestModal').modal('hide');
            $scope.clear();
        }, function (response) {
            $scope.testError = response.headers('Failure');
        });
    }

    // ==================================================================================

});

