'use strict';

angular.module('lmsApp').factory('User', function($resource) {
  return $resource(
    'api/users/:login',
    {},
    {
      query: { method: 'GET', isArray: true },
      get: {
        method: 'GET',
        transformResponse: function(data) {
          data = angular.fromJson(data);
          return data;
        }
      },
      updateParents: {
        method: 'PUT',
        url: 'api/users/studentparents'
      },
      updateCourses: {
        method: 'PUT',
        url: 'api/users/teacherCourses'
      },
      updateMethodologistCourses: {
        method: 'PUT',
        url: 'api/users/methodologistCourses'
      },
      getAllByAuthority: {
        method: 'GET',
        url: 'api/users/authority/:authority',
        isArray: true
      },
      getTeachersForCourse: {
        method: 'GET',
        url: 'api/users/teachers/course/:courseId',
        isArray: true
      },
      getByLogin: {
        method: 'GET',
        url: 'api/users/:login',
        isArray: true
      },
      getPossibleOwners: {
        method: 'GET',
        url: 'api/users/possibleOwners',
        isArray: true
      },
      getAllParentId: {
        method: 'GET',
        url: 'api/users/parents/:id',
        isArray: true
      },
      getAllById: {
        method: 'GET',
        url: 'api/users/id/:id',
        params: {
          expand: '@expand'
        }
      },
      getProfilePhotoPath: {
        method: 'GET',
        url: 'api/account/:id/profile_photo'
      },
      getAllLoggedUserActivity: {
        method: 'GET',
        url: 'api/users/logged'
      },
      getHistoryOfLoggedUsers: {
        method: 'GET',
        url: 'api/users/history'
      }
    }
  );
});
