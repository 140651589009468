'use strict';

angular.module('lmsApp')
    .factory('LessonProgress', function ($resource) {
        return $resource('api/lessonProgresses/', {}, {
            'addLessonAttempt': {
                method: 'PUT',
                url: '/api/lessonProgresses/lessonId/:lessonId/studentId/:studentId',
                params: {
                    lessonId: '@lessonId',
                    studentId: '@studentId'
                }
            }
        });
    });