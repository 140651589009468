'use strict';

angular.module('lmsApp').controller('CourseDomainController', function ($scope, CourseDomain) {
    $scope.courseDomains = [];

    // $scope.coursesInCourseGroup = 0;
    // $scope.courses = Course.query();
    $scope.pagination = {
        page: 1,
        total: 0,
        perPage: 20
    };

    $scope.loadAll = function () {
        CourseDomain.query({
            page: $scope.pagination.page,
            per_page: $scope.pagination.perPage
        }, function(result, headers) {
            $scope.pagination.total = headers('X-Total-Count');
            $scope.courseDomains = result;
        });
    };

    $scope.showUpdate = function (id) {
        CourseDomain.get({id: id}, function (result) {
            $scope.courseDomain = result;
            $('#saveCourseDomainModal').modal('show');
        });
    };

    $scope.save = function () {
        if ($scope.courseDomain.id !== void 0 && $scope.courseDomain.id !== null) {
            CourseDomain.update($scope.courseDomain,
                function () {
                    $scope.refresh();
                });
        } else {
            CourseDomain.save($scope.courseDomain,
                function () {
                    $scope.refresh();
                });
        }
    };

    $scope.delete = function (id) {
        CourseDomain.get({
            id: id
        }, function (result) {
            $scope.courseDomain = result;
            $('#deleteCourseDomainConfirmation').modal('show');
        });
    };

    $scope.confirmDelete = function (id) {
        CourseDomain.delete({
                id: id
            },
            function () {
                $scope.loadAll();
                $('#deleteCourseDomainConfirmation').modal('hide');
                $scope.clear();
            });
    };

    $scope.clear = function () {
        $scope.courseDomain = {
            domainName: null,
            id: null,
            courseGroups: []
        };

        $scope.editForm.$setPristine();
        $scope.editForm.$setUntouched();
    };

    $scope.changePerPageCount = function (number) {
        $scope.pagination.perPage = number;
        $scope.pagination.page = 1;
        $scope.loadAll();
    };


    $scope.refresh = function () {
        $scope.loadAll();
        $('#saveCourseDomainModal').modal('hide');
        $scope.clear();
    };

    $scope.loadAll();
});
