/**
 * Created by oem on 21.12.16.
 */
'use strict';

angular.module('lmsApp')
    .factory('SchoolNew', function ($resource, API) {
        var schoolApi = '/admin/schools'
        return $resource(schoolApi, {}, {
            getAll: {
                method: 'GET',
                url: schoolApi,
                isArray: true
            },                                                      
            create: {
                method: 'POST',
                url: schoolApi,
            },
            update: {
                method: 'PUT',
                url: schoolApi
            },
            deleteAdmins: {
                method: 'DELETE',
                url: schoolApi + '/admins'
            },
            addAdmins: {
                method: 'POST',
                url: schoolApi + '/admins'
            },
            deleteCoordinators: {
                method: 'DELETE',
                url: schoolApi + '/coordinators'
            },
            addCoordinators: {
                method: 'POST',
                url: schoolApi + '/coordinators'
            },
            deleteDeputyHeadteachers: {
                method: 'DELETE',
                url: schoolApi + '/deputyheadteachers'
            },
            addDeputyHeadteachers: {
                method: 'POST',
                url: schoolApi + '/deputyheadteachers'
            },
            deleteHeadteachers: {
                method: 'DELETE',
                url: schoolApi + '/headteachers'
            },
            addHeadteachers: {
                method: 'POST',
                url: schoolApi + '/headteachers'
            },
            deleteMethodologists: {
                method: 'DELETE',
                url: schoolApi + '/methodologists'
            },
            addMethodologists: {
                method: 'POST',
                url: schoolApi + '/methodologists'
            },
            deletePsychologists: {
                method: 'DELETE',
                url: schoolApi + '/psychologists'
            },
            addPsychologists: {
                method: 'POST',
                url: schoolApi + '/psychologists'
            },
            deleteTeachers: {
                method: 'DELETE',
                url: schoolApi + '/teachers'
            },
            addTeachers: {
                method: 'POST',
                url: schoolApi + '/teachers'
            },
            deleteTutors: {
                method: 'DELETE',
                url: schoolApi + '/tutors'
            },
            addTutors: {
                method: 'POST',
                url: schoolApi + '/tutors'
            },
            getByTerritory: {
                method: 'GET',
                url: schoolApi + '/location/:id',
                isArray: true
            },
            getById: {
                method: 'GET',
                url: schoolApi + '/:id'
            }                                                                                   
        });
    })
