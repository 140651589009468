'use strict';

angular.module('lmsApp').directive('questionResponses', function ($stateParams, $translate, uuid4) {
    return {
        restrict: 'E',
        scope: {
            type: '=',
            responseMap: '=',
            saved: '=',
            questionIndex: '='
        },
        templateUrl: 'app/entities/course/course-questionResponses.html',
        link: function (scope) {

            scope.allResponses = [];
            scope.multiDropItems = [];
            scope.lastQuestionId = 1;
            scope.lastMultyNumber = 1;
            scope.removeResponse = removeResponse;
            scope.moveResponseUp = moveResponseUp;
            scope.moveResponseDown = moveResponseDown;
            scope.addResponse = addResponse;
            scope.moveDropdownItemUp = moveDropdownItemUp;
            scope.moveDropdownItemDown = moveDropdownItemDown;
            scope.removeDropdownItem = removeDropdownItem;
            scope.addDropdownItem = addDropdownItem;
            scope.removeBlankItem = removeBlankItem;
            scope.addBlankItem = addBlankItem;
            scope.filteredMatchedAnswers = filteredMatchedAnswers;
            scope.selectResponse = selectResponse;
            scope.selectItemForResponse = selectItemForResponse;

            function init() {
                scope.questionType = null;
                switch (scope.type) {
                    case 'multiple_choice_question':
                    case 'multiple_answers_question':
                    case 'short_answer_question':
                        scope.questionType = 'simpleResponse';
                        break;
                    case 'numerical_question':
                        scope.questionType = 'simpleResponse';
                        if (objectLength(scope.responseMap) === 0) {
                            scope.addResponse();
                        }
                        break;
                    case "true_false_question":
                        scope.questionType = "simpleResponse";
                        // Добавляем ответы, если вопрос только создаётся
                        if (objectLength(scope.responseMap) === 0) {
                            scope.addResponse();
                        }
                        break;
                    case 'fill_in_multiple_blanks_question':
                    case 'multiple_dropdowns_question':
                        scope.questionType = 'multipleDropdownResponse';
                        break;
                    case 'matching_question':
                        scope.questionType = 'matchingResponse';
                        break;
                }
                toList();
            }

            function compare(a, b) {
                if (a.number < b.number)
                    return -1;
                if (a.number > b.number)
                    return 1;
                return 0;
            }

            function toList() {
                scope.allResponses = [];
                angular.forEach(Object.keys(scope.responseMap), function (value, key) {
                    if (scope.lastQuestionId < scope.responseMap[value].number) {
                        scope.lastQuestionId = scope.responseMap[value].number
                    }
                    scope.multiDropItems = [];
                    scope.lastMultyNumber = 1;
                    scope.allResponses.push(scope.responseMap[value]);
                    if (scope.responseMap[value].type == "multipleDropdownResponse") {
                        angular.forEach(Object.keys(scope.responseMap[value].items), function (itemValue, key) {
                            if (scope.lastMultyNumber < scope.responseMap[value].items[itemValue].number) {
                                scope.lastMultyNumber = scope.responseMap[value].items[itemValue].number
                            }
                            scope.multiDropItems.push(scope.responseMap[value].items[itemValue]);
                        });
                        scope.multiDropItems.sort(compare);
                        scope.allResponses[scope.allResponses.length - 1].listMultiDropItems = scope.multiDropItems;
                        scope.allResponses[scope.allResponses.length - 1].lastMultyNumber = scope.lastMultyNumber;
                    }
                });
                scope.allResponses.sort(compare);
            }

            function removeResponse(imsccId) {
                var wasCorrect = scope.responseMap[imsccId].isCorrect === true;
                delete scope.responseMap[imsccId];
                for (var i in scope.responseMap) {
                    if (scope.type === 'multiple_dropdowns_question' && scope.responseMap[i].correctImsccId === imsccId) {
                        scope.responseMap[i].correctImsccId = null;
                    } else if (scope.type === 'multiple_choice_question' && wasCorrect) {
                        scope.responseMap[i].isCorrect = true;
                        break;
                    }
                }
                toList();
            }

            function moveResponseUp(imsccId, index) {
                scope.responseMap[imsccId].number = scope.responseMap[imsccId].number - 1;
                scope.responseMap[scope.allResponses[index - 1].imsccId].number =
                    scope.responseMap[scope.allResponses[index - 1].imsccId].number + 1;
                toList();
            }

            function moveResponseDown(imsccId, index) {
                scope.responseMap[imsccId].number = scope.responseMap[imsccId].number + 1;
                scope.responseMap[scope.allResponses[index + 1].imsccId].number =
                    scope.responseMap[scope.allResponses[index + 1].imsccId].number - 1;
                toList();
            }

            function addResponse() {
                var newResponse = null;

                if (scope.questionType === 'simpleResponse') {
                    if (scope.type === 'numerical_question') {
                        var newUpperResponse = {
                            type: scope.questionType,
                            imsccId: 'upper',
                            isCorrect: false,
                            number: Object.keys(scope.responseMap).length + 3
                        };
                        var newCorrectResponse = {
                            type: scope.questionType,
                            imsccId: 'correct',
                            isCorrect: true,
                            number: Object.keys(scope.responseMap).length + 1
                        };
                        var newLowerResponse = {
                            type: scope.questionType,
                            imsccId: 'lower',
                            isCorrect: false,
                            number: Object.keys(scope.responseMap).length + 2
                        };
                        scope.responseMap[newUpperResponse.imsccId] = newUpperResponse;
                        scope.responseMap[newCorrectResponse.imsccId] = newCorrectResponse;
                        scope.responseMap[newLowerResponse.imsccId] = newLowerResponse;
                    } else if (scope.type === 'true_false_question') {
                        var newResponseYes = {
                            type: scope.questionType,
                            imsccId: uuid4.generate(),
                            isCorrect: true,
                            text: "Да",
                            number: Object.keys(scope.responseMap).length + 1
                        };
                        var newResponseNo = {
                            type: scope.questionType,
                            imsccId: uuid4.generate(),
                            isCorrect: false,
                            text: "Нет",
                            number: Object.keys(scope.responseMap).length + 2
                        };
                        scope.responseMap[newResponseYes.imsccId] = newResponseYes;
                        scope.responseMap[newResponseNo.imsccId] = newResponseNo;
                    }
                    else {
                        newResponse = {
                            type: scope.questionType,
                            imsccId: uuid4.generate(),
                            number: Object.keys(scope.responseMap).length + 1
                        };
                        newResponse.isCorrect = objectLength(scope.responseMap) === 0;
                        scope.responseMap[newResponse.imsccId] = newResponse;
                    }
                } else if (scope.questionType === 'multipleDropdownResponse') {
                    newResponse = {
                        type: scope.questionType,
                        imsccId: uuid4.generate(),
                        items: {},
                        number: Object.keys(scope.responseMap).length + 1
                    };
                    scope.responseMap[newResponse.imsccId] = newResponse;

                } else if (scope.questionType === 'matchingResponse') {
                    newResponse = {
                        type: scope.questionType,
                        imsccId: uuid4.generate(),
                        number: Object.keys(scope.responseMap).length + 1
                    };
                    scope.responseMap[newResponse.imsccId] = newResponse;
                }
                toList();
            }

            function moveDropdownItemUp(responseImsccId, itemImsccId, index, indexParent) {
                scope.responseMap[responseImsccId].items[itemImsccId].number =
                    scope.responseMap[responseImsccId].items[itemImsccId].number - 1;
                var imsccId = scope.allResponses[indexParent].listMultiDropItems[index - 1].imsccId;
                scope.responseMap[responseImsccId].items[imsccId].number =
                    scope.responseMap[responseImsccId].items[imsccId].number + 1;
                toList();
            }

            function moveDropdownItemDown(responseImsccId, itemImsccId, index, indexParent) {
                scope.responseMap[responseImsccId].items[itemImsccId].number =
                    scope.responseMap[responseImsccId].items[itemImsccId].number + 1;
                var imsccId = scope.allResponses[indexParent].listMultiDropItems[index + 1].imsccId;
                scope.responseMap[responseImsccId].items[imsccId].number =
                    scope.responseMap[responseImsccId].items[imsccId].number - 1;
                toList();
            }

            function removeDropdownItem(responseImsccId, itemImsccId) {
                var moveCorrect = false;
                if (scope.responseMap[responseImsccId].items[itemImsccId].isCorrect === true) {
                    moveCorrect = true;
                }
                delete scope.responseMap[responseImsccId].items[itemImsccId];
                if (moveCorrect) {
                    for (var itemId in scope.responseMap[responseImsccId].items) {
                        scope.responseMap[responseImsccId].items[itemId].isCorrect = true;
                        break;
                    }
                }
                toList();
            }

            function addDropdownItem(responseImsccId) {
                var uuid = uuid4.generate();
                var correct = objectLength(scope.responseMap[responseImsccId].items) == 0;
                scope.responseMap[responseImsccId].items[uuid] = {
                    imsccId: uuid,
                    isCorrect: correct,
                    number: Object.keys(scope.responseMap[responseImsccId].items).length + 1
                };
                toList();
            }

            function removeBlankItem(responseImsccId, itemImsccId) {
                delete scope.responseMap[responseImsccId].items[itemImsccId];
            }

            function addBlankItem(responseImsccId) {
                var uuid = uuid4.generate();
                scope.responseMap[responseImsccId].items[uuid] = {
                    imsccId: uuid,
                    isCorrect: true,
                    number: Object.keys(scope.responseMap[responseImsccId].items).length + 1
                };
                toList();
            }

            function filteredMatchedAnswers(key) {
                var array = [];
                for (var i in scope.responseMap) {
                    if (!scope.responseMap[i].correctImsccId && i !== key) {
                        array.push(scope.responseMap[i]);
                    }
                }
                return array;
            }

            function selectResponse(key) {
                for (var i in scope.responseMap) {
                    scope.responseMap[i].isCorrect = false;
                }
                scope.responseMap[key].isCorrect = true;
                toList();
            }

            function selectItemForResponse(responseKey, itemKey) {
                for (var i in scope.responseMap[responseKey].items) {
                    scope.responseMap[responseKey].items[i].isCorrect = false;
                }
                scope.responseMap[responseKey].items[itemKey].isCorrect = true;
                toList();
            }

            scope.$watch('questionIndex', function (newValue, oldValue) {
                init();
            }, true);

            scope.$watch('responseMap', function (newValue, oldValue) {
                if (scope.type === 'matching_question' && objectLength(newValue) === objectLength(oldValue)) {
                    var detachedIds = [];
                    for (var imsccId in scope.responseMap) {
                        if (newValue[imsccId].correctImsccId !== null && oldValue[imsccId].correctImsccId === null) {
                            detachedIds.push(newValue[imsccId].imsccId);
                        }
                    }
                    for (var k in scope.responseMap) {
                        if (detachedIds.indexOf(scope.responseMap[k].correctImsccId) > -1) {
                            scope.responseMap[k].correctImsccId = null;
                        }
                    }
                } else if (scope.type === 'numerical_question') {
                    if (objectLength(scope.responseMap) !== 0 && (!scope.responseMap.upper || scope.responseMap.upper === null)) {
                        scope.responseMap.upper = {
                            type: 'simpleResponse',
                            imsccId: 'upper',
                            isCorrect: false,
                            number: Object.keys(scope.responseMap).length + 3
                        };
                    }
                    if (objectLength(scope.responseMap) !== 0 && (!scope.responseMap.lower || scope.responseMap.lower === null)) {
                        scope.responseMap.lower = {
                            type: 'simpleResponse',
                            imsccId: 'lower',
                            isCorrect: false,
                            number: Object.keys(scope.responseMap).length + 2
                        };
                    }
                }
            }, true);

            function objectLength(obj) {
                var element_count = 0;
                for (var e in obj) {
                    if (obj.hasOwnProperty(e)) {
                        element_count++;
                    }
                }
                return element_count;
            }

            String.prototype.lpad = function (padString, length) {
                var str = this;
                while (str.length < length) {
                    str = padString + str;
                }
                return str;
            };
            }
    };
});
