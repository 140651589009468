'use strict';

angular.module('lmsApp').controller('CourseRubricsController', function ($scope, $rootScope, $stateParams, Course, Rubric, RubricManager, FilesService) {
    var toDelete = null;

    $scope.course = {};
    $scope.courseRubrics = [];
    $scope.pagination = {
            page: 1,
            total: 0,
            perPage: 20
        };

    $scope.sorting = {
        fields: ['name'],
        direction: 'asc'
    };
    $scope.pagination = {
            page: 1,
            total: 0,
            perPage: 20
        };

    $scope.load = function (id) {
        $scope.courseId = id;
        Course.get({
            id: id,
            expand: 'topics'
        }, function (result) {
            $scope.courseRubrics = [];
            $scope.course = result;
            Course.getRubrics({
                id: id,
                page: $scope.pagination.page,
                per_page: $scope.pagination.perPage,
                sorting_fields : $scope.sorting.fields,
                sorting_direction : $scope.sorting.direction
            }, function (rubrics, headers) {
                $scope.courseRubrics = rubrics;
                $scope.pagination.total = headers('X-Total-Count');
            angular.forEach($scope.course.topics, function (topic) {
                angular.forEach(topic.lessons, function (lesson) {
                    angular.forEach(lesson.rubrics, function (rubric) {
                            angular.forEach($scope.courseRubrics, function(curRubric) {
                                if(curRubric.id === rubric.id) {
                                    curRubric.topic = {
                            id: topic.id,
                            name: topic.name
                        };
                                    curRubric.lesson = {
                            id: lesson.id,
                            name: lesson.name
                        };
                                }
                    });
                });
            });
        });
            });
        });
    };

    $scope.createRubric = function () {
        RubricManager.openRubric($scope.course.id, null, $scope.course.topics);
    };

    $scope.confirmDelete = function (rubric) {
        toDelete = rubric;
        $('#deleteConfirmation').modal('show');
    };

    $scope.delete = function () {
        Rubric.delete({
            id: toDelete.id
        }, function () {
            if (toDelete.path) {
                FilesService.deleteFile({
                    id: $scope.course.id,
                    path: toDelete.path
                });
            }
            for (var i = 0; i < $scope.courseRubrics.length; i++) {
                if ($scope.courseRubrics[i].id === toDelete.id) {
                    $scope.courseRubrics.splice(i, 1);
                    break;
                }
            }
            toDelete = null;
            $('#deleteConfirmation').modal('hide');
        });
    };

    $scope.editRubric = function (index) {
        RubricManager.openRubric($scope.course.id, $scope.courseRubrics[index], $scope.course.topics);
    };

	$scope.changePerPageCount = function (number) {
        $scope.pagination.perPage = number;
        $scope.pagination.page = 1;
        $scope.load($stateParams.id);
    };

    $scope.sortRubricsList = function(fields) {
        if(fields && fields.length > 0) {
            if (fields[0] === $scope.sorting.fields[0]) {
                $scope.sorting.direction = ($scope.sorting.direction === 'asc') ? 'desc' : 'asc';
            } else {
                $scope.sorting.fields = fields;
                $scope.sorting.direction = 'asc';
            }
            $scope.load($stateParams.id);
        }
    };

    $scope.load($stateParams.id);

    $rootScope.$on('rubricFileSaved', function () {
        $scope.load($stateParams.id);
    });
});
