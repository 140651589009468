'use strict';

angular.module('lmsApp')
    .controller('MainController', function ($scope, $state, Principal) {
        $scope.loading = true;

        Principal.identity().then(function(account) {
            if(!account){
                $state.go('login');
            } else {
                $scope.loading = false;
            }
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;
        });
    });
