'use strict';

angular.module('lmsApp')
    .controller('DynamicSyllabusController', function ($scope, $rootScope, $stateParams, $translate, Course, LessonProgress, Principal, Topic, TopicResource, LessonResource, LessonPageResource, Lesson, LessonPage, FilesManager) {

        $scope.course = $rootScope.currentCourse;
        $scope.selectedTopic = null;
        $scope.selectedLesson = null;
        $scope.selectedTask = null;
        $scope.selectedLessonHeading = '';
        $scope.selectedPath = '';
        $scope.studentId = null;
        $scope.modal = {};
        $scope.modal.projectTaskName = ''; // Если это сборник исследовательских заданий и категория задания - "Проектное занятие", то у него есть наименование
        $scope.preventDouble = false;
        $scope.PROJECT_CATEGORIES = { 
            projectTask: 'Проектное задание',
            projectLikeTasks: 'Задания проектного характера',
            researchLikeTasks: 'Задания исследовательского характера'
        };
        $scope.load = function (id) {
            // don't load the course, if it's already in scope
            if (!$scope.course || $scope.course.id !== id) {
                Course.get({id: id, expand: 'topics'}, function (result) {
                    $scope.course = result;         
                });
            }

            // don't load topics, if they are already in scope
            //if ($scope.topics && $scope.topics.length > 0) return;
            $scope.topics = Course.getDynamicSyllabus({id: id, expand: 'lessons'},
                function (result) {
                    $scope.topics = result;
                }, function (error) {
                    $scope.error = error.data.message;
                }
            );

            $scope.lessons = Course.getDynamicSyllabus({id: id, expand: 'lessons'},
                function (result) {
                    $scope.topics = result;
                }, function (error) {
                    $scope.error = error.data.message;
                }
            );

            Principal.identity().then(function (account) {
                $scope.studentId = account.id;
            });
        };

        $scope.selectLessonPage = function (page, lesson, topic) {
            $scope.selectedTopic = topic;
            $scope.selectedLesson = lesson;
            $scope.selectedLessonHeading = lesson.name;
            $scope.selectedPath = page.path;
        };

        $scope.selectTaskPage = function (topic, taskPath) {
            $scope.selectedTopic = topic;
            $scope.selectedPath = taskPath;
            $scope.selectedTask = 'task';
        };

        $scope.addAttempt = function () {
            LessonProgress.addLessonAttempt({lessonId: $scope.lessonForAddAttempt.id, studentId: $scope.studentId});
            $('#addAttemptDialog').modal('hide');
        };

        $scope.showAddAttemptDialog = function (lesson) {
            $scope.lessonForAddAttempt = lesson;
            $('#addAttemptDialog').modal('show');
        };


        $scope.load($stateParams.id);

        angular.element('#courseContentFrame').on('load', updateLessonHeading);


        /**
         * Performs update of iframe heading when content inside the iframe has changed, e.g. user clicked a link.
         *
         * @this Element
         * @returns {undefined}
         */
        function updateLessonHeading() {
            /*jshint validthis:true */
            var path = this.contentWindow.location.pathname || this.contentWindow.location.href;
            var filteredLessons = $scope.selectedTopic.lessons.filter(function (lesson) {
                for (var i in lesson.lessonPages) {
                    if (path.indexOf(lesson.lessonPages[i].path) > -1) {
                        return true;
                    }
                }
                return false;
            });

            if (filteredLessons.length > 0) {
                // the page in the iframe belongs to selected topic
                $scope.selectedLesson = filteredLessons[0];
                $scope.selectedLessonHeading = filteredLessons[0].name;
                // reset path, so selectLessonPage() won't stumble if a user click the same link again
                $scope.selectedPath = '';
            } else {
                $scope.selectedLessonHeading = '';
            }

            $scope.$apply();
        }

        //Functions for modals
        $scope.save = function () {
            var lesson = null;
            var lessonPage = null;
            if(!$scope.preventDouble) {
                $scope.preventDouble = true;
                switch ($scope.modal.type) {
                    case 'topic':
                        $scope.topic = {
                            course: $scope.course
                        };
                        if ($scope.modal.id) {
                            $scope.topic.id = $scope.modal.id;
                            $scope.topic.name = $scope.modal.name;
                            $scope.topic.number = $scope.modal.number;
                            $scope.topic.folder = $scope.modal.folder;
                            $scope.topic.subType = +$scope.modal.subType;
                            Topic.update($scope.topic, function () {
                                $scope.load($stateParams.id);
                                $('#saveTopic').modal('hide');
                                $scope.preventDouble = false;
                            });
                        } else {
                            $scope.topic.name = $scope.modal.name;
                            $scope.topic.number = $scope.modal.number;
                            $scope.topic.folder = $scope.modal.folder;
                            $scope.topic.subType = +$scope.modal.subType;
                            Topic.add($scope.topic, function (response) {
                                $scope.topics.push(response);
                                $('#saveTopic').modal('hide');
                                $scope.preventDouble = false;
                            });
                        }
                        break;
                    case 'lesson':
                        if ($scope.modal.id) {
                            lesson = {
                                id: $scope.modal.id,
                                name: $scope.modal.name,
                                number: $scope.modal.number,
                                topic: {
                                    id: $scope.modal.parentData.id
                                }
                            };
                            Lesson.update(lesson, function () {
                                $scope.load($scope.course.id);
                                $('#saveTopic').modal('hide');
                                $scope.preventDouble = false;
                            });
                        } else {
                            lesson = {
                                // Если категория заданий - "Проектное задание", то добавить индивидуальное наименование, если остальные два - то просто имя категории
                                name: $scope.modal.name === $scope.PROJECT_CATEGORIES.projectTask ? $scope.modal.name + '. ' + $scope.modal.projectTaskName : $scope.modal.name,
                                number: $scope.modal.number,
                                topic: {
                                    id: $scope.modal.parentData.data.id
                                }
                            };
                            Lesson.add(lesson, function (response) {
                                var idx = $scope.topics.indexOf($scope.modal.parentData.data);
                                if (!$scope.topics[idx].lessons ||
                                    $scope.topics[idx].lessons === void 0) {
                                    $scope.topics[idx].lessons = [];
                                }
                                $scope.topics[idx].lessons.push(response);
                                $('#saveTopic').modal('hide');
                                $scope.preventDouble = false;
                            });
                        }
                        break;
                    case 'lessonPage':
                        if ($scope.modal.id) {
                            lessonPage = {
                                id: $scope.modal.id,
                                path: $scope.modal.name.substr(0, $scope.modal.name.lastIndexOf('/')+1)+$scope.modal.page,
                                pageNumber: $scope.modal.number,
                                lesson: {
                                    id: $scope.modal.parentData.id
                                }
                            };
                            LessonPage.update(lessonPage, function () {
                                $scope.load($scope.course.id);
                                $('#saveTopic').modal('hide');
                                $scope.preventDouble = false;
                            });
                        }
                }
            }
        };

        $scope.delete = function () {
            switch ($scope.modal.type) {
                case 'topic':
                    deleteTopic($scope.modal.data);
                    break;
                case 'lesson':
                    deleteLesson($scope.modal.data);
                    /*Lesson.delete({id: $scope.modal.data.id}, function () {
                        var topicIdx = $scope.topics.indexOf($scope.modal.p),
                            lessonIdx = $scope.topics[topicIdx].lessons.indexOf($scope.modal.data);
                        $scope.topics[topicIdx].lessons.splice(lessonIdx, 1);
                        $('#delete-modal').modal('hide');
                    });*/
                    break;
                case 'lessonPage':
                    deleteLessonPage($scope.modal.data);
                    break;
            }
        };

        $scope.ResourceDeleteError = '';
        function deleteTopic(data) {
            $scope.ResourceDeleteError = '';
            TopicResource
                .delete({id: data.id}).$promise
                .then(function(res) {
                    var idx = $scope.topics.indexOf(data);
                    $scope.topics.splice(idx, 1);
                    $('#delete-modal').modal('hide');
                })
                .catch(function(res) {
                    if (res.status === 404) {
                        $translate('lmsApp.course.topic.deleteModal.error404').then(function (translatedValue) {
                            $scope.ResourceDeleteError = translatedValue;
                        });
                    }
                    if (res.status === 403) {
                        $translate('lmsApp.course.topic.deleteModal.error403').then(function (translatedValue) {
                            $scope.ResourceDeleteError = translatedValue;
                        });
                    }
                });
        }

        function deleteLesson(data) {
            $scope.ResourceDeleteError = '';
            LessonResource
                .delete({id: data.id}).$promise
                .then(function(res) {
                    var topicIdx, lessonIdx;
                    topicIdx = $scope.topics.indexOf($scope.modal.p);
                    lessonIdx = $scope.topics[topicIdx].lessons.indexOf($scope.modal.data);
                    $scope.topics[topicIdx].lessons.splice(lessonIdx, 1);
                    $('#delete-modal').modal('hide');
                })
                .catch(function(res) {
                    if (res.status === 404) {
                        $translate('lmsApp.course.lesson.deleteModal.error404').then(function (translatedValue) {
                            $scope.ResourceDeleteError = translatedValue;
                        });
                    }
                    if (res.status === 403) {
                        $translate('lmsApp.course.lesson.deleteModal.error403').then(function (translatedValue) {
                            $scope.ResourceDeleteError = translatedValue;
                        });
                    }
                });
        }

        function deleteLessonPage(data) {
            $scope.ResourceDeleteError = '';
            LessonPageResource
                .delete({id: data.id}).$promise
                .then(function(res) {
                    for (var i = 0; i < $scope.topics.length; i++) {
                        var idx = $scope.topics[i].lessons.indexOf($scope.modal.p);
                        if (idx !== -1) {
                            var lessonPageIdx = $scope.topics[i].lessons[idx].lessonPages.indexOf(data);
                            $scope.topics[i].lessons[idx].lessonPages.splice(lessonPageIdx, 1);
                        }
                    }
                    $('#delete-modal').modal('hide');
                })
                .catch(function(res) {
                    if (res.status === 404) {
                        $translate('lmsApp.course.lessonpage.deleteModal.error404').then(function (translatedValue) {
                            $scope.ResourceDeleteError = translatedValue;
                        });
                    }
                    if (res.status === 403) {
                        $translate('lmsApp.course.lessonpage.deleteModal.error403').then(function (translatedValue) {
                            $scope.ResourceDeleteError = translatedValue;
                        });
                    }
                });
        }

        // Topics
        $scope.addTopic = function () {
            $scope.topic = {};
            $scope.modal = {
                title: $scope.course.courseType.id === 1 ? 'занятиe' : 'тему', 
                type: 'topic',
                isTopic: true,
                folder: '',
                subType: false,
                number: $scope.topics.length + 1,
                parentData: {
                    type: $scope.course.courseType.id === 1 ? 'Курс' : 'Сборник',
                    name: $scope.course.name
                }
            };
        };

        $scope.editTopic = function (topic) {
            $scope.modal = {
                type: 'topic',
                title: $scope.course.courseType.id === 1 ? 'занятиe' : 'тему',
                id: topic.id,
                name: topic.name,
                number: topic.number,
                isTopic: true,
                subType: Boolean(topic.subType),
                folder: topic.folder,
                parentData: {
                    type: $scope.course.courseType.id === 1 ? 'Курс' : 'Сборник',
                    name: $scope.course.name,
                    id: topic.id
                }
            };
        };

        // Lessons
        $scope.addLesson = function (topic) {
            var num = topic.lessons ? topic.lessons.length + 1 : 1;
            $scope.modal = {
                type: 'lesson',
                title:  $scope.course.courseType.id === 1 ? 'урок' : 'категорию заданий',
                isTopic: false,
                number: num,
                parentData: {
                    type: $scope.course.courseType.id === 1 ? 'Занятие' : 'Тема',
                    name: topic.name,
                    data: topic
                }
            };
        };

        $scope.editLesson = function (lesson, topic) {
            $scope.modal = {
                type: 'lesson',
                title: $scope.course.courseType.id === 1 ? 'урок' : 'категорию заданий',
                id: lesson.id,
                isTopic: false,
                name: lesson.name,
                number: lesson.number,
                parentData: {
                    type: $scope.course.courseType.id === 1 ? 'Занятие' : 'Тема',
                    name: topic.name,
                    id: topic.id
                }
            };
        };

        //Lesson pages
        $scope.addLessonPage = function (parentLesson) {
            parentLesson.lessonPages =  parentLesson.lessonPages ?  parentLesson.lessonPages : [];
            var _lesson = {
                pageNumber: parentLesson.lessonPages.length + 1,
                lesson: {
                    id: parentLesson.id
                }
            };
            LessonPage.add(_lesson, function (response) {
                parentLesson.lessonPages.push(response)
            });
        };

        $scope.editLessonPage = function (lessonPage, lesson) {
            $scope.modal = {
                specialField: 'URL',
                type: 'lessonPage',
                isTopic: false,
                title: 'страницу урока',
                id: lessonPage.id,
                name: lessonPage.path,
                number: lessonPage.pageNumber,
                page: lessonPage.path.substr(lessonPage.path.lastIndexOf('/') + 1, lessonPage.path.length),
                parentData: {
                    type: 'Урок',
                    name: lesson.name,
                    id: lesson.id
                }
            };
        };

        $scope.editLessonPageHtml = function (courseId, pagePath) {
            if (courseId && pagePath) {
                // FilesService.editText({id: courseId, path: pagePath}, $scope.raw_html);
                FilesManager.openFile(courseId, pagePath, {windowClass: 'edit-lesson-page'});
            }
        };

        $scope.move = function (type, obj, up, parent) {
            var mod = up ? -1 : 1;

            switch (type) {
                case 'topic':
                    obj.number = obj.number + mod;
                    obj.course = $scope.course;
                    Topic.update(obj, function () {
                        $scope.load($stateParams.id);
                    });
                    break;
                case 'lesson':
                    obj.number = obj.number + mod;
                    obj.topic = {
                        id: parent.id
                    };
                    Lesson.update(obj, function () {
                        $scope.load($scope.course.id);
                    });
                    break;
                case 'lessonPage':
                    obj.pageNumber = obj.pageNumber + mod;
                    obj.lesson = {
                        id: parent.id
                    };
                    LessonPage.update(obj, function () {
                        $scope.load($scope.course.id);
                    });
                    break;
            }
        };

        $scope.deleteEntity = function (type, entity, parent) {
            $scope.ResourceDeleteError = '';
            $scope.modal = {
                type: type,
                data: entity,
                p: parent ? parent : null
            };
        };
    });
