'use strict';

angular.module('lmsApp')
    .controller('NavbarController', function ($scope, $window, $location, $state, $translate, Auth, Principal, Cruduser, UserSettings, innopolis, innopolis_stage) {

        $scope.$state = $state;
        $scope.userRoles = [];
        $scope.userSetting = {};
        $scope.currentRoleOnRussian = '';

        $scope.getRoleView = function (roles, currentRole) {
            for (var i = 0; i < roles.length; i++) {
                if (roles[i] !== currentRole) {
                    var role = {};
                    role.roleName = roles[i];
                    role.roleOnRussian = $translate.instant('lmsApp.cruduser.roles.' + roles[i]);
                    $scope.userRoles.push(role);
                }
            }
            $scope.currentRoleOnRussian = $translate.instant('lmsApp.cruduser.roles.' + currentRole);
            return $scope.userRoles;
        };

        $scope.refreshAccountData = function () {
            Principal.identity().then(function (account) {
                $scope.account = account;
                $scope.account.currentRole = $scope.account.userRoles[0];
                $scope.account.rolesView = $scope.getRoleView(account.userRoles, account.currentRole);
                $scope.isAuthenticated = Principal.isAuthenticated;
            });
        };

        $scope.refreshAccountData();

        $scope.logout = function () {
            Auth.logout();
            if (innopolis) {
                window.location.href = "https://sso.mob-edu.ru/";
            }
            else if (innopolis_stage) {
                window.location.href = "https://sso-stage.mob-edu.ru/";
            }
            else {
                $state.go('home', {}, {'reload': true});
            }
        };

        $scope.update = function () {
            UserSettings.get({id: $scope.account.id}, function (result) {
                result.currentRole = $scope.role.roleName;
                UserSettings.update(result, function () {
                    $window.location.reload();
                });
            });
        };

    });
