'use strict';

angular.module('lmsApp')
    .controller('CourseSectionOpenTaskTableController', function ($rootScope, $scope, $stateParams, CourseSection, AssignmentProgress, RubricProgress, $q, $translate) {

        $scope.courseId = null;
        $scope.courseSection = null;
        $scope.students = [];
        $scope.studentIds = [];

        $scope.assignments = [];
        $scope.dataForGridOpenTask = [];
        $scope.optionsOpenTask = [];
        $scope.columnDefsOpenTask = [];

        $scope.rubrics = [];
        $scope.dataForGridRubrics = [];
        $scope.optionsRubrics = [];
        $scope.columnDefsRubrics = [];

        var headerRowHeight = 130;

        var translateOptions = [
            'lmsApp.courseSection.matrix.*.selectLabelForNone',
            'lmsApp.courseSection.matrix.*.selectLabelForRequire',
            'lmsApp.courseSection.matrix.*.selectLabelForHomeWork'
        ];

        $scope.gridOptionsOpenTask = {
            headerRowHeight: headerRowHeight,
            data: 'dataForGridOpenTask',
            columnDefs: 'columnDefsOpenTask'
        };

        $scope.gridOptionsRubric = {
            headerRowHeight: headerRowHeight,
            data: 'dataForGridRubrics',
            columnDefs: 'columnDefsRubrics'
        };

        $scope.load = function (id) {
            CourseSection.get({id: id}, function (result) {
                init(result);
                var reqObj = {
                    courseId: $scope.courseId,
                    studentIds: $scope.studentIds.join(',')
                };
                $q.all([
                        getAssignmentsFromLesson(reqObj),
                        getAssignmentsFromTopic(reqObj)]
                ).then(function (results) {
                        initGrid(results[0].concat(results[1]), 'assignment');
                    });

                RubricProgress.getAll(reqObj, function (result) {
                    initGrid(result, 'rubric');
                });
            });
        };

        function init(result) {
            $scope.courseSection = result;
            $scope.courseId = result.course.id;
            result.courseProgress.forEach(function (item) {
                $scope.students.push(item.user);
                $scope.studentIds.push(item.user.id);
            });
        }

        function getAssignmentsFromLesson(reqObj) {
            var deferred = $q.defer();
            AssignmentProgress.getAllByCourseIdAndStudentIdsFromLesson(reqObj, function (res) {
                deferred.resolve(res);
            });
            return deferred.promise;
        }

        function getAssignmentsFromTopic(reqObj) {
            var deferred = $q.defer();
            AssignmentProgress.getAllByCourseIdAndStudentIdsFromTopic(reqObj, function (res) {
                deferred.resolve(res);
            });
            return deferred.promise;
        }

        function initGrid(result, assignmentOrRubric) {
            sortByField(result, 'studentId');
            if (assignmentOrRubric === 'assignment') {
                $scope.assignments = initAssignmentsOrRubricsArr(result, assignmentOrRubric);
                checkAssignmentArr();
                $scope.dataForGridOpenTask = prepareDataForGrid(result, assignmentOrRubric);
                $scope.optionsOpenTask = getOptionsForSelect('openTaskTable');
                $scope.columnDefsOpenTask = prepareColumnDefsForGrid(assignmentOrRubric);
            } else {
                $scope.rubrics = initAssignmentsOrRubricsArr(result, assignmentOrRubric);
                $scope.dataForGridRubrics = prepareDataForGrid(result, assignmentOrRubric);
                $scope.optionsRubrics = getOptionsForSelect('rubrics');
                $scope.columnDefsRubrics = prepareColumnDefsForGrid(assignmentOrRubric);
            }
        }

        function initAssignmentsOrRubricsArr(result, assignmentOrRubric) {
            var assignmentsOrRubrics = [];
            var BreakException = {};
            try {
                /*jshint validthis:true */
                result.forEach(function (item, index, arr) {
                    if (index !== 0 && item.studentId !== arr[index - 1].studentId) {
                        throw BreakException;
                    }
                    assignmentsOrRubrics.push(item[assignmentOrRubric]);
                }, this);
            } catch (e) {
                if (e !== BreakException) {
                    throw e;
                }
            }
            sortByField(assignmentsOrRubrics, 'id');
            return assignmentsOrRubrics;
        }

        function checkAssignmentArr() {
            $scope.assignments.forEach(function (item, index, arr) {
                if (index !== 0 && item.id === arr[index - 1].id) {
                    console.error('Check DB. Maybe one assignment link to lesson and to topic. ' +
                        'It\'s impossible, for correct work');
                    throw Error('DUPLICATE ASSIGNMENTS');
                }
            });
        }

        function getOptionsForSelect(translatePath) {
            var options = [];
            translateOptions.forEach(function (item, index) {
                var selectItem = {value: index, label: $translate.instant(item.replace('*', translatePath))};
                options.push(selectItem);
            });
            return options;
        }

        function getFieldFromArrById(nameField, arr, id) {
            id = parseInt(id, 10);

            for (var i in arr) {
                if (arr[i].id && arr[i].id === id) {
                    return arr[i][nameField];
                }
            }
            return null;
        }

        function getColumnForAssignment(i, arrWithAssignmentOrRubric) {
            // домашние задания может назначать только учитель.
            var disabled = ($rootScope.user.id !== $scope.courseSection.user.id);
            return {
                field: 'progress' + i,
                headerCellTemplate: '<div ng-class="isHover" ' +
                'ng-mouseleave="isHover = \'headCellHoverLeave\'" ng-mouseenter="isHover = \'headCellHoverEnter\'"' +
                ' class="openTaskTableHeader ngHeaderText">' + arrWithAssignmentOrRubric[i].imsccId + '\n' + arrWithAssignmentOrRubric[i].title + '</div>',

                headerClass: 'matrixHeader',
                minWidth: 120,
                cellTemplate: '<select ng-class="{disabled: row.entity.progress' + i + '.isDisabled}" ' +
                (disabled ? 'ng-disabled="1"' : 'ng-disabled="row.entity.progress' + i + '.isDisabled" ') +
                'ng-change="handleAssignmentProgress(row.entity.progress' + i + ')" ' +
                'ng-model="row.entity.progress' + i + '.value" convert-to-number ' +
                'ng-options="item.value as item.label for item in optionsOpenTask" >'
            };
        }

        function getColumnForRubric(i, arrWithAssignmentOrRubric) {
             // домашние задания может назначать только учитель.
            var disabled = ($rootScope.user.id !== $scope.courseSection.user.id);
            return {
                field: 'progress' + i,
                headerCellTemplate: '<div ng-class="isHover" ' +
                'ng-mouseleave="isHover = \'headCellHoverLeave\'" ng-mouseenter="isHover = \'headCellHoverEnter\'"' +
                ' class="openTaskTableHeader ngHeaderText">' + arrWithAssignmentOrRubric[i].id + '\n' + arrWithAssignmentOrRubric[i].name + '</div>',

                headerClass: 'matrixHeader',
                minWidth: 120,
                cellTemplate: '<select ng-change="handleRubricProgress(row.entity.progress' + i + ')" ' +
                (disabled ? 'ng-disabled="1"' : '') +
                'ng-model="row.entity.progress' + i + '.value" convert-to-number ' +
                'ng-options="item.value as item.label for item in optionsRubrics" >'
            };
        }

        $scope.handleAssignmentProgress = function (entity) {
            AssignmentProgress.updateRequireAndHomeAssignmentById(calculateOutputObject(entity));
        };

        $scope.handleRubricProgress = function (entity) {
            RubricProgress.updateRequireAndHomeAssignmentById(calculateOutputObject(entity));
        };

        function prepareColumnDefsForGrid(assignmentOrRubric) {
            var arrWithAssignmentOrRubric = $scope[assignmentOrRubric + 's'];
            var countFieldsWithAssignmentProgress = arrWithAssignmentOrRubric.length;
            var columnDefs = [
                {
                    field: 'name',
                    displayName: $translate.instant('lmsApp.courseSection.userLogin'),
                    headerClass: 'openTaskTableHeader',
                    minWidth: 150
                }
            ];
            for (var i = 0; i < countFieldsWithAssignmentProgress; i++) {
                var column = (assignmentOrRubric === 'assignment') ?
                    getColumnForAssignment(i, arrWithAssignmentOrRubric, assignmentOrRubric) :
                    getColumnForRubric(i, arrWithAssignmentOrRubric, assignmentOrRubric);
                columnDefs.push(column);
            }
            return columnDefs;
        }

        function sortByField(result, field) {
            result.sort(function (a, b) {
                return a[field] - b[field];
            });
        }

        function getAssignmentProgressOrRubricProgress(studentId, assignmentId, result, assignmentOrRubric) {
            for (var i in result) {
                var item = result[i];
                if (item.studentId === studentId && item[assignmentOrRubric].id === assignmentId) {
                    return item;
                }
            }
        }

        function initCellWithProgress(row, i, entity, assignmentOrRubric) {
            row['progress' + i] = {
                id: entity.id,
                value: calculateInputValueAndDisabled(entity, assignmentOrRubric),
                isDisabled: entity.isDisabled
            };
            if (assignmentOrRubric === 'rubric') {
                delete row['progress' + i].isDisabled;
            }
        }

        function prepareRowForStudent(result, n, countAssignmentsOrRubrics, arrWithAssignmentOrRubric, assignmentOrRubric, dataForGrid) {
            var studentId = result[n * countAssignmentsOrRubrics].studentId;
            var row = {
                name: getFieldFromArrById('login', $scope.students, studentId)
            };
            for (var i = 0; i < countAssignmentsOrRubrics; i++) {
                var assignmentId = arrWithAssignmentOrRubric[i].id;
                var ap = getAssignmentProgressOrRubricProgress(
                    studentId, assignmentId, result, assignmentOrRubric);
                initCellWithProgress(row, i, ap, assignmentOrRubric);
            }
            dataForGrid.push(row);
        }

        function prepareDataForGrid(result, assignmentOrRubric) {
            var arrWithAssignmentOrRubric = $scope[assignmentOrRubric + 's'];
            var dataForGrid = [];
            var countAssignmentsOrRubrics = arrWithAssignmentOrRubric.length;
            var countRow = result.length / countAssignmentsOrRubrics;
            for (var n = 0; n < countRow; n++) {
                prepareRowForStudent(result, n, countAssignmentsOrRubrics, arrWithAssignmentOrRubric, assignmentOrRubric, dataForGrid);
            }
            return dataForGrid;
        }

        function calculateOutputObject(entity) {
            var output = {
                id: entity.id,
                require: false,
                home: false
            };
            if (entity.value !== 0) {
                output.require = true;
            }
            if (entity.value === 2) {
                output.home = true;
            }
            return output;
        }

        function calculateInputValueAndDisabled(progress, assignmentOrRubric) {
            var flag = (assignmentOrRubric === 'assignment'); // this flag for assignmentProgress
            if (flag) {
                progress.isDisabled = false; // disabled only assignmentProgress
            }
            if (progress.required === false) {
                return 0;
            }
            //only assignment progress maybe have property 'existTopicProgress' and 'assignment.keyQuestion'
            if (flag && (progress.existTopicProgress === true || progress.assignment.keyQuestion === true)) {
                progress.isDisabled = true;
                return 1; // and disabled
            }
            if (progress.required === true && progress.isHomeAssignment === false) {
                return 1;
            }
            if (progress.required === true && progress.isHomeAssignment === true) {
                return 2;
            }
        }

        $scope.load($stateParams.id);
    });