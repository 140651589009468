'use strict';

angular.module('lmsApp')
    .controller('StudentsForParentSectionDetailController', function ($scope, $stateParams, CourseSection, Course, User) {
        $scope.studentId = '';
        $scope.courseSection = {};
        $scope.userList = []; // todo: refactor this part if user number increase, possible memory leak
        $scope.newCourseProgressId = '';
        $scope.removedCourseProgressId = '';
        $scope.roleStudent = 'ROLE_STUDENT';

        $scope.load = function (studentId, id) {
            CourseSection.get({id: id}, function (result) {
                $scope.courseSection = result;
            });
            $scope.studentId = studentId;
        };


        $scope.addCourseProgress = function () {
            var obj = {};
            //obj.user = getUserById($scope.newCourseProgressId);
            $scope.courseSection.courseProgress.push(obj);

            CourseSection.update($scope.courseSection,
                function (data) {
                    $scope.courseSection = data;
                    $('#addModal').modal('hide');
                    $scope.load($stateParams.id);
                    $scope.clear();
                }
            );
        };

        $scope.deleteCourseProgress = function () {
            //removeCourseProgressById($scope.removedCourseProgressId);

            CourseSection.update($scope.courseSection,
                function (data) {
                    $scope.courseSection = data;
                    $('#deleteModal').modal('hide');
                    $scope.load($stateParams.id);
                    $scope.clear();
                }
            );
        };

        $scope.load($stateParams.studentId, $stateParams.id);
    });
