'use strict';

angular.module('lmsApp').controller('TerritoryController', ['$scope', 'Territory', 'Locations', function ($scope, Territory, Locations) {
    $scope.territories = [];
    $scope.regions = [];
    $scope.territoryError = '';
    $scope.pagination = {
        page: 1,
        total: 0,
        perPage: 20
    };
    $scope.paginationVisible = false;
    $scope.regionsOptions = {
        'page': 1,
        'per_page': 10,
        'sorting_fields': ['id'],
        'sorting_direction': 'asc',
        'type': 'REGION'
    };

    $scope.loadAll = function () {
        Locations.query({
            page: $scope.pagination.page,
            per_page: $scope.pagination.perPage,
            type: 'TERRITORY'
        }, function (result, headers) {
            $scope.territories = result;
            $scope.pagination.total = headers('X-Total-Count');
            $scope.paginationVisible = ($scope.pagination.perPage < $scope.pagination.total) ? true : false;
        });
        $scope.getRegions();
    };

    $scope.changePerPageCount = function (number) {
        $scope.pagination.perPage = number;
        $scope.pagination.page = 1;
        $scope.loadAll();
    };

    $scope.saveTerritory = function() {
        var locationId = $scope.territory.id,
            parentLocation = $scope.territory.region,
            locationFn = !!locationId ? 'editLocation' : 'addLocation',
            territoryParams = {
                name:  $scope.territory.name,
                type: 'TERRITORY'
            }

        !!locationId && _.assign(territoryParams, {id: locationId});
        !!parentLocation && _.assign(territoryParams, {parentId: parentLocation.id});

        Locations[locationFn](territoryParams, function() {
            $scope.loadAll();
            $('#saveTerritoryModal').modal('hide');
            $scope.clear();
        });
    };

    $scope.create = function () {
        var parentLocation = $scope.territory.region,
            newЕerritory = {
                name:  $scope.territory.name,
                type: 'TERRITORY'
            }

        !!parentLocation && _.assign(newЕerritory, {parentId: parentLocation.id});

        Locations.addLocation(newЕerritory, function() {
            $scope.loadAll();
            $('#saveTerritoryModal').modal('hide');
            $scope.clear();
        });
    };

    $scope.update = function (id) {
        Territory.get({
            id: id
        }, function (result) {
            $scope.territory = result;
            $('#saveTerritoryModal').modal('show');
        });
    };

    $scope.delete = function (id) {
        Territory.get({
            id: id
        }, function (result) {
            $scope.territory = result;
            $('#deleteTerritoryConfirmation').modal('show');
        });
    };

    $scope.confirmDelete = function (id) {
        Territory.delete({
                id: id
            },
            function () {
                $scope.loadAll();
                $('#deleteTerritoryConfirmation').modal('hide');
                $scope.clear();
            },
            function (response) {
                $scope.territoryError = response.headers('Failure');
            });

    };

    $scope.clear = function () {
        $scope.territory = {
            name: null,
            region: null,
            id: null
        };
        $scope.territoryError = '';
        $scope.editForm.$setPristine();
        $scope.editForm.$setUntouched();
    };

    $scope.getRegions = function(params) {
        var loadOptions = _.assign($scope.regionsOptions, params);
        Locations.query(loadOptions, function (result, headers) {
            $scope.regions = {
                'items': result,
                'total': headers('X-Total-Count'),
            };
        });
    };

    $scope.loadAll();
    }]);
