'use strict';

angular.module('lmsApp')
    .factory('CourseUploader', function (Upload) {
        return {

            /**
             * Uploads a Common Cartridge associated with the given course.
             *
             * @param {File} cartridge the cartridge to upload
             * @param {Long} courseId the course id to link the cartridge with or simply to update
             * @param {Object} [actions] the object with methods to perform on progress, success or error
             * @param {Boolean} [override] whether or not course content will be overridden in case there is already linked progresses
             * @returns {undefined}
             */
            upload: function(cartridge, courseId, actions, override) {

                var query = '';
                actions = actions || {};
                if (override) {
                    query += '?override=true';
                }

                Upload.upload({

                    url: 'api/courses/' + courseId + '/upload' + query,
                    file: cartridge

                }).progress(function(evt) {

                    if (actions.progress && typeof actions.progress === 'function') {
                        actions.progress(evt);
                    }

                }).success(function(data, status) {

                    if (status !== 200) {
                        if (actions.error && typeof actions.error === 'function') {
                            actions.error(data, status);
                        }
                    } else {
                        if (actions.success && typeof actions.success === 'function') {
                            actions.success(data, status);
                        }
                    }

                }).error(function (data, status, headers) {
                    if (actions.error && typeof actions.error === 'function') {
                        actions.error(data, status, headers);
                    }
                });
            }
        };
    });
