'use strict';

angular.module('lmsApp')
    .controller('CourseSectionStudentResultsController', function ($scope, $stateParams, CourseSection, Course, QuizProgress, LessonProgress) {

        $scope.courseId = null;
        $scope.courseSection = null;
        $scope.studentId = $stateParams.studentId;
        $scope.quizProgresses = null;
        $scope.maxAttempts = '';
        $scope.attempCoefficients = [1, 0.9, 0.75, 0];

        $scope.load = function (id, studentId) {
            CourseSection.get({id: id}, function (result) {
                $scope.courseSection = result;
                $scope.courseId = result.course.id;
                CourseSection.getStudentQuizProgresses({id: id, studentId: studentId}, function (result) {
                    $scope.quizProgresses = result;
                    for (var i = 0; i < $scope.quizProgresses.length; i++) {
                        var map = {};
                        if ($scope.quizProgresses[i].mark > 0) {
                            $scope.quizProgresses[i].mark = Math.round(Number($scope.quizProgresses[i].mark));
                        }
                        if (!$scope.quizProgresses[i].quizAttempts) {
                            $scope.quizProgresses[i].quizAttempts = [];
                        }

                        for (var k = 0; k < $scope.quizProgresses[i].quizAttempts.length; k++) {
                            $scope.quizProgresses[i].quizAttempts[k].mark = Math.round(Number($scope.quizProgresses[i].quizAttempts[k].mark) * $scope.attempCoefficients[k]);
                            map[$scope.quizProgresses[i].quizAttempts[k].number] = $scope.quizProgresses[i].quizAttempts[k];
                        }
                        $scope.quizProgresses[i].quizAttempts = map;
                    }
                });
            });
            QuizProgress.getMaxAttempts(function (data) {
                $scope.maxAttempts = data.maxAttempts;
            });
        };

        $scope.range = function (n) {
            return new Array(n);
        };

        $scope.load($stateParams.id, $scope.studentId);
    });