'use strict';

angular.module('lmsApp').controller('methodologistCoursesController', function ($scope, Users) {
    $scope.userList = [];
    $scope.roleMethodologist = "ROLE_METHODOLOGIST";
    $scope.searchText = '';
    $scope.sorting = {
        fields: ['id'],
        direction: 'asc'
    };
    $scope.pagination = {
        page: 1,
        total: 0,
        perPage: 20
    };

    $scope.loadAll = function (withSearch) {
        if (!withSearch) {
            $scope.searchText = '';
        }
        Users.getAll({
            role: $scope.roleMethodologist,
            page: $scope.pagination.page,
            per_page: $scope.pagination.perPage,
            sorting_fields: $scope.sorting.fields,
            sorting_direction: $scope.sorting.direction,
            search_text: $scope.searchText
        }, function (result, headers) {
            $scope.pagination.total = headers('X-Total-Count');
            $scope.userList = result;
        })
    };

    $scope.changePerPageCount = function (number) {
        $scope.pagination.perPage = number;
        $scope.pagination.page = 1;
        $scope.loadAll();
    };

    $scope.sort = function (fields) {
        //Меняем порядок сортировки, если выбран тот же столбец
        if (fields[0] == $scope.sorting.fields[0]) {
            if ($scope.sorting.direction == 'asc') {
                $scope.sorting.direction = 'desc';
            } else {
                $scope.sorting.direction = 'asc';
            }
        } else {
            //Меняем столбец сортировки
            $scope.sorting.fields = fields;
            $scope.sorting.direction = 'asc';
        }

        $scope.pagination.page = 1;
        $scope.loadAll();
    };

    $scope.search = function () {
        $scope.pagination.page = 1;
        $scope.loadAll(true);
    };

    $scope.loadAll();
});
