'use strict';

angular.module('lmsApp')
    .controller('CourseGroupDetailController', function ($scope, $stateParams, CourseGroup, Course) {
        $scope.courseGroup = {};
        $scope.load = function (id) {
            CourseGroup.get({id: id}, function (result) {
                $scope.courseGroup = result;
            });
            Course.getAllByCourseGroupId({id: id}, function (result) {
                $scope.courses = result;
            });
        };
        $scope.load($stateParams.id);
    });
