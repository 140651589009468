'use strict';

angular.module('lmsApp')
    .factory('RubricProgress', function ($resource) {
        return $resource('api/rubricProgresses/', {}, {

            getAll: {
                method: 'GET',
                url: 'api/rubricProgresses/course/:courseId',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                },
                isArray: true
            },

            updateRequireAndHomeAssignmentById: {
                method: 'PUT',
                url: '/api/rubricProgresses/:id/require/:require/home/:home',
                params: {
                    id: '@id',
                    require: '@require',
                    home: '@home'
                }
            }
        });
    });
