'use strict';

angular.module('lmsApp')
    .factory('FilesService', function ($resource) {
        return $resource('api/files/course/:id', {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'editFiles': {
                method: 'POST',
                url: 'api/files/course/:id/files'
            },
            'editText': {
                method: 'PUT',
                url: 'api/files/course/:id/files/text',
                params: {
                    path: '@path'
                }
            },
            'deleteFile': {
                method: 'DELETE',
                url: 'api/files/course/:id/files',
                params: {
                    path: '@path'
                }
            },
            'editDirectory': {
                method: 'POST',
                url: 'api/files/course/:id/directories'
            },
            'getTree': {
                method: 'GET',
                url: 'api/files/course/:id/tree',
                isArray: true
            },
            'exists': {
                method: 'GET',
                url: 'api/files/course/:id/exists',
                params: {
                    path: '@path'
                },
                transformResponse: function (data, headers) {
                    var response = {}
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            }
        });
    });
