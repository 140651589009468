'use strict';

angular.module('lmsApp').controller('SchoolController', function ($scope, School, Locations, SchoolNew, $q) {
    $scope.schools = [];
    $scope.territoriesForUpdateForm = [];

    //location.name sorting
    // search_text
    $scope.terOptionsForUpdateForm = {
        'page': 1,
        'per_page': 10,
        'sorting_fields': ['id'],
        'sorting_direction': 'asc'
    }

    $scope.pagination = {
        page: 1,
        total: 0,
        per_page: 20,
        sorting_fields: ['id'],
    };

    $scope.sorting = {
        sorting_fields: 'id',
        search_text: ''
    }

    $scope.learningclasss = [];
    $scope.errors = '';

    $scope.loadAll = function (sorting) {
        var sort = sorting ? sorting : $scope.pagination;
        sort['page'] = $scope.pagination.page;
        sort['per_page'] = $scope.pagination.per_page;
        SchoolNew.getAll(sort, function (result, headers) {
            $scope.schools = result;
            $scope.pagination.total = headers('X-Total-Count');
        });
        sessionStorage.setItem('schoolPage', JSON.stringify(sorting));
    };

    var loadAllRegions = function() {
        var dfd = $q.defer();
        Locations.query({type: 'REGION'}, function (result, headers) {
            $scope.regions = {
                'items': result,
                'total': headers('X-Total-Count'),
            };
            dfd.resolve(result);
        });
        return dfd.promise;
    }

    var loadTerritoriesByRegion = function(regionId) {
        var dfd = $q.defer();
        Locations.children({id: regionId}, function (result) {
            $scope.territories = {
                'items': result
            };
            dfd.resolve(result);
        });
        return dfd.promise;
    }
    

    function setRegionFilter(regions, regionId) {
        var dfd = $q.defer();
        $scope.selectedRegion = regions.find(
            function(region) {
                return region.id === regionId;
            }
        )
        if ($scope.selectedRegion) {
            return loadTerritoriesByRegion(regionId);
        }
        else {
            dfd.reject();
            return dfd.promise;
        }
    }


    function setTerritoryFilter(territories, territoryId) {
        $scope.selectedTerritory = territories.find(
            function(territory) {
                return territory.id === territoryId;
            }
        );
    }

    function init() {
        var savedSortParams = JSON.parse(sessionStorage.getItem('schoolPage'));
        $scope.sorting = savedSortParams ? savedSortParams : $scope.sorting;
        $scope.loadAll($scope.sorting);
        var regionPromise = loadAllRegions();
        if (savedSortParams) {
            regionPromise.then(
                function(regions) {
                    if (savedSortParams.region) setRegionFilter(regions, savedSortParams.region).then(
                        function(territories) {
                            if (savedSortParams.territory) setTerritoryFilter(territories, savedSortParams.territory);
                        }
                    )
                }
            )
        }
    }

    init();

    $scope.changePerPageCount = function (number) {
        $scope.pagination.per_page = number;
        $scope.pagination.page = 1;
        $scope.loadAll($scope.sorting);
    };

    $scope.create = function () {
        var newSchool = {};
        newSchool.locationId = $scope.school.location.id;
        newSchool.name = $scope.school.name;
        newSchool.vkGroupId = $scope.school.vkGroupId > 0 ? $scope.school.vkGroupId : null;
        newSchool.partnerProgram = $scope.school.partnerProgram;
        newSchool.branch = $scope.school.branch;
        newSchool.vendorID = $scope.school.vendorID || null;
        newSchool.inn = $scope.school.inn || null;
        // ------
        if($scope.school.id !== null && $scope.school.id !== undefined){
            newSchool.id = $scope.school.id;
            SchoolNew.update(newSchool,
                function () {
                    $scope.refresh();
                });
        } else {

        SchoolNew.create(newSchool,
            function () {
                $scope.refresh();
            });
        }
    };

    $scope.update = function (id) {
        SchoolNew.getById({
            id: id
        }, function (result) {
            result.vkGroupId = +result.vkGroupId;
            $scope.school = result;
            $('#saveSchoolModal').modal('show');
        });
        $scope.getTerritoriesForUpdateForm();
    };

    $scope.delete = function (id) {
        SchoolNew.getById({
            id: id
        }, function (result) {
            $scope.school = result;
            $('#deleteSchoolConfirmation').modal('show');
        });
    };

    $scope.confirmDelete = function (id) {
        School.delete({
                id: id
            },
            function () {
                $scope.refresh();
                $('#deleteSchoolConfirmation').modal('hide');
            },
            function (response) {
                $scope.errors = response.headers('Failure');
            }
        );
    };

    $scope.refresh = function () {
        $scope.loadAll($scope.sorting);
        $('#saveSchoolModal').modal('hide');
        $scope.clear();
    };

    $scope.clear = function () {
        $scope.errors = '';
        $scope.school = {
            name: null,
            id: null
        };
        $scope.editForm.$setPristine();
        $scope.editForm.$setUntouched();
    };

    $scope.openCreateForm = function () {
        $scope.clear();
        $scope.getTerritoriesForUpdateForm();
    };

    $scope.getTerritoriesForUpdateForm = function (loadOptions) {
        loadOptions = (loadOptions) ? loadOptions : $scope.terOptionsForUpdateForm;
        loadOptions['type'] = 'TERRITORY';
        Locations.query(loadOptions, function (result, headers) {
            $scope.territoriesForUpdateForm = {
                'items': result,
                'total': headers('X-Total-Count'),
            };
        });
    };

    $scope.sort = function (fields) {


        //Меняем порядок сортировки, если выбран тот же столбец
        if (fields === $scope.sorting.sorting_fields) {
            if ($scope.sorting.sorting_direction === 'asc') {
                $scope.sorting.sorting_direction = 'desc';
            } else {
                $scope.sorting.sorting_direction = 'asc';
            }
        } else {
            //Меняем столбец сортировки
            $scope.sorting.sorting_fields = fields;
            $scope.sorting.sorting_direction = 'asc';
        }

        $scope.pagination.page = 1;
        $scope.loadAll($scope.sorting);
    };

    $scope.onRegionChange = function(selectedRegion) {
        if (!selectedRegion) {
            delete $scope.sorting['region'];
            delete $scope.sorting['territory'];
            $scope.territories = {
                items: []
            }
        }
        else {
            delete $scope.sorting['territory'];
            $scope.sorting['region'] = selectedRegion.id;
            loadTerritoriesByRegion(selectedRegion.id);
        }
        $scope.loadAll($scope.sorting);
    };

    $scope.onTerritoryChange = function(selectedTerritory) {
        if (!selectedTerritory) {
            delete $scope.sorting['territory'];
        }
        else {
            $scope.sorting['territory'] = selectedTerritory.id;
            $scope.sorting['region'] = $scope.selectedRegion.id;
        }
        $scope.loadAll($scope.sorting);
    };

});
