'use strict';

angular.module('lmsApp')
    .controller('CourseSectionSubscriptionController', function ($scope, $stateParams, CourseSection, Course, Principal) {

        $scope.account = {};
        $scope.courseSections = null;

        Principal.identity().then(function (account) {
            $scope.account = account;
        });

        CourseSection.getUserSubscriptions({id: $stateParams.id}, function(sections) {
            $scope.courseSections = sections;
        });
    });
