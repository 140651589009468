'user strict';

angular.module('lmsApp')
    .factory('StatisticResource', [
        '$resource',
        function($resource) {
            return $resource('admin/statistic', {}, {
                'getData': {
                    method: 'GET',
                    isArray: false,
                    url: 'admin/statistic/getData'
                },

                'getStatistic': {
                    method: 'GET',               
                    responseType: 'arraybuffer',
                    cache: false,
                    transformResponse: function(data, headersGetter) { 
                        return { 
                            data : data 
                        }
                    }
                }
            });
        }
    ]
)