'use strict';

angular.module('lmsApp')
    .factory('CourseSection', function ($resource) {
        return $resource('api/courseSections/:id', {}, {
            'query': {method: 'GET', isArray: true},

            'getAllSections': {
                method: 'GET',
                url: 'admin/courseSections/getAllCourseSections',
                isArray: true
            },

            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },

            'getAllForUserAndCourse': {
                method: 'GET',
                url: 'api/courseSections/',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getAllByTeacherId': {
                method: 'GET',
                url: 'api/courseSections/teacher/:id',
                isArray: true
            },
            'getAllByUserGroupId': {
                method: 'GET',
                url: 'api/courseSections/userGroup/:id',
                isArray: true
            },
            'getByCourseIdAndUserGroupId': {
                method: 'GET',
                url: 'api/courseSections/course/:courseId/userGroup/:groupId'
            },
            'getAllByTutorId': {
                method: 'GET',
                url: 'api/courseSections/tutor/:id',
                isArray: true
            },
            'update': {
                method: 'PUT',
                url: 'admin/userGroups/updateSection'
                },
            'create': {
                method: 'POST',
                url: 'admin/userGroups/createSection'
                },
            'getUserSubscriptions': {
                method: 'GET',
                url: 'api/courseSections/user/:id',
                isArray: true
            },
            'getStudentQuizProgresses': {
                method: 'GET',
                url: 'api/courseSections/:id/students/:studentId/quizProgresses',
                isArray: true
            },
            'getWithSchool': {
                method: 'GET',
                url: 'api/courseSections/:id/dto'
            },
            'getCourseSectionDTO': {
                method: 'GET',
                url: 'admin/courseSection/changeCourseSection/:id'
            },

            'getOneById': {
                method: 'GET',
                url: '/admin/courseSection/:id'
            },

            'changeActiveStatus': {
                method: 'PUT',
                url: 'admin/userGroups/switchActivate'
            }
        });
    });
