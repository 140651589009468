'use strict';

angular.module('lmsApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('courseWiki', {
                parent: 'entity',
                url: '/course/:id/wiki',
                data: {
                    roles: ['OP_MANAGE_COURSES','OP_VIEW_COURSES'],
                    pageTitle: 'lmsApp.course.wiki.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/course/course-wiki.html',
                        controller: 'CourseWikiController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('course');
                        return $translate.refresh();
                    }]
                }
            })
            .state('courseRubrics', {
                parent: 'entity',
                url: '/course/:id/rubrics',
                data: {
                    roles: ['OP_MANAGE_COURSES','OP_VIEW_COURSES'],
                    pageTitle: 'lmsApp.course.rubrics.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/course/course-rubrics.html',
                        controller: 'CourseRubricsController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('course');
                        return $translate.refresh();
                    }]
                }
            })
            .state('courseQuizzes', {
                parent: 'entity',
                url: '/course/:id/quizzes',
                data: {
                    roles: ['OP_MANAGE_COURSES','OP_VIEW_COURSES'],
                    pageTitle: 'lmsApp.course.quizzes.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/course/course-quizzes.html',
                        controller: 'CourseQuizzesController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('course');
                        return $translate.refresh();
                    }]
                }
            })
            .state('courseQuiz', {
                parent: 'entity',
                url: '/course/:id/quizzes/:imsccId',
                data: {
                    roles: ['OP_MANAGE_COURSES','OP_VIEW_COURSES'],
                    pageTitle: 'lmsApp.course.quizzes.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/course/course-quiz.html',
                        controller: 'CourseQuizController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('course');
                        $translatePartialLoader.addPart('quiz');
                        return $translate.refresh();
                    }]
                }
            })
            .state('dynamicSyllabus', {
                parent: 'entity',
                url: '/course/:id/dynamicSyllabus',
                data: {
                    roles: ['OP_MANAGE_COURSES', 'OP_VIEW_COURSES'],
                    pageTitle: 'lmsApp.course.dynamicSyllabus.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/course/dynamicSyllabus/syllabus.html',
                        controller: 'DynamicSyllabusController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('course');
                        $translatePartialLoader.addPart('studentProgress');
                        return $translate.refresh();
                    }]
                }
            })
            .state('topicFrontPage', {
                parent: 'entity',
                url: '/course/:id/topic/:topicId/front',
                data: {
                    roles: ['OP_MANAGE_COURSES', 'OP_VIEW_COURSES'],
                    pageTitle: 'lmsApp.course.topicFrontPage.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/course/dynamicSyllabus/topic-front.html',
                        controller: 'TopicFrontPageController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('course');
                        return $translate.refresh();
                    }]
                }
            })
            .state('topicSummaryPage', {
                parent: 'entity',
                url: '/course/:id/topic/:topicId/summary',
                data: {
                    roles: ['OP_MANAGE_COURSES', 'OP_VIEW_COURSES'],
                    pageTitle: 'lmsApp.course.topicSummaryPage.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/course/dynamicSyllabus/topic-summary.html',
                        controller: 'TopicSummaryController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('course');
                        return $translate.refresh();
                    }]
                }
            })
            .state('lessonSummaryPage', {
                parent: 'entity',
                url: '/course/:id/topic/:topicId/lesson/:lessonId/summary',
                data: {
                    roles: ['OP_MANAGE_COURSES', 'OP_VIEW_COURSES'],
                    pageTitle: 'lmsApp.course.lessonSummaryPage.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/course/dynamicSyllabus/lesson-summary.html',
                        controller: 'LessonSummaryController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('course');
                        return $translate.refresh();
                    }]
                }
            })
            .state('imsccPackages', {
                parent: 'entity',
                url: '/course/:id/imsccPackages',
                data: {
                    roles: ['OP_MANAGE_COURSES', 'OP_VIEW_COURSES'],
                    pageTitle: 'lmsApp.course.imsccPackages.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/course/course-imsccPackages.html',
                        controller: 'ImsccPackagesController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('course');
                        return $translate.refresh();
                    }]
                }
            });
    });

