'use strict';

function AssignmentResource($resource, API) {
    var urlApi = API.course + '/assignments';
    return $resource(urlApi, {}, {
        delete: {
            method: 'DELETE',
            url: urlApi + '/:id',
            transformResponse: function (data, headersGetter, status) {
                return {
                    status: status,
                    data: data,
                    headers: headersGetter()
                };
            }
        }
    });
}

angular
    .module('lmsApp')
    .factory('AssignmentResource', AssignmentResource);