'use strict';

angular.module('lmsApp')
    .factory('CourseProgress', function ($resource) {
        return $resource('api/courseProgresses', {}, {
            'getCourseProgresses': {
                method: 'GET',
                url: 'api/courseProgresses/userGroupId/:userGroupId/studentId/:studentId',
                isArray: true
            }
        });
    });
