'use strict';
/**
 * Директива, для отображения содержимого директории
 */
angular.module('lmsApp')
    .directive('fileBrowser', function ($translate) {
        return {
            restrict: 'E',
            templateUrl: 'app/components/admin/fileBrowser.html',
            link: function (scope, element, attrs) {

                scope.selectFolder = function(folder) {
                    scope.getCoursePath(scope.course.id, scope.currentPath + '/' + folder);
                    // find all parent folders names (as strings) and store it in array
                    var parentFolders = scope.currentPath.split('/'),
                        nodeArray = [];
                    // add current folder name to array
                    parentFolders.push(folder);
                    // now we need to iterate through scope.dataForTree obj to find
                    // all nodes to expand and push it to nodeArray
                    var loop = 0,
                        node = scope.dataForTree;
                    while (loop < parentFolders.length) {
                        // code here
                        node.forEach(function(f) {
                            if (f.name === parentFolders[loop]) {
                                nodeArray.push(f);
                                if (f.children && f.children.length > 0) {
                                    node = f.children;
                                }
                            }
                        });
                        loop++;
                    }

                    scope.selectedNode = nodeArray[nodeArray.length -1];
                    scope.expandedNodes = nodeArray;
                }; 
                scope.selectFile = function(file) {
                    scope.showFile(scope.currentPath + '/' + file.name);
                };

                scope.$watch('currentPathData', function(v) {
                    if(v && (v.folders || v.files)) {
                        setTimeout(function() {
                            $('.current-path-items .path-item').click(function() {
                                $('.current-path-items .path-item').removeClass('selected');
                                $(this).addClass('selected');
                            });
                        }, 500);
                    }
                });
            }
        };
    }
);
