'use strict';

angular.module('lmsApp')
    .controller('TopicFrontPageController', function ($scope, $rootScope, $stateParams, Course) {

        $scope.course = $rootScope.currentCourse;
        $scope.topic = null;

        $scope.load = function (params) {
            // don't load the course, if it's already in scope
            if (!$scope.course || $scope.course.id !== +params.id) {
                $scope.course = Course.get({id: params.id}, function (result) {
                    $scope.course = result;
                });
            }

            // don't load topics, if they are already in scope
            if ($scope.topics && $scope.topics.length > 0) {
                $scope.topic = getTopic($scope.topics, params);
                return;
            }
            $scope.topics = Course.getDynamicSyllabus({id: params.id}, function (result) {
                $scope.topics = result;
                $scope.topic = getTopic(result, params);
            });
        };

        $scope.load($stateParams);

        function getTopic(topics, params) {
            var topic = topics.filter(function (topic) {
                return (topic.id === +params.topicId);
            });

            if (topic.length > 0) {
                return topic[0];
            } else {
                return null;
            }
        }
    });
